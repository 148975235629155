<template>
    <ul v-show="files.length" role="list" class="-my-5 divide-y divide-gray-200">
        <li v-for="file of files.filter(f => f.status != 'hidden')" :key="file.id" class="py-4">
            <div class="flex items-center" :class="`space-x-${media_type == 'image' ? '4' : '2'}`">
                <div v-if="media_type == 'image'" class="flex-shrink-0 h-12 w-12 rounded-md bg-cover flex justify-center items-center" :style="`background-image:url(${file.url});`">
                    <LoadingIcon v-if="file.status == 'loading'" class="text-white h-8 w-8 inline-block animate-reverse-spin opacity-75" />
                </div>
                <div v-else class="flex-shrink-0 h-12 w-12 flex justify-center items-center">
                    <ClockStrokeIcon v-if="file.status == 'ready'" class="text-gray-500 h-8 w-8 inline-block" />
                    <LoadingIcon v-if="file.status == 'loading'" class="text-blue-600 h-8 w-8 inline-block animate-reverse-spin" />
                    <AlertSuccessIcon v-if="file.status == 'complete'" class="text-green-600 h-8 w-8 inline-block" />
                    <AlertErrorIcon v-if="file.status == 'error'" class="text-red-600 h-8 w-8 inline-block" />
                </div>
                <div class="min-w-0 flex-1">
                    <p class="truncate text-sm font-medium text-gray-900">{{ file.file.name }}</p>
                    <Badge :color="getBadgeColor(file.status)" :text="file.status" />
                </div>
                <div>
                    <button v-show="file.status == 'ready'" v-on:click="removeFile(file)" class="inline-flex items-center rounded-full border border-gray-300 bg-white px-2.5 py-0.5 text-sm font-medium leading-5 text-gray-700 shadow-sm hover:bg-gray-50">Remove</button>
                    <button v-show="file.status == 'complete' && file.media_id" v-on:click="deleteMedia(file)" class="inline-flex items-center rounded-full border border-gray-300 bg-white px-2.5 py-0.5 text-sm font-medium leading-5 text-gray-700 shadow-sm hover:bg-gray-50">Delete</button>
                </div>
            </div>
        </li>
    </ul>
</template>

<script>
import LoadingIcon from '@ico/LoadingIcon.vue';
import TrashIconFill from '@ico/TrashIconFill.vue'
import AlertSuccessIcon from '@ico/AlertSuccessIcon.vue';
import AlertErrorIcon from '@ico/AlertErrorIcon.vue';
import ClockStrokeIcon from '@ico/ClockStrokeIcon.vue';
import * as AssetService from '@ser/asset'
export default {
    components: {
        LoadingIcon,
        AlertSuccessIcon,
        AlertErrorIcon,
        ClockStrokeIcon,
        TrashIconFill
    },
    props: ['files','removeFile', 'media_type', 'asset_id'],
    setup(props, {emit}) {

        const deleteMedia = async (file) => {

            try {
                await AssetService.deleteMedia(file.media_id, props.asset_id)
                // insruct the parent to refresh the data (maintin a single source of truth for data)
                props.removeFile(file)

            } catch (error) {
                console.error('AssetService.deleteMedia error', error)
            }
        }

        const getBadgeColor = status => {
            switch(status) {
                case 'loading':
                    return 'blue'
                case 'complete':
                    return 'green'
                case 'error':
                    return 'red'
                case 'ready':
                default:
                    return 'gray'
            }
        }

        return {
            getBadgeColor,
            deleteMedia
        }
    },
}
</script>