<template>
    <FeatureFieldEdit v-for="field in fields.fields" :key="field.id" :field="field" />
</template>

<script>
import { onMounted, computed, reactive, watch } from 'vue';
export default {
    props: ['fields'],
    setup(props) {
        
    },
}
</script>