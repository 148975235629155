import { defineStore } from 'pinia'
import axios from 'axios'

import * as AuthService from '@ser/auth'
import * as UserService from '@ser/user'
import { useSystemStore } from '@sto/system.store'

import Echo from 'laravel-echo'
import * as Sentry from "@sentry/browser";

export const useAuthStore = defineStore('auth', {

    state: () => {
        return {
            load_complete_flag: false,
            user: null,
            token: null
        }
    },

    getters: {
        load_complete: (state) => {
            return state.load_complete_flag
        },
        user_organisation_ids: (state) => {
            return state.user?.organisations ? state.user.organisations.map(({ id }) => id) : []
        },
        user_organisations: (state) => {
            return state.user?.organisations ? state.user.organisations : []
        },
        user_dealership_ids: (state) => {
            if (!state.user?.organisations) return []
            const user_organisations = state.user.organisations.filter(o => o.type == 'dealership')
            return user_organisations.map(({ id }) => id)
        },
        user_auctioneer_ids: (state) => {
            if (!state.user?.organisations) return []
            const user_organisations = state.user.organisations.filter(o => o.type == 'auctioneer')
            return user_organisations.map(({ id }) => id)
        },
        user_group_ids: (state) => {
            if (!state.user?.organisations) return []
            const user_organisations = state.user.organisations.filter(o => o.type == 'group')
            return user_organisations.map(({ id }) => id)
        },
        user_is_developer: (state) => {
            return Array.isArray(state?.user?.roles) && state.user.roles.includes('USER_DEVELOPER')
        },
        user_is_superadmin: (state) => {
            return Array.isArray(state?.user?.roles) && state.user.roles.includes('USER_SUPER_ADMIN')
        },

        /**
         * auth user combined org and user accounts
         */
        user_combined_accounts: (state) => {
            return [].concat(state.user?.user_accounts || [], state.user?.organisation_accounts || [])
        },
        user_combined_account_ids: (state) => {
            return state.user_combined_accounts.map(({id}) => id)
        },

        /**
         * auth user auctioneers
         */
        user_auctioneers: (state) => {
            return Array.isArray(state.user.organisation_auctioneers) ? state.user.organisation_auctioneers
                : []
        },
        user_has_auctioneer: (state) => {
            return Array.isArray(state.user_auctioneers) && state.user_auctioneers.length > 0
        },

        /**
         * auth user seller accounts
         */
        user_seller_accounts: (state) => {
            return state.user_combined_accounts.filter(a => a.seller != null)
        },
        user_has_seller_account: (state) => {
            return Array.isArray(state.user_seller_accounts) && state.user_seller_accounts.length > 0
        },

        /**
         * auth user seller accounts
         */
        user_buyer_accounts: (state) => {
            return state.user_combined_accounts.filter(a => a.buyer != null)
        },
        user_has_buyer_account: (state) => {
            return Array.isArray(state.user_buyer_accounts) && state.user_buyer_accounts.length > 0
        },

        /**
         * simple suer scopes
         * return an array of simple format user scopes
         */
        user_scopes: (state) => {
            let scopes = []
            if (state.user_has_buyer_account) scopes.push('buyer')
            if (state.user_has_seller_account) scopes.push('seller')
            if (state.user_has_auctioneer) scopes.push('auctioneer')
            return scopes
        }

    },

    actions: {

        /**
         * login
         * 
         * @param {str} email
         * @param {str} password
         */
        async login(email, password) {

            try {
                const csrf = await AuthService.csrf()
                const login = await AuthService.login({email: email, password: password})
                this.token = login?.token
                this.user = login?.user
                localStorage.setItem('@TOKEN', login?.token)
                //SYSTEM_STORE.clear()
                axios.defaults.headers.common = {
                    'Authorization': 'Bearer ' + login?.token
                };
                this.connectSocketEvents(login?.token, login?.user?.id)
                //this.initMonitoring(login?.user?.id, login?.user?.email, login?.user?.first_name+' '+login?.user?.last_name)
                return true
            } catch (error) {
                console.error('AuthStore.login error', error)
                return new Promise((resolve, reject) => reject(error))
            }
        },

        /**
         * intialises monitoring
         */
        initMonitoring(user_id = null, user_email = null, user_name = null) {

            if (!user_id && !user_email) return
            Sentry.setUser({
                email: user_email,
                id: user_id,
                username: user_name != ' ' ? user_name : null
            });
        },

        /**
         * socket connection
         */
        async connectSocketEvents(token, user_id) {

            if (window.Echo) return;

            window.Echo = new Echo({
                broadcaster: "pusher",
                cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
                encrypted: true,
                key: import.meta.env.VITE_PUSHER_APP_KEY,
                authorizer: (channel, options) => {
                    return {
                        authorize: (socketId, callback) => {
                            axios({
                                method: 'post',
                                url: import.meta.env.VITE_BROADCAST_AUTH_ENDPOINT,
                                data: {
                                    socket_id: socketId,
                                    channel_name: channel.name
                                },
                                headers: {
                                    'Accept': 'application/json',
                                    'Authorization': 'Bearer ' + token
                                }
                            })
                            .then(response => {
                                callback(false, response.data);
                            })
                            .catch(error => {
                                callback(true, error);
                            });
                        }
                    };
                },
            })
        },

        /**
         * logout
         */
         async logout() {
            try {
                await AuthService.logout()
                const SYSTEM_STORE = useSystemStore()
                SYSTEM_STORE.clear()
                this.user = null
                this.token = null
                localStorage.removeItem('@TOKEN')
                Sentry.setUser(null)
                //SYSTEM_STORE.clear()
            } catch (error) {
                console.error('AuthStore.logout error', error)
            }
            return
        },

        /**
         * Get the curretnly authenticated user
         * 
         * @returns AuthUser
         */
        async getUser() {
            return new Promise((resolve) => {
                AuthService.user()
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        resolve(error) 
                    })
                    .finally(() => {
                        // set the flag to indicate we've loaded
                        this.load_complete_flag = true
                    })
            })
        },

        /**
         * refresh the auth user object
         */
        async refreshStateUser() {
            try {
                const user = await AuthService.user()
                if (user) this.user = user
            } catch (error) {
                console.error('refreshStateUser error', error)
            }
        },

        /**
         * Live check to see if the current user authenticated
         * 
         * @returns bool
         */
        async authCheck() {
            try {
                const user = await AuthService.user()
                this.user = user
                const store_token = localStorage.getItem('@TOKEN')
                if (!store_token) return false
                axios.defaults.headers.common = {
                    'Authorization': 'Bearer ' + store_token
                };
                this.connectSocketEvents(store_token, user?.id)
                this.initMonitoring(user?.id, user?.email, user?.first_name+' '+user?.last_name)
                if(user && !user?.message?.includes('Unauthenticated')) return true
            } catch (error) {
                console.error('authCheck', error)
            }

            return false
        },

        /**
         * get the target path if one exists
         */
        getTargetPath() {
            return sessionStorage.getItem('@A_TARGET_PATH') || null
        },

        /**
         * set target path
         */
        setTargetPath(path) {
            if (typeof path == 'string' && path.length > 0 && path != '/login') sessionStorage.setItem('@A_TARGET_PATH', path)
        },

        /**
         * remove the target path if one exists
         */
        removeTargetPath() {
            return sessionStorage.removeItem('@A_TARGET_PATH')
        },

        /**
         * update the authenticated user
         * @param {obj} payload of update data
         * NOTE: gets user id from auth state ... this is for the authenticated user only i.e. update self
         */
        async updateAuthUser(payload) {
            return await UserService.update(this.user.id, payload)
        }
    }
})