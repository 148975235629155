<template>
    <slot v-if="permitted"></slot>
</template>

<script>
import { useAuthStore } from '@sto/auth.store'
import { computed } from 'vue'
export default {
    props: ['roles', 'exclude_roles'],
    setup(props) {
        // auth store
        const AUTH = useAuthStore()
        const {user} = AUTH

        const permitted = computed(() => {

            if (!props.roles || (Array.isArray(props.roles) && props.roles.length == 0)) return true

            let has_roles_flag = false
            // check that we match exactly one of the role groups provided
            props.roles.forEach(role_group => {
                if (role_group.every(r => user.roles.includes(r))) has_roles_flag = true
            })
            // if any of the excluded roles are found it's a nope
            if (Array.isArray(props.exclude_roles) && props.exclude_roles.some(r => user.roles.includes(r))) has_roles_flag = false

            return has_roles_flag
        })

        return {
            permitted
        }
    },
}
</script>