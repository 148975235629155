<template>
	<div v-if="pagination" class="hidden sm:block">
        <p v-if="pagination.total == 0" class="text-sm text-gray-700">0 results</p>
        <p v-if="pagination.total > 0" class="text-sm text-gray-700">
            Showing
            <span class="font-medium">{{ (pagination.current_page * pagination.per_page) - (pagination.per_page - 1) }}</span>
            to
            <span class="font-medium">{{ (pagination.current_page * pagination.per_page) > pagination.total ? pagination.total : pagination.current_page * pagination.per_page }}</span>
            of
            <span class="font-medium">{{ pagination.total }}</span>
            {{ `result${pagination.total == 1 ? '' : 's'}` }}
        </p>
    </div>
</template>

<script>
import {onMounted, reactive, watch} from 'vue'
export default {
    props: ['pagination'],
	setup(props) {
    	
    }
}
</script>