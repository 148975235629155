<template>
    <div class="bg-white shadow rounded-lg divide-y divide-gray-200">
        <div class="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap px-4 py-5 sm:p-6">
            <div class="ml-4 mt-2">
                <h3 class="text-lg leading-6 font-medium text-gray-900">Additional Invoice Items</h3>
            </div>
        </div>
    
        <div class="px-4 py-5 sm:p-6">
            <p class="mb-4 text-sm text-gray-500">Use the form below to apply additional charges to this loan.</p>
            <LoanCreateItem />
            <LoanItems />
        </div>
    
        <div class="px-4 py-4 sm:px-6 text-right">
            <Button type="button" width="w-auto" color="blue-outline" :onClick="LOAN.activatePrevStep">Back</Button>
            <Button type="button" width="w-auto" class="ml-2" :onClick="LOAN.activateNextStep">Next</Button>
        </div>
    </div>
</template>

<script>
import { useLoanStore } from '@sto/loan.store'
import LoanCreateItem from '@par/loan/LoanCreateItem.vue'
import LoanItems from '@par/loan/LoanItems.vue'
export default {
    components: {
        LoanCreateItem,
        LoanItems
    },
    setup() {
        const LOAN = useLoanStore()

        return {
            LOAN
        }
    }
}
</script>