<template>
    <div class="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200">
        <div class="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap px-4 py-5 sm:p-6">
            <div class="ml-4 mt-2">
                <h3 class="text-lg leading-6 font-medium text-gray-900">Buyer Details</h3>
            </div>
        </div>
    
        <div class="px-4 py-5 sm:p-6">
            <FormField v-for="(field, key) in state.buyer.fields" :key="key" :field="field" namespace="loan_buyer" :name="key" class="mb-4" />
        </div>
    
        <div class="px-4 py-4 sm:px-6 text-right">
            <Button type="button" width="w-auto" color="blue-outline" :onClick="LOAN.activatePrevStep">Back</Button>
            <Button type="button" width="w-auto" class="ml-2" :onClick="() => submitStep()" :disabled="state.submit_disabled || state.buyer.loading">Save & next</Button>
        </div>
    </div>
</template>

<script>
import { useLoanStore } from '@sto/loan.store'
import { useUserStore } from '@sto/user.store'
import { computed, onMounted, reactive, watch } from 'vue'
import { isValid, getPayload } from '@lib/helpers'
export default {
    setup() {
        const LOAN = useLoanStore()
        const USER = useUserStore()

        const state = reactive({
            loading: false,
            loan_lender_name: computed(() => LOAN.loan.lender_name),
            loan_store_buyer_name: computed(() => LOAN.loan.buyer_name),
            loan_store_buyer_account_code: computed(() => LOAN.loan.buyer_account_code),
            loan_store_dealer_ref: computed(() => LOAN.loan.dealer_ref),
            submit_disabled: computed(() => !isValid(state.buyer.fields, true)),
            loan_auctioneer_name: computed(() => {
                const org = USER.organisation_auctioneers.find(o => o.id == LOAN.loan.auctioneer_id)
                return org?.name || null
            }),
            loan_lender_name: computed(() => {
                const len = LOAN.auctioneer_lenders.find(l => l.id == LOAN.loan.lender_id)
                return len?.name || null
            }),
            buyer: {
                valid: false,
                has_changes: computed(() => {
                    let has_changes = false
                    for (let f in state.buyer.fields) {
                        if (state.buyer.fields[f].value != state.buyer.fields[f].stored_value) has_changes = true
                    }
                    return has_changes
                }),
                loading: false,
                fields: {
                    buyer_name: {
                        active: true,
                        label: 'Buyer name',
                        type: 'text',
                        value: LOAN.loan?.buyer_name || '',
                        stored_value: LOAN.loan?.buyer_name || '',
                        required: true,
                        pristine: LOAN.loan?.buyer_name ? false : true,
                        valid: false,
                        message: '',
                        validation: [],
                        notes: '',
                    },
                    buyer_account_code: {
                        active: true,
                        label: computed(() => `${state.loan_auctioneer_name ? state.loan_auctioneer_name : 'Internal'} account code`),
                        type: 'text',
                        value: LOAN.loan?.buyer_account_code || '',
                        stored_value: LOAN.loan?.buyer_account_code || '',
                        required: true,
                        pristine: LOAN.loan?.buyer_account_code ? false : true,
                        valid: false,
                        message: '',
                        validation: [],
                        notes: '',
                    },
                    dealer_ref: {
                        active: true,
                        label: computed(() => `${state.loan_lender_name ? state.loan_lender_name : 'Lender'} dealer ID`),
                        type: 'text',
                        value: LOAN.loan?.dealer_ref || '',
                        stored_value: LOAN.loan?.dealer_ref || '',
                        required: true,
                        pristine: LOAN.loan?.dealer_ref ? false : true,
                        valid: false,
                        message: '',
                        validation: [],
                        notes: '',
                    },
                    dealer_classification: {
                        active: computed(() => state.loan_lender_name == 'V12'),
                        label: computed(() => `${state.loan_lender_name ? state.loan_lender_name : 'Lender'} dealer classification`),
                        type: 'select',
                        value: LOAN.loan?.dealer_classification || '',
                        stored_value: LOAN.loan?.dealer_classification || '',
                        options: [
                            { value: null, label: 'Please select...' },
                            { value: 'PRIME', label: 'Prime' },
                            { value: 'PARTNER', label: 'Partner' },
                            { value: 'FRANCHISE', label: 'Franchise' }
                        ],
                        required: computed(() => state.loan_lender_name == 'V12'),
                        pristine: LOAN.loan?.dealer_classification ? false : true,
                        valid: false,
                        message: '',
                        validation: [],
                        notes: '',
                    }
                }
            }
        })

        const submitStep = async () => {

            // fields will automatically create their own validation states
            if (state.buyer.loading || !isValid(state.buyer.fields)) return

            // if the value is present and unchaned, just skip to the next step
            if (!state.buyer.has_changes) {
                LOAN.activateNextStep()
                return
            }

            state.buyer.loading = true

            try {
                const buyer_payload = getPayload(state.buyer.fields)
                await LOAN.update(LOAN.loan.id, buyer_payload)
                LOAN.activateNextStep()
            } catch (error) {
                console.error('submitStep', error)
                // TODO handle error
            } finally {
                state.buyer.loading = false
            }

        }

        watch(
            () => state.loan_store_buyer_name,
            (new_value, old_value) => {
                if (state.buyer.fields.buyer_name.value != state.loan_store_buyer_name) {
                    state.buyer.fields.buyer_name.value = new_value
                    state.buyer.fields.buyer_name.stored_value = new_value
                }
                
            }
        )

        watch(
            () => state.loan_store_buyer_account_code,
            (new_value, old_value) => {
                if (state.buyer.fields.buyer_account_code.value != state.loan_store_buyer_account_code) {
                    state.buyer.fields.buyer_account_code.value = new_value
                    state.buyer.fields.buyer_account_code.stored_value = new_value
                }
            }
        )

        watch(
            () => state.loan_store_dealer_ref,
            (new_value, old_value) => {
                if (state.buyer.fields.dealer_ref.value != state.loan_store_dealer_ref) {
                    state.buyer.fields.dealer_ref.value = new_value
                    state.buyer.fields.dealer_ref.stored_value = new_value
                }
            }
        )

        watch(
            () => state.loan_lender_name,
            (new_value, old_value) => {
                //customiseSteps()
            }
        )

        onMounted(() => {
            //customiseSteps()
        })

        return {
            LOAN,
            state,
            submitStep
        }
    }
}
</script>