<template>
    <slot v-if="has_role"></slot>
</template>

<script>
import { useAuthStore } from '@sto/auth.store'
import { computed } from 'vue'
export default {
    props: ['roles','organisation_id', 'exclude_roles'],
    setup(props) {
        // auth store
        const AUTH = useAuthStore()
        const {user} = AUTH
        const user_organisations = () => {
            // if no user or malfored/unexpected organisations property, return empty array
            if (!user || !Array.isArray(user.organisations)) return []
            // if no organisation is specified, assume all user's organisations
            if (!props.organisation_id || props.organisation_id == '*') return user.organisations
            // just the specified organisation object found by id
            return user.organisations.filter(org => org.id == props.organisation_id)
        }

        const has_role = computed(() => {
            // super admin procede always
            //if (user.roles.includes('USER_SUPER_ADMIN')) return true
            // no roles supplied - might be the case if the component was called in a loop
            if (!props.roles || (Array.isArray(props.roles) && props.roles.length === 0)) return true
            // get the organisations
            const organisations = user_organisations()
            // malformed or missing organisation arrays stop here - must check *after* super admin because they will have no organisations
            if (!organisations || !Array.isArray(organisations) || organisations.length == 0) return false
            // reformat whatever roles we received into an array for comparison
            let check_roles = typeof props.roles === 'string' ? [props.roles] : Array.isArray(props.roles) ? props.roles : []
            let has_role_flag = false
            organisations.forEach(org => {
                // for each of the organisations, check if the organisation roles match at least one of the supplied prop roles
                if (props.roles.some(r => org.user_roles.includes(r))) has_role_flag = true
            })

            return has_role_flag
        })

        return {
            has_role
        }
    },
}
</script>