<template>
    <InformationCircleIcon :class="class" />
</template>

<script>
import { InformationCircleIcon } from '@heroicons/vue/outline'

export default {
    props: ['class'],
    components: {
        InformationCircleIcon
    }
}
</script>
