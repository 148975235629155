<template>
    <TransitionRoot as="template" :show="modal_active">
        <Dialog as="div" class="relative z-10" @close="() => toggleModal()">

            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity"></div>
            </TransitionChild>

            <div class="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">

                <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 opacity-0 scale-95" enter-to="opacity-100 scale-100" leave="ease-in duration-200" leave-from="opacity-100 scale-100" leave-to="opacity-0 scale-95">
                    <div class="mx-auto max-w-4xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                        <div class="lg:flex lg:items-center lg:justify-between px-6 py-3">
                            <h3 class="text-lg font-medium text-gray-900">Alerts</h3>
                            <button type="button" v-on:click="toggleModal">
                                <CrossIcon class="h-5 w-5" />
                            </button>
                        </div>

                        <!-- Results, show/hide based on command palette state -->
                        <ul v-if="alerts.length > 0" class="max-h-96 scroll-py-3 overflow-y-auto p-3" id="options" role="listbox">
                            <AlertModalItem v-for="notification in alerts" :notification="notification" :key="notification.id" />

                            <div v-if="alerts.length < pagination.total" v-on:click="getNextPage" class="flex justify-center py-4">
                                <button class="rounded-md bg-blue-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">Load more...</button>
                            </div>
                        </ul>

                        <!-- Empty state, show/hide based on command palette state -->
                        <div v-if="alerts.length == 0" class="py-14 px-6 text-center text-sm sm:px-14">
                            <!-- Heroicon name: outline/exclamation-circle -->
                            <svg class="mx-auto h-6 w-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                            </svg>
                            <p class="mt-4 font-semibold text-gray-900">You're all up to date!</p>
                            <p class="mt-2 text-gray-500">There are no alerts to show.</p>
                        </div>

                        <div v-if="alerts.length > 0" class="p-3">
                            <Button :onClick="acknowledgeAll" width="w-auto" size="sm" class="mr-2">Mark all as read</Button>
                            <Button :onClick="deleteAll" width="w-auto" size="sm">Delete all</Button>
                        </div>

                    </div>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script>
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import CrossIcon from '@ico/CrossIcon.vue'
import AlertModalItem from '@par/alerts/AlertModalItem.vue'
import { useAlertStore } from '@sto/alert.store';
import { useAuthStore } from '@sto/auth.store'
import { computed, watch, onMounted } from 'vue'
export default {
    components: {
        Dialog,
        DialogPanel,
        DialogTitle,
        TransitionChild,
        TransitionRoot,
        CrossIcon,
        AlertModalItem
    },
    setup() {
        
        const ALERT_STORE = useAlertStore()
        const AUTH_STORE = useAuthStore()

        const toggleModal = () => {
            ALERT_STORE.setGlobalModal(!modal_active.value)
        }

        const getAlerts = async (page = 1) => {
            await ALERT_STORE.getUserAlerts(auth_user_id.value, page)
        }

        const deleteAll = async () => {
            await ALERT_STORE.deleteAll(auth_user_id.value)
            getAlerts()
        }

        const acknowledgeAll = async () => {
            await ALERT_STORE.acknowledgeAll(auth_user_id.value)
            getAlerts()
        }

        const getNextPage = () => {
            getAlerts((pagination.value.current_page + 1))
        }

        const auth_user_id = computed(() => {
            return AUTH_STORE.user?.id || null
        })

        const modal_active = computed(() => ALERT_STORE.global_modal_active)

        const alerts = computed(() => ALERT_STORE.alerts)
        const pagination = computed(() => ALERT_STORE.pagination)

        // watch the modal active state
        watch(
            () => modal_active.value,
            (new_value, old_value) => {
                // when the modal is activated grab the notiifcations
                if (new_value) getAlerts()
            }
        )

        onMounted(() => {
            getAlerts()
        })

        return {
            alerts,
            pagination,
            modal_active,
            toggleModal,
            getNextPage,
            deleteAll,
            acknowledgeAll
        }
    },
}
</script>