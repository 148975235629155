<template>
    <dl class="sm:divide-y sm:divide-gray-200">
        <slot></slot>
    </dl>
</template>

<script>
export default {
    props: ['borders'],
    setup() {
        
    },
}
</script>