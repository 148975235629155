<!--
    Returns the slot content if the currently authenticated user has at leat one buyer account
-->

<template>
	<slot v-if="user_has_buyer_account"></slot>
</template>

<script>
import { computed } from 'vue'
import { useAuthStore } from '@sto/auth.store'
export default {
	setup() {
    	const AUTH_STORE = useAuthStore()

        const user_has_buyer_account = computed(() => AUTH_STORE.user_has_buyer_account)

        return {
            user_has_buyer_account
        }
    }
}
</script>