<template>
    <XCircleIcon :class="class" />
</template>

<script>
import { XCircleIcon } from '@heroicons/vue/solid'

export default {
    props: ['class'],
    components: {
        XCircleIcon
    }
}
</script>