<template>
    <XIcon :class="class" />
</template>

<script>
import { XIcon } from '@heroicons/vue/solid'

export default {
    props: ['class'],
    components: {
        XIcon
    }
}
</script>