<template>
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke="currentColor" fill="none" viewBox="0 0 26 26" style="enable-background:new 0 0 26 26;" xml:space="preserve" :class="class">
        <g>
			<path d="M13,24.8c6.4,0,11.6-5.2,11.6-11.6S19.4,1.6,13,1.6S1.4,6.8,1.4,13.2S6.6,24.8,13,24.8z"/>
			<path d="M13,13.2V9"/>
			<path d="M13,13.2l5.2,5.2"/>
		</g>
    </svg>
</template>

<script>
export default {
    props: ['class'],
}
</script>