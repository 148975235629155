<template>
    <div class="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200">
        <div class="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap px-4 py-5 sm:p-6">
            <div class="ml-4 mt-2">
                <h3 class="text-lg leading-6 font-medium text-gray-900">Finance Provider</h3>
            </div>
        </div>
    
        <div class="px-4 py-5 sm:p-6">
            
            <Listbox as="div" v-model="state.selected_lender">
                <ListboxLabel class="block text-sm font-medium text-gray-700">Lender</ListboxLabel>
                <div class="mt-1 relative">
                    <ListboxButton class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                        <span class="block truncate">{{ state.selected_lender?.name || 'please choose...' }}</span>
                        <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                            <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </span>
                    </ListboxButton>

                    <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                        <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                            <ListboxOption as="template" v-for="lender in LOAN.auctioneer_lenders" :key="lender.id" :value="lender" v-slot="{ active, selected }">
                                <li :class="[active ? 'text-white bg-blue-600' : 'text-gray-900', 'cursor-default select-none relative py-2 pl-3 pr-9']">
                                    <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
                                        {{ lender.name }}
                                    </span>

                                    <span v-if="selected" :class="[active ? 'text-white' : 'text-blue-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                        <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                    </span>
                                </li>
                            </ListboxOption>
                        </ListboxOptions>
                    </transition>
                </div>
            </Listbox>

        </div>
    
        <div class="px-4 py-4 sm:px-6 text-right">
            <!--<button type="button" class="bg-blue-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Save & Next</button>-->
            <Button type="button" width="w-auto" :onClick="() => submitStep()" :disabled="!state.selected_lender || state.loading">Save & next</Button>
        </div>
    </div>
</template>

<script>
import { useLoanStore } from '@sto/loan.store'
import { computed, onMounted, reactive, watch } from 'vue'
import { useRoute } from 'vue-router'
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { CheckIcon, SelectorIcon } from '@heroicons/vue/solid'
export default {
    components: {
        Listbox,
        ListboxButton,
        ListboxLabel,
        ListboxOption,
        ListboxOptions,
        CheckIcon,
        SelectorIcon
    },
    setup() {
        const LOAN = useLoanStore()
        const ROUTE = useRoute()

        const state = reactive({
            selected_lender: null,
            existing_lender_id: computed(() => LOAN.loan?.lender_id),
            auctioneer_lenders: computed(() => LOAN.auctioneer_lenders),
            loading: false
        })

        const submitStep = async () => {
            if (state.loading) return

            // if the value is present and unchaned, just skip to the next step
            if (state.selected_lender.id && state.selected_lender.id == state.existing_lender_id) {
                LOAN.activateNextStep()
                return
            }
            try {
                state.loading = true
                await LOAN.update(ROUTE.params.loan_id, {lender_id: state.selected_lender.id})
                LOAN.activateNextStep()
            } catch (error) {
                console.error('submitStep', error)
                // TODO handle error
            } finally {
                state.loading = false
            }
        }

        /**
         * uses the existing lender_id from GET /loan to find the relevant lender from available lenders and sets that as the dropdown value
         */
        const setValue = () => {
            // only proceed if both the exising lender and the available lenders have resolved
            if (!state.existing_lender_id || (!Array.isArray(state.auctioneer_lenders) || state.auctioneer_lenders.length == 0)) return
            // use the existing id to search the avaialable lenders
            const lender = state.auctioneer_lenders.find(l => l.id == state.existing_lender_id)
            state.selected_lender = lender || null
        }

        watch(
            () => state.existing_lender_id,
            (new_value, old_value) => {
                setValue()
            }
        )

        watch(
            () => state.auctioneer_lenders,
            (new_value, old_value) => {
                setValue()
            }
        )

        onMounted(() => {
            setValue()
        })

        return {
            state,
            LOAN,
            submitStep
        }
    }
}
</script>