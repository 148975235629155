<template>
    <dd :class="classes">
        <slot></slot>
    </dd>
</template>

<script>
import { computed } from 'vue'
export default {
    props: ['colspan', 'color', 'weight', 'size', 'text_align'],
    setup(props) {
        const classes = computed(() => {
            let class_string = ''

            class_string += ' sm:col-span-' + (props.colspan ? props.colspan : '2')
    
            // colour
            class_string += ' text-' + (props.color ? props.color : 'gray-900')
    
            // weight
            class_string += ' font-' + (props.weight ? props.weight : 'regular')
    
            // size
            class_string += ' text-' + (props.size ? props.size : 'sm')

            // align
            class_string += ' text-' + (props.text_align ? props.text_align : 'left')
    
            return class_string
        })
    
        return {
            classes
        }
    },
}
</script>