<template>
    <div class="sm:grid" :class="classes">
        <slot></slot>
    </div>
</template>

<script>
import { computed } from 'vue'
export default {
    props: ['size', 'grid_cols', 'background_class'],
    setup(props) {
        const classes = computed(() => {
            let class_string = ''

            switch(props.size) {
                case 'sm':
                    class_string += 'py-1 sm:py-2 sm:gap-0 sm:px-3'
                    break
                case 'lg':
                default:
                    class_string += 'py-4 sm:py-5 sm:gap-6 sm:px-6'
            }

            if (props.background_class) class_string += ' ' + props.background_class

            class_string += ' sm:grid-cols-' + (props.grid_cols ? props.grid_cols : '3')

            return class_string
        })

        return {
            classes
        }
    },
}
</script>