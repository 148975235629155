<template>
    <div class="space-y-6" :class="col_class">
        <slot></slot>
    </div>
</template>

<script>
import { computed } from 'vue'
export default {
    props: ['offset'],
    setup(props) {
        
        const col_class = computed(() => {
            switch(props.offset) {
                case 'none':
                    return 'lg:col-span-4'
                case 'large':
                    return 'lg:col-start-10 lg:col-span-3'
                default:
                    return 'lg:col-start-9 lg:col-span-4'
            }
        })

        return {
            col_class
        }
    },
}
</script>