<template>
    <div class="border-t border-gray-200" :class="state.padding_classes">
        <slot></slot>
    </div>
</template>

<script>
import { reactive, computed, watch } from 'vue'
export default {
    props: ['padding'],
    setup(props) {

        const state = reactive({
            padding_classes: computed(() => {
                switch(props.padding) {
                    case 'none':
                        return 'p-0'
                    default:
                        return 'px-4 py-5 sm:px-6'
                }
            }),
        })

        return {
            state
        }
    },
}
</script>