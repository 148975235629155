/*******************************************************************************
 * Dependency Imports
 ******************************************************************************/

// Helpers
import { getEndpoint } from '@lib/helpers'
import axios from 'axios'
import { useAuthStore } from '@sto/auth.store'

/*******************************************************************************
 * Private methods
 ******************************************************************************/

/*******************************************************************************
 * Public methods
 ******************************************************************************/

/**
 * create a loan for the supplied organisation
 * @param {int} auctioneer_id
 * @param {obj} payload of startring data
 * @returns 
 */
 export const create = async (auctioneer_id, payload = null) => {

    return new Promise(( resolve, reject ) => {

        if (!auctioneer_id) {
            reject({message: 'auctioneer_id missing'})
            return
        }

        axios({
            method: 'post',
            url: getEndpoint('/auctioneer/' + auctioneer_id + '/loan'),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * create a loan for the supplied organisation
 * @param {int} loan_id
 * @param {int} organisation_id
 * @param {obj} payload of startring data
 * @returns 
 */
export const update = async (loan_id, payload = null) => {

    return new Promise(( resolve, reject ) => {

        if (!loan_id) {
            reject({message: 'Loan ID missing'})
            return
        }

        axios({
            method: 'patch',
            url: getEndpoint('/loan/' + loan_id),
            data: payload,
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * delete a loan
 * @param {int} loan_id
 * @param {obj} payload of startring data
 * @returns 
 */
export const remove = async (loan_id, auctioneer_id) => {

    return new Promise(( resolve, reject ) => {

        if (!auctioneer_id) {
            reject({message: 'auctioneer_id missing'})
            return
        }

        if (!loan_id) {
            reject({message: 'Loan ID missing'})
            return
        }

        axios({
            method: 'delete',
            url: getEndpoint('/loan/' + loan_id),
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * get a loan by id
 * @param {int} loan_id
 * @param {int} auctioneer_id
 * @returns 
 */
 export const show = async (loan_id = null) => {

    return new Promise(( resolve, reject ) => {

        if (!loan_id) {
            reject({message: 'Loan ID missing'})
            return
        }

        axios({
            method: 'get',
            url: getEndpoint('/loan/' + loan_id),
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * get a list of loans for a specificed organisation
 * @param {int} loan_id
 * @param {int} organisation_id
 * @returns 
 */
export const list = async ($organisation_ids = null) => {

    return new Promise(( resolve, reject ) => {

        if (!$organisation_ids) {
            reject({message: 'Organisation IDs are missing'})
            return
        }

        axios({
            method: 'get',
            url: getEndpoint('/organisation/' + organisation_id + '/loan/' + loan_id),
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * get a list of loans for many specified auctioneer
 * @param {int} loan_id
 * @param {arr} auctioneer_ids
 * @returns 
 */
 export const listForUser = async (user_id, page = 1, per_page = 10) => {

    const AUTH = useAuthStore()

    return new Promise(( resolve, reject ) => {

        if (!user_id) {
            reject({message: 'user_id is missing'})
            return
        }

        axios({
            method: 'get',
            url: getEndpoint('/user/'+user_id+'/loans' + '?page=' + page + '&per_page=' + per_page),
            headers: {
                'Accept': 'application/json',
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * get lenders for a given organisation
 * @param {int} organisation_id
 * @returns 
 */
 export const organisationLenders = async (organisation_id = null) => {

    return new Promise(( resolve, reject ) => {

        if (!organisation_id) {
            reject({message: 'Organisation ID missing'})
            return
        }

        axios({
            method: 'get',
            url: getEndpoint('/organisation/' + organisation_id + '/lenders'),
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * create or update a vehicle for the specified loan
 * @param {int} loan_id
 * @param {int} organisation_id
 * @param {obj} payload of startring data
 * @returns 
 */
 export const createOrUpdateVehicle = async (loan_id, payload = null) => {

    return new Promise(( resolve, reject ) => {

        if (!loan_id) {
            reject({message: 'Loan ID missing'})
            return
        }

        axios({
            method: 'post',
            url: getEndpoint('/loan/' + loan_id + '/vehicle'),
            data: payload,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * get the loan meta for an organisation
 * @param {int} organisation_id
 * @returns 
 */
 export const auctioneerMeta = async (auctioneer_id = null) => {

    return new Promise(( resolve, reject ) => {

        if (!auctioneer_id) {
            reject({message: 'auctioneer_id missing'})
            return
        }

        axios({
            method: 'get',
            url: getEndpoint('/auctioneer/' + auctioneer_id + '/loan-meta'),
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * create an item for a specified loan
 * @param {int} loan_id
 * @param {int} organisation_id
 * @param {obj} payload of startring data
 * @returns 
 */
 export const createItem = async (loan_id, auctioneer_id, payload = null) => {

    return new Promise(( resolve, reject ) => {

        if (!loan_id) {
            reject({message: 'Loan ID missing'})
            return
        }

        if (!auctioneer_id) {
            reject({message: 'auctioneer_id missing'})
            return
        }

        axios({
            method: 'post',
            url: getEndpoint('/auctioneer/' + auctioneer_id + '/loan/' + loan_id + '/item'),
            data: payload,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * delete an item for a specified loan
 * @param {int} loan_id
 * @param {int} organisation_id
 * @param {int} item_id 
 * @returns 
 */
export const deleteItem = async (loan_id, auctioneer_id, item_id) => {

    return new Promise(( resolve, reject ) => {

        if (!loan_id) {
            reject({message: 'Loan ID missing'})
            return
        }

        if (!item_id) {
            reject({message: 'Item ID missing'})
            return
        }

        if (!auctioneer_id) {
            reject({message: 'auctioneer_id missing'})
            return
        }

        axios({
            method: 'delete',
            url: getEndpoint('/auctioneer/' + auctioneer_id + '/loan/' + loan_id + '/item/' + item_id),
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * delete an item for a specified loan
 * @param {int} loan_id
 * @param {int} organisation_id
 * @returns 
 */
 export const prepareInvoice = async (loan_id, auctioneer_id) => {

    return new Promise(( resolve, reject ) => {

        if (!auctioneer_id) {
            reject({message: 'auctioneer_id missing'})
            return
        }

        if (!loan_id) {
            reject({message: 'Loan ID missing'})
            return
        }

        axios({
            method: 'post',
            url: getEndpoint('/auctioneer/' + auctioneer_id + '/loan/' + loan_id + '/prepare'),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * submit application
 * @param {int} loan_id
 * @param {int} organisation_id
 * @returns 
 */
export const submitApplication = async (loan_id, auctioneer_id) => {

    return new Promise(( resolve, reject ) => {

        if (!auctioneer_id) {
            reject({message: 'auctioneer_id missing'})
            return
        }

        if (!loan_id) {
            reject({message: 'Loan ID missing'})
            return
        }

        axios({
            method: 'post',
            url: getEndpoint('/auctioneer/' + auctioneer_id + '/loan/' + loan_id + '/apply'),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * get loan events
 * @param {int} loan_id
 * @param {int} auctioneer_id
 * @returns 
 */
 export const events = async (loan_id, auctioneer_id) => {

    return new Promise(( resolve, reject ) => {

        if (!auctioneer_id) {
            reject({message: 'auctioneer_id missing'})
            return
        }

        if (!loan_id) {
            reject({message: 'Loan ID missing'})
            return
        }

        axios({
            method: 'get',
            url: getEndpoint('/auctioneer/' + auctioneer_id + '/loan/' + loan_id + '/events'),
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * get loan records
 * @param {int} loan_id
 * @param {int} auctioneer_id
 * @returns 
 */
export const records = async (loan_id, auctioneer_id) => {

    return new Promise(( resolve, reject ) => {

        if (!auctioneer_id) {
            reject({message: 'auctioneer_id missing'})
            return
        }

        if (!loan_id) {
            reject({message: 'Loan ID missing'})
            return
        }

        axios({
            method: 'get',
            url: getEndpoint('/auctioneer/' + auctioneer_id + '/loan/' + loan_id + '/records'),
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}
