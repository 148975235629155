<template>
    <div :class="wrapper_class">
        <div class="sm:hidden">
            <label for="tabs" class="sr-only">Select a tab</label>
            <select :id="tab_id" name="tabs" class="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                <option v-for="tab in available_tabs.filter(t => (t.tab_type == 'tab' && !t.disabled))" :key="tab.key" :selected="tab.key == tab_manager.active_tab">{{ tab.label}}</option>
            </select>
        </div>
        <div class="hidden sm:block">
            <div class="border-b border-gray-200">
                <nav class="-mb-px flex space-x-8" aria-label="Tabs">
                    <div v-for="tab in available_tabs" :key="tab.key" class="flex items-center">
                        <button v-if="tab.tab_type == 'tab' && !tab.disabled" v-on:click="activateTab(tab.key)" :class="tab.key == tab_manager.active_tab ? 'border-blue-500 text-blue-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'" class="font-medium text-sm border-b-2 whitespace-nowrap py-4 px-1" aria-current="page" :id="tabLinkId(tab.key)">
                            {{ tab.label }}
                            <Badge v-if="tab.badge_count" :size="'sm'" :color="tab.badge_color || 'red'" :text="tab.badge_count" />
                        </button>

                        <component v-if="tab.tab_type == 'component' && !tab.disabled" :is="tab.tab_component" v-bind="tab.component_properties" />
                    </div>
                </nav>
            </div>
        </div>
    </div>
</template>

<script>
import { useSystemStore } from '@sto/system.store'
import { useAuthStore } from '@sto/auth.store'
import { useRoute } from 'vue-router'
import { computed } from 'vue'
export default {
    props: ['tab_manager', 'tab_id', 'wrapper_class'],
    setup(props) {
        const SYSTEM_STORE = useSystemStore()
        const AUTH_STORE = useAuthStore()
        const ROUTE = useRoute()
        const activateTab = key => {
            const from_tab = props.tab_manager.active_tab
            const to_tab = key
            props.tab_manager.active_tab = key
            SYSTEM_STORE.createInteraction('TAB_EVENT', ROUTE.fullPath, to_tab, { route_path: ROUTE.fullPath, from_tab: from_tab, to_tab: to_tab })
        }

        const available_tabs = computed(() => {
            const scope_organisations = !AUTH_STORE?.user?.organisations ? []
                : ROUTE.params.organisation_id ? [AUTH_STORE.user.organisations.find(o => o.id == ROUTE.params.organisation_id)]
                : AUTH_STORE.user.organisations

            let user_organisation_roles = []

            scope_organisations.map(org => { user_organisation_roles = user_organisation_roles.concat(org.user_roles)})

            props.tab_manager.tabs.forEach(tab => {
                let tab_roles = Array.isArray(tab.roles) ? tab.roles
                    : []
                tab.active = tab_roles.length > 0 ? tab_roles.some(role => user_organisation_roles.includes(role))
                    : true

                tab.tab_type = tab.tab_type || 'tab'
            })

            const tabs = props.tab_manager.tabs.filter(t => t.active)

            return tabs

        })

        const tabLinkId = key => {
            return `${props.tab_id}-${key.replace('_','-')}`
        }

        return {
            available_tabs,
            activateTab,
            tabLinkId
        }
    },
}
</script>