import { defineStore } from 'pinia'
import { useAuthStore } from '@sto/auth.store'
import * as UserService from '@ser/user'

export const useUserStore = defineStore('user', {

    getters: {
        user: () => {
            const auth = useAuthStore()
            return auth?.user || null
        },
        organisations: () => {
            const auth = useAuthStore()
            return auth?.user?.organisations
        },
        lender_enabled_organisation_ids: () => {
            const auth = useAuthStore()
            const user = auth?.user || null
            if (!user) return []
            if (!user.organisations || !Array.isArray(user.organisations)) return []
            const lender_enabled_organisations = user.organisations.filter(o => 
                o.type == 'auctioneer' &&
                (Array.isArray(o.user_roles) && o.user_roles.includes('USER_ORGANISATION_AUCTIONEER_CASHDESK_SUPERVISOR') || o.user_roles.includes('USER_ORGANISATION_AUCTIONEER_CASHDESK')) &&
                (Array.isArray(o.lenders) && o.lenders.length > 0)
            )
            let ids = []
            lender_enabled_organisations.forEach(o => ids.push(o.id))
            return ids
        },
        is_developer: () => {
            const auth = useAuthStore()
            return Array.isArray(auth?.user?.roles) && auth.user.roles.includes('USER_DEVELOPER')
        },
        organisation_auctioneers: () => {
            const auth = useAuthStore()
            return auth?.user?.organisation_auctioneers || []
        },
        organisation_accounts: () => {
            const auth = useAuthStore()
            return auth?.user?.organisation_accounts || []
        },
        user_accounts: () => {
            const auth = useAuthStore()
            return auth?.user?.user_accounts || []
        },
        all_user_accounts: () => {
            const auth = useAuthStore()
            return [].concat(auth?.user?.organisation_accounts || [], auth?.user?.user_accounts || [])
        },

        /**
         * user all accounts
         */
        user_combined_accounts: () => {
            const auth = useAuthStore()
            return auth.user_combined_accounts // return arr
        },

        /**
         * user auctioneers
         */
        user_auctioneers: () => {
            const auth = useAuthStore()
            return auth.user_auctioneers // return arr
        },
        user_has_auctioneer: () => {
            const auth = useAuthStore()
            return auth.user_has_auctioneer // return bool
        },


        /**
         * user buyers
         */
        user_buyer_accounts: () => {
            const auth = useAuthStore()
            return auth.user_buyer_accounts // return arr
        },
        user_has_buyer_account: () => {
            const auth = useAuthStore()
            return auth.user_has_buyer_account // return bool
        },

        /**
         * user sellers
         */
        user_seller_accounts: () => {
            const auth = useAuthStore()
            return auth.user_seller_accounts // return arr
        },
        user_has_seller_account: () => {
            const auth = useAuthStore()
            return auth.user_has_seller_account // return bool
        },
    },

    actions: {

        async upserConfiguration(user_id, payload) {
            return UserService.upserConfiguration(user_id, payload)
        },

        /**
         * determine if a user is the auctioneer of a given asset
         * @param {obj} user - if null, uses the currently authenticated user
         * @param {obj} asset_details - can be asset_auctioneer_id (int), asset_auctioneer (obj) or asset with auctioneer property (obj)
         */
        userIsAuctioneerOfAsset(asset_details = {}, user = null) {
            if (!asset_details) throw 'asset details not given'
            const auth = useAuthStore()
            const target_user = user || auth?.user
            // make sure we have a valid user to work with
            if (!target_user || !target_user.hasOwnProperty('organisation_auctioneers')) throw 'invalid user'

            let user_asset_auctioneer = [];

            if (asset_details.asset_auctioneer_id) {
                user_asset_auctioneer = target_user.organisation_auctioneers.filter(a => a.id == asset_details.asset_auctioneer_id)
            } else if (asset_details.asset_auctioneer?.id) {
                user_asset_auctioneer = target_user.organisation_auctioneers.filter(a => a.id == asset_details.asset_auctioneer.id)
            } else if (asset_details.asset?.auctioneer?.id) {
                user_asset_auctioneer = target_user.organisation_auctioneers.filter(a => a.id == asset_details.asset.auctioneer.id)
            } else {
                console.warn('userIsAuctioneerOfAsset invalid argument: asset_details', asset_details)
                throw 'invalid asset_details'
            }

            // if the matching array of auctioneers is greater than zero, the specified user is the auctioneer
            return user_asset_auctioneer.length > 0
        },

        /**
         * determine if a user is the buyer of a given asset
         * @param {obj} user - if null, uses the currently authenticated user
         * @param {obj} asset_details - can be asset_buyer_account_id (int), asset_buyer_account (obj) or asset with buyer_account property (obj)
         */
        userIsBuyerOfAsset(asset_details = {}, user = null) {
            if (!asset_details) throw 'asset details not given'
            const auth = useAuthStore()
            const target_user = user || auth?.user
            // make sure we have a valid user to work with
            if (!target_user || !target_user.hasOwnProperty('organisation_auctioneers')) throw 'invalid user'

            let user_asset_buyer = [];
            const user_combined_accounts = [].concat(target_user.user_accounts || [], target_user.organisation_auctioneers || [])

            if (asset_details.asset_buyer_account_id) {
                user_asset_buyer = user_combined_accounts.filter(a => a.id == asset_details.asset_buyer_account_id)
            } else if (asset_details.asset_buyer_account?.id) {
                user_asset_buyer = user_combined_accounts.filter(a => a.id == asset_details.asset_buyer_account.id)
            } else if (asset_details.asset?.buyer_account?.id) {
                user_asset_buyer = user_combined_accounts.filter(a => a.id == asset_details.asset.buyer_account.id)
            } else {
                console.warn('userIsBuyerOfAsset invalid argument: asset_details', asset_details)
                throw 'invalid asset_details'
            }

            // if the matching array of buyers is greater than zero, the specified user is the buyer
            return user_asset_buyer.length > 0
        },

        /**
         * determine if a user is the seller of a given asset
         * @param {obj} user - if null, uses the currently authenticated user
         * @param {obj} asset_details - can be asset_seller_account_id (int), asset_seller_account (obj) or asset with seller_account property (obj)
         */
        userIsSellerOfAsset(asset_details = {}, user = null) {
            if (!asset_details) throw 'asset details not given'
            const auth = useAuthStore()
            const target_user = user || auth?.user
            // make sure we have a valid user to work with
            if (!target_user) throw 'invalid user'

            let user_asset_seller = [];
            const user_combined_accounts = [].concat(target_user.user_accounts || [], target_user.organisation_accounts || [])

            if (asset_details.asset_seller_account_id) {
                user_asset_seller = user_combined_accounts.filter(a => a.id == asset_details.asset_seller_account_id)
            } else if (asset_details.asset_seller_account?.id) {
                user_asset_seller = user_combined_accounts.filter(a => a.id == asset_details.asset_seller_account.id)
            } else if (asset_details.asset?.seller_account?.id) {
                user_asset_seller = user_combined_accounts.filter(a => a.id == asset_details.asset.seller_account.id)
            } else {
                console.warn('userIsSellerOfAsset invalid argument: asset_details', asset_details)
                throw 'invalid asset_details'
            }
            
            // if the matching array of sellers is greater than zero, the specified user is the seller
            return user_asset_seller.length > 0
        },

        /**
         * check if the user belongs to a specified aucioneer_id
         * @param {int} auctioneer_id 
         * @param {obj|null} user 
         * @returns 
         */
        userBelongsToAuctioneer(auctioneer_id, user = null) {
            const auth = useAuthStore()
            const match = auth.user_auctioneers.filter(auc => auc.id == auctioneer_id)

            return Array.isArray(match) && match.length > 0
        },

        /**
         * check if the user belongs to a specified account_id
         * @param {int} account_id 
         * @param {obj|null} user 
         * @returns 
         */
        userBelongsToAccount(account_id, user = null) {

            const auth = useAuthStore()
            const user_combined_accounts = [].concat(auth.user.user_accounts || [], auth.user.organisation_accounts || [])
            const match = user_combined_accounts.filter(acc => acc.id == account_id)

            return Array.isArray(match) && match.length > 0

        },
    }

})