<template>
    <CheckCircleIcon :class="class" />
</template>

<script>
import { CheckCircleIcon } from '@heroicons/vue/solid'

export default {
    props: ['class'],
    components: {
        CheckCircleIcon
    }
}
</script>