<template>
    <svg :class="class" :style="style" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 848.7 157.4" style="enable-background:new 0 0 848.7 157.4;" xml:space="preserve">
    <g>
	    <g>
		    <path class="logo_full--0" d="M237.2,31.9l33.6,87.8h-18.6l-7.9-21.2h-32.9l-7.7,21.2h-18.3L219,31.9H237.2z M217.2,83h21.4l-10.7-29.2
			    L217.2,83z"/>
		    <path class="logo_full--0" d="M336,119.7h-17.7v-5.9c-3.6,4-9.7,7.3-17.2,7.3c-14.3,0-22.5-9.4-22.5-23.6V55.7h17.7v38.2
			    c0,6.7,3.3,11.3,10.3,11.3c5.4,0,10.2-3.3,11.4-8.9V55.7H336V119.7z"/>
		    <path class="logo_full--0" d="M378,54.3c16,0,26.2,9.4,28,23h-17.4c-1-4.9-4.9-7.9-10.6-7.9c-8.2,0-13.2,7.4-13.2,18.3
			    c0,10.7,5,18.2,13.2,18.2c5.7,0,9.6-3,10.6-7.9H406c-1.9,13.7-12,23-28,23c-18.2,0-30.7-13.6-30.7-33.3
			    C347.3,67.9,359.8,54.3,378,54.3z"/>
		    <path class="logo_full--0" d="M421.3,70h-9.7V55.7h9.7V38.4h17.6v17.3h14V70h-14v28c0,5,2.7,7.3,7.3,7.3c2.7,0,5.7-0.9,7.7-2.1v15.7
			    c-2.4,1.3-6.7,2-11.4,2c-13,0-21.2-6.4-21.2-20.6V70z"/>
		    <path class="logo_full--0" d="M475.7,47.4c-5.7,0-10.6-4.1-10.6-10.2c0-5.9,4.9-10.2,10.6-10.2c5.6,0,10.4,4.3,10.4,10.2
			    C486.1,43.3,481.2,47.4,475.7,47.4z M484.5,119.7h-17.7V55.7h17.7V119.7z"/>
		    <path class="logo_full--0" d="M527.3,121.1c-18.6,0-31.3-13.7-31.3-33.3c0-19.7,12.7-33.5,31.3-33.5s31.3,13.7,31.3,33.5
			    C558.6,107.4,545.9,121.1,527.3,121.1z M527.3,69.3c-8.9,0-13.7,7.7-13.7,18.4c0,10.6,4.9,18.3,13.7,18.3
			    c8.9,0,13.7-7.7,13.7-18.3C541,77,536.1,69.3,527.3,69.3z"/>
		    <path class="logo_full--0" d="M569.9,55.7h17.7v5.9c3.4-4,9.7-7.3,17.2-7.3c14.4,0,22.6,9.4,22.6,23.6v41.8h-17.7v-38
			    c0-6.7-3.4-11.3-10.4-11.3c-5.4,0-10.2,3.3-11.6,8.9v40.5h-17.7V55.7z"/>
		    <path class="logo_full--0" d="M661.4,67.9h5c7.4,0,13.2-3.7,13.2-11c0-6.1-4.3-10.9-11.4-10.9c-8,0-12.3,5.3-12.9,12h-17.7
			    c1.7-15.6,13.2-27.6,30.7-27.6c17.9,0,28.6,10.6,28.6,25.6c0,8.4-4.4,16-10.7,19c8.2,3.6,12.2,11.2,12.2,19.2
			    c0,15.3-11.3,26.9-30,26.9c-18.9,0-31.5-11.6-32.5-28.6h18c0.9,7.7,5.6,13,14.3,13c7.4,0,12.7-4.6,12.7-11.6
			    c0-7.4-5.7-11.9-13.9-11.9h-5.6V67.9z"/>
		    <path class="logo_full--0" d="M752.2,54c-1.7-5.3-5.4-8.6-11.6-8.6c-10.4,0-17,12.3-17,24.6v1.3c2.9-5.4,9.9-9.3,18.4-9.3
			    c17.7,0,28.6,13.2,28.6,28.9c0,16.6-11.4,30.2-31,30.2c-11,0-20.7-5.1-26.6-15.9c-4.4-7.7-6.2-16.3-6.2-27.6
			    c0-27.3,12.6-47.2,33.6-47.2c16.6,0,26.3,8.9,29.5,23.6H752.2z M739.7,106.1c8.4,0,14.4-6,14.4-14.7c0-9.2-6-15.2-14.4-15.2
			    c-8.7,0-14.6,6-14.6,15.2C725.1,100.1,731,106.1,739.7,106.1z"/>
		    <path class="logo_full--0" d="M797.8,82.6h-14.3V31.9H835v15.9h-36.6v17.9c2.7-1.9,8-4,14.2-4c17,0,28.3,12,28.3,29.5
			    c0,17.9-12.3,30-30.2,30c-18.4,0-30.6-11.3-31.9-28.6h17.7c1,7.7,5.7,13,14.2,13c8,0,13-6,13-14.4c0-8.3-5-14.3-13.3-14.3
			    C804.8,76.8,800.6,79.3,797.8,82.6z"/>
	    </g>
	    <g>
		    <linearGradient id="SVGLF_1_" gradientUnits="userSpaceOnUse" x1="133.0167" y1="125.7571" x2="72.8351" y2="2.3666">
			    <stop  offset="0.362" style="stop-color:#285FF9"/>
			    <stop  offset="0.4458" style="stop-color:#275EF6"/>
			    <stop  offset="0.5094" style="stop-color:#2659EC"/>
			    <stop  offset="0.5663" style="stop-color:#2352DC"/>
			    <stop  offset="0.6192" style="stop-color:#1E48C5"/>
			    <stop  offset="0.6695" style="stop-color:#193AA7"/>
			    <stop  offset="0.7171" style="stop-color:#122A84"/>
			    <stop  offset="0.7592" style="stop-color:#0B195D"/>
		    </linearGradient>
		    <path class="logo_full--1" d="M56.7,10.3c25.2-10.7,54.3,1.1,65,26.2l32,79.1c-1.4,0.8-2.9,1.5-4.5,2.2c-25.2,10.7-54.3-1.1-65-26.2
			    l-6.9-20L52.2,12.5C53.6,11.7,55.1,11,56.7,10.3z"/>
		    <path class="logo_full--2" d="M95.4,10.3c-25.2-10.7-54.3,1.1-65,26.2L8.8,86.3l0,0l0,0c-10.7,25.2,1.1,54.3,26.2,65
			    c1.5,0.7,3.1,1.2,4.7,1.7l35.2-81.5c1.8-4.2,3.7-8.7,5.8-13.7c18-42.4,34-34.2,39.5-24.5C115,23.4,106.5,15,95.4,10.3z"/>
	    </g>
    </g>
</svg>
</template>

<script>
export default {
    props: ['class','style']
}
</script>


<style>
	.logo_full--0{fill:#0B193F;}
	.logo_full--1{fill:url(#SVGLF_1_);}
	.logo_full--2{fill:#FF1A22;}
</style>