/*******************************************************************************
 * Dependency Imports
 ******************************************************************************/

// Helpers
import { getEndpoint } from '@lib/helpers'
import config from '@/config'
import axios from 'axios'

/*******************************************************************************
 * Private methods
 ******************************************************************************/

/*******************************************************************************
 * Public methods
 ******************************************************************************/

/**
 * carf
 * @returns 
 */
export const csrf = async () => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'get',
            url: import.meta.env.VITE_API_HOST + 'sanctum/csrf-cookie',
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * login
 * @param {*} payload 
 * @returns 
 */
export const login = async payload => {

    return new Promise(( resolve, reject ) => {
        axios({
            method: 'post',
            url: getEndpoint('/auth/login'),
            data: payload,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * logout
 * @returns 
 */
 export const logout = async () => {

    return new Promise(( resolve, reject ) => {
        axios({
            method: 'post',
            url: getEndpoint('/auth/logout'),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * get the currently authenticated user
 * @returns 
 */
export const user = async () => {

    return new Promise(( resolve, reject ) => {
        axios({
            method: 'get',
            url: getEndpoint('/auth/user'),
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * get the currently authenticated user
 * @returns 
 */
 export const test = async () => {

    return new Promise(( resolve, reject ) => {
        axios({
            method: 'get',
            url: getEndpoint('/organisation/2/lenders'),
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * log an interaction for this user
 */
export const interaction = async data => {
    return new Promise(( resolve, reject ) => {
        axios({
            method: 'post',
            url: getEndpoint('/interaction'),
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * request a password reset
 * @param {*} payload 
 * @returns 
 */
export const requestPasswordReset = async payload => {

    return new Promise(( resolve, reject ) => {
        axios({
            method: 'post',
            url: getEndpoint('/password/request-reset'),
            data: payload,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(( response ) => {
            resolve( response );
        })
        .catch(( error ) => {
            reject( error );
        });
    });
};

/**
 * request a password reset
 * @param {*} payload 
 * @returns 
 */
export const resetPassword = async payload => {

    return new Promise(( resolve, reject ) => {
        axios({
            method: 'post',
            url: getEndpoint('/password/reset'),
            data: payload,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(( response ) => {
            resolve( response );
        })
        .catch(( error ) => {
            reject( error );
        });
    });
};