<template>
    <div class="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200">
        <div class="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap px-4 py-5 sm:p-6">
            <div class="ml-4 mt-2">
                <h3 class="text-lg leading-6 font-medium text-gray-900">Vehicle {{ loan_item_asset_vehicle_id }}</h3>
            </div>
        </div>
    
        <div class="px-4 py-5 sm:p-6">
            <div v-show="!state.asset.show_fields" class="mb-4">
                <label for="search_vrm" class="block text-sm font-medium text-gray-700">Vehicle number plate</label>
                <div class="mt-1 flex rounded-md shadow-sm">
                    <div class="relative flex items-stretch flex-grow focus-within:z-10 mr-2">
                        <div class="min-w-0 flex-1 px-0 grid grid-cols-1 gap-2">
                            <input v-model="state.search_vrm" type="text" name="search_vrm" id="search_vrm" class="border border-gray-300 focus:ring-blue-500 focus:border-blue-500 block w-full rounded-md pl-3 sm:text-sm border-gray-300" :class="{'border-red-700 bg-red-50 text-red-900 placeholder-red-600': state.search_vrm_error}" placeholder="Number plate" />
                        </div>
                    </div>
                    <button v-on:click="findVehicle" type="button" class="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm text-center font-medium rounded-md text-blue-700 bg-blue-100 hover:bg-blue-200 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500">
                        <span v-if="!state.search_loading">Search</span>
                        <LoadingIcon v-if="state.search_loading" class="h-5 w-5 animate-reverse-spin" />
                    </button>
                </div>
            </div>
            <FormField v-show="state.asset.show_fields" v-for="(field, key) in state.asset.fields" :key="key" :field="field" namespace="vehicle" :name="key" class="mb-4" />
            <FormField v-show="state.asset.show_fields" :field="state.proxy_tax.fields.tax_status" namespace="vehicle" name="tax_status" class="mb-4" />
        </div>
    
        <div class="px-4 py-4 sm:px-6 text-right">
            <Button type="button" width="w-auto" color="blue-outline" :onClick="LOAN.activatePrevStep">Back</Button>
            <Button type="button" width="w-auto" class="ml-2" :onClick="() => submitStep()" :disabled="state.asset.loading || !state.asset.fields.vin.value">Next</Button>
        </div>

        <!-- EXISTING FINANCE MODAL-->
        <Modal v-on:close="state.existing_finance_error_show = false" :active="state.existing_finance_error_show" size="xl">
            <div>
                <div class="mt-3 text-center sm:mt-5">
                    <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900">{{ state.existing_finance_error.heading }}</DialogTitle>
                    <div class="my-2">
                        <p class="text-sm text-gray-500">{{ state.existing_finance_error.body }}</p>
                        <p class="text-sm text-gray-500">{{ `` }}</p>
                    </div>
                </div>
            </div>
            <div class="mt-5 sm:mt-6">
                <Button v-if="!state.application_pending" type="button" color="red" :onClick="clearVehicleDataError">Dismiss</Button>
            </div>
        </Modal>

        <!-- CREATE NEW ASSET MODAL -->
        <Modal v-on:close="state.new_asset_modal_show = false" :active="state.new_asset_modal_show" size="sm">
            <div>
                <div class="mt-3 text-center sm:mt-5">
                    <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900">Create New Vehicle?</DialogTitle>
                    <div class="my-2">
                        <p class="text-sm text-gray-500">We couldn't find any existing vehicles with that VRM associated to this auctioneer.</p>
                        <p class="text-sm text-gray-500">You can either create this vehicle now, or start a new search.</p>
                    </div>
                </div>
            </div>
            <div class="mt-5 sm:mt-6">
                <Button type="button" color="blue" :onClick="getNewVehicleData" class="mb-4">Create vehicle</Button>
                <Button type="button" color="text-blue" :onClick="() => state.new_asset_modal_show = false">New search</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
import { useLoanStore } from '@sto/loan.store'
import { useSystemStore } from '@sto/system.store'
import { useAuthStore } from '@sto/auth.store'
import * as VehicleService from '@ser/vehicle'
import { computed, onMounted, onBeforeMount, reactive, watch } from 'vue'
import LoadingIcon from '@ico/LoadingIcon.vue'
import { isValid, getPayload, poundsToPence } from '@lib/helpers'
import { useRoute } from 'vue-router'
import config from '@/config'
export default {
    components: {
        LoadingIcon
    },
    setup() {
        const LOAN = useLoanStore()
        const AUTH_STORE = useAuthStore()
        const SYSTEM_STORE = useSystemStore()
        const ROUTE = useRoute()

        const loan_item = computed(() => {
            const items = LOAN?.loan?.items
            if (!items || (Array.isArray(items) && items.length === 0)) return null
            return items.find(i => i.type == 'asset') || null
        })

        const loan_id = computed(() => {
            return LOAN?.loan?.id || null
        })

        const loan_item_id = computed(() => {
            return loan_item?.value?.id || null
        })

        const loan_item_asset_id = computed(() => {
            return loan_item?.value?.asset?.id ? loan_item?.value?.asset?.id
                : loan_item?.value?.asset_id
        })

        const loan_item_asset_vehicle_id = computed(() => {
            return loan_item?.value?.asset?.vehicle?.id ? loan_item?.value?.asset?.vehicle?.id
                : loan_item?.value?.asset?.vehicle_id
        })

        const loan_lender_name = computed(() => {
            const len = LOAN.auctioneer_lenders.find(l => l.id == LOAN.loan.lender_id)
            return len?.name || ''
        })

        const buyer_dealer_classification = computed(() => {
            return  LOAN?.loan?.dealer_classification || null
        })

        const show_tax_applies_toggle = computed(() => {
            // if V12 return true because the option is always to be available
            if (loan_lender_name.value.toUpperCase() == 'V12') return true
            // if NextGear
            return state?.asset?.fields?.vehicle_class?.value != 'LCV'
        });

        const initialProxyTaxStatus = tax_status => {
            if (typeof tax_status === 'string' && tax_status.includes('apply_vat')) return 'apply_vat'
            if (typeof tax_status === 'string' && tax_status.includes('vat_qualifying')) return 'vat_qualifying'
            return 'no_vat'
        }

        const state = reactive({
            search_vrm: '',
            search_vrm_error: false,
            search_loading: false,
            existing_finance_error_show: false,
            existing_finance_error: {
                heading: null,
                body: null,
                data: null,
                disable_progress: false
            },
            new_asset_modal_show: false,
            existing_asset_id: null,
            existing_vehicle_id: null,
            asset: {
                show_fields: false,
                valid: false,
                has_changes: computed(() => {
                    let has_changes = false
                    for (let f in state.asset.fields) {
                        if (state.asset.fields[f].value != state.asset.fields[f].stored_value) has_changes = true
                    }
                    return has_changes
                }),
                loading: false,
                fields: {
                    vrm: {
                        active: true,
                        label: 'VRM',
                        type: 'text',
                        value: loan_item?.value?.asset?.vehicle?.vrm || '',
                        stored_value: loan_item?.value?.asset?.vehicle?.vrm || '',
                        required: true,
                        pristine: loan_item?.value?.asset?.vehicle?.vrm ? false : true,
                        valid: false,
                        message: '',
                        validation: [],
                        notes: '',
                        editable: false,
                        disabled: false
                    },
                    vin: {
                        active: true,
                        label: 'VIN',
                        type: 'text',
                        value: loan_item?.value?.asset?.vehicle?.vin || '',
                        stored_value: loan_item?.value?.asset?.vehicle?.vin || '',
                        required: true,
                        pristine: loan_item?.value?.asset?.vehicle?.vin ? false : true,
                        valid: false,
                        message: '',
                        validation: [],
                        notes: '',
                        editable: false,
                        disabled: false
                    },
                    make: {
                        active: true,
                        label: 'Make',
                        type: 'text',
                        value: loan_item?.value?.asset?.vehicle?.make || '',
                        stored_value: loan_item?.value?.asset?.vehicle?.make || '',
                        required: true,
                        pristine: loan_item?.value?.asset?.vehicle?.make ? false : true,
                        valid: false,
                        message: '',
                        validation: [],
                        notes: '',
                        editable: false,
                        disabled: false
                    },
                    model: {
                        active: true,
                        label: 'Model',
                        type: 'text',
                        value: loan_item?.value?.asset?.vehicle?.model || '',
                        stored_value: loan_item?.value?.asset?.vehicle?.model || '',
                        required: true,
                        pristine: loan_item?.value?.asset?.vehicle?.model ? false : true,
                        valid: false,
                        message: '',
                        validation: [],
                        notes: '',
                        editable: false,
                        disabled: false
                    },
                    colour: {
                        active: true,
                        label: 'Colour',
                        type: 'text',
                        value: loan_item?.value?.asset?.vehicle?.colour || '',
                        stored_value: loan_item?.value?.asset?.vehicle?.colour || '',
                        required: true,
                        pristine: loan_item?.value?.asset?.vehicle?.colour ? false : true,
                        valid: false,
                        message: '',
                        validation: [],
                        notes: '',
                        editable: false,
                        disabled: false
                    },
                    first_registered: {
                        active: true,
                        label: 'First registered',
                        type: 'datepicker',
                        min_date: null,
                        time_enabled: false,
                        date_format: 'dd-MM-yyyy',
                        date_clearable: true,
                        value: loan_item?.value?.asset?.vehicle?.first_registered || '',
                        stored_value: loan_item?.value?.asset?.vehicle?.first_registered || '',
                        required: true,
                        pristine: loan_item?.value?.asset?.vehicle?.first_registered ? false : true,
                        valid: false,
                        message: '',
                        validation: [],
                        notes: '',
                        editable: false,
                        disabled: false
                    },
                    registration_country: {
                        active: true,
                        label: 'Registration country',
                        type: 'select',
                        value: loan_item?.value?.asset?.vehicle?.registration_country || 'GBR',
                        stored_value: loan_item?.value?.asset?.vehicle?.registration_country || '',
                        options: config.country_alpha_codes,
                        required: true,
                        pristine: loan_item?.value?.asset?.vehicle?.registration_country ? false : true,
                        valid: false,
                        message: '',
                        validation: [],
                        notes: '',
                        editable: false,
                        disabled: false
                    },
                    manufacture_year: {
                        active: true,
                        label: 'Manufacture year',
                        type: 'number',
                        value: loan_item?.value?.asset?.vehicle?.manufacture_year || '',
                        stored_value: loan_item?.value?.asset?.vehicle?.manufacture_year || '',
                        required: true,
                        pristine: loan_item?.value?.asset?.vehicle?.manufacture_year ? false : true,
                        valid: false,
                        message: '',
                        validation: [],
                        notes: '',
                        editable: false,
                        disabled: false
                    },
                    body: {
                        active: true,
                        label: 'Body type',
                        type: 'text',
                        value: loan_item?.value?.asset?.vehicle?.body || '',
                        stored_value: loan_item?.value?.asset?.vehicle?.body || '',
                        required: true,
                        pristine: loan_item?.value?.asset?.vehicle?.body ? false : true,
                        valid: false,
                        message: '',
                        validation: [],
                        notes: '',
                        editable: false,
                        disabled: false
                    },
                    doors: {
                        active: true,
                        label: 'Doors',
                        type: 'number',
                        value: loan_item?.value?.asset?.vehicle?.doors || '',
                        stored_value: loan_item?.value?.asset?.vehicle?.doors || '',
                        required: true,
                        pristine: loan_item?.value?.asset?.vehicle?.doors ? false : true,
                        valid: false,
                        message: '',
                        validation: [],
                        notes: '',
                        editable: false,
                        disabled: false
                    },
                    engine_size: {
                        active: true,
                        label: 'Engine size CC',
                        type: 'number',
                        value: loan_item?.value?.asset?.vehicle?.engine_size || '',
                        stored_value: loan_item?.value?.asset?.vehicle?.engine_size || '',
                        required: false,
                        pristine: loan_item?.value?.asset?.vehicle?.engine_size ? false : true,
                        valid: false,
                        message: '',
                        validation: [],
                        notes: '',
                        editable: false,
                        disabled: false
                    },
                    engine_number: {
                        active: true,
                        label: 'Engine number',
                        type: 'text',
                        value: loan_item?.value?.asset?.vehicle?.engine_number || '',
                        stored_value: loan_item?.value?.asset?.vehicle?.engine_number || '',
                        required: false,
                        pristine: loan_item?.value?.asset?.vehicle?.engine_number ? false : true,
                        valid: false,
                        message: '',
                        validation: [],
                        notes: '',
                        editable: false,
                        disabled: false
                    },
                    fuel_type: {
                        active: true,
                        label: 'Fuel type',
                        type: 'text',
                        value: loan_item?.value?.asset?.vehicle?.fuel_type || '',
                        stored_value: loan_item?.value?.asset?.vehicle?.fuel_type || '',
                        required: true,
                        pristine: loan_item?.value?.asset?.vehicle?.fuel_type ? false : true,
                        valid: false,
                        message: '',
                        validation: [],
                        notes: '',
                        editable: false,
                        disabled: false
                    },
                    transmission: {
                        active: true,
                        label: 'Transmission',
                        type: 'text',
                        value: loan_item?.value?.asset?.vehicle?.transmission || '',
                        stored_value: loan_item?.value?.asset?.vehicle?.transmission || '',
                        required: true,
                        pristine: loan_item?.value?.asset?.vehicle?.transmission ? false : true,
                        valid: false,
                        message: '',
                        validation: [],
                        notes: '',
                        editable: false,
                        disabled: false
                    },
                    mileage: {
                        active: true,
                        label: 'Mileage',
                        type: 'number',
                        value: loan_item?.value?.asset?.vehicle?.mileage || '',
                        stored_value: loan_item?.value?.asset?.vehicle?.mileage || '',
                        required: true,
                        pristine: loan_item?.value?.asset?.vehicle?.mileage ? false : true,
                        valid: false,
                        message: '',
                        validation: [],
                        notes: '',
                        editable: true,
                        disabled: false
                    },
                    cost_net: {
                        active: true,
                        label: 'Hammer price NET',
                        type: 'number',
                        value: loan_item?.value?.cost_net ? (loan_item.value.cost_net / 100).toFixed(2) : '',
                        stored_value: loan_item?.value?.cost_net ? (loan_item.value.cost_net / 100).toFixed(2) : '',
                        transformer: poundsToPence,
                        required: true,
                        pristine: loan_item?.value?.cost_net ? false : true,
                        valid: false,
                        message: '',
                        validation: [],
                        notes: '',
                        editable: true,
                        disabled: false
                    },
                    vehicle_class: {
                        active: true,
                        label: 'Vehicle class',
                        type: 'radio',
                        value: loan_item?.value?.asset?.vehicle?.vehicle_class || 'CAR',
                        stored_value: loan_item?.value?.asset?.vehicle?.vehicle_class || '',
                        options: [
                            {
                                value: 'CAR',
                                label: 'Passenger'
                            },
                            {
                                value: 'LCV',
                                label: 'Commercial'
                            }
                        ],
                        layout: 'inline',
                        required: true,
                        pristine: loan_item?.value?.asset?.vehicle?.vehicle_class ? false : true,
                        valid: false,
                        message: '',
                        validation: [],
                        notes: '',
                        editable: true,
                        disabled: false
                    },
                    tax_status: {
                        active: true,
                        label: 'Tax status',
                        type: 'hidden',
                        value: loan_item?.value?.asset?.tax_status || 'car_no_vat',
                        stored_value: loan_item?.value?.asset?.tax_status || '',
                        required: false,
                        pristine: false,
                        valid: false,
                        message: '',
                        validation: [],
                        notes: '',
                        editable: true,
                        disabled: false
                    }
                }
            },
            proxy_tax: {
                fields: {
                    tax_status: {
                        active: true,
                        label: 'Tax status',
                        type: 'radio',
                        value: initialProxyTaxStatus(loan_item?.value?.asset?.tax_status),
                        stored_value: '',
                        options: [
                            {
                                value: 'no_vat',
                                label: 'No VAT'
                            },
                            {
                                value: 'apply_vat',
                                label: 'Apply VAT'
                            },
                            {
                                value: 'vat_qualifying',
                                label: 'VAT Qualifying'
                            }
                        ],
                        layout: 'inline',
                        required: true,
                        pristine: true,
                        valid: false,
                        message: '',
                        validation: [],
                        notes: '',
                        editable: true,
                        disabled: false
                    }
                }
            }
        })

        const clearVehicleDataError = () => {
            state.existing_finance_error = {
                heading: null,
                body: null,
                data: null,
                disable_progress: false
            }
            state.existing_finance_error_show = false
        }

        const setVehicleDataError = (heading = null, body = null, data = null, disable_progress = false) => {
            state.existing_finance_error = {
                heading: heading,
                body: body,
                data: data,
                disable_progress: disable_progress
            }
            state.existing_finance_error_show = true
        }

        const setVehicleData = vehicle_data => {

            console.log('setVehicleData', vehicle_data)

            if (!vehicle_data) return

            for (let field in state.asset.fields) {

                if (vehicle_data[field] || vehicle_data['vehicle_'+field]) {
                    state.asset.fields[field].value = vehicle_data[field] ? vehicle_data[field]
                        : vehicle_data['vehicle_'+field]
                    state.asset.fields[field].disabled = !state.asset.fields[field].editable
                }
                state.asset.fields[field].pristine = false
            }

            state.asset.show_fields = true

            state.existing_asset_id = vehicle_data?.asset_id || null
            state.existing_vehicle_id = vehicle_data?.vehicle_id ? vehicle_data?.vehicle_id
                : vehicle_data?.vehicle_id || null
        }

        const findExistingVehicle = async () => {

            try {

                const vehicles = await VehicleService.vrmCheck(state.search_vrm, AUTH_STORE.user?.id)
                return vehicles.length > 0 ? vehicles[0]
                    : null

            } catch (error) {
                console.error('VehicleService.vrmCheck error', error)
                return null
            }

        }

        const findVehicle = async () => {

            state.search_vrm_error = false

            let search_error = ''

            if (!state.search_vrm) {
                state.search_vrm_error = true
                search_error = 'Vehicle number plate is a required field'
            }

            if (search_error.length > 0) return SYSTEM_STORE.addError(search_error)

            const existing_asset = await findExistingVehicle()

            if (!existing_asset) {
                return state.new_asset_modal_show = true
            }

            setVehicleData(existing_asset)
        }

        const getNewVehicleData = async () => {

            state.new_asset_modal_show = false
            state.search_loading = true

            clearVehicleDataError()

            try {
                const vehicle_data = await VehicleService.data(state.search_vrm)
                if (vehicle_data) setVehicleData(vehicle_data)
            } catch (error) {

                console.error('error', error)
                
                switch(error?.status) {
                    case 422:
                        setVehicleDataError('Existing Finance Alert', 'This vehicle has existing finance so it does not qualify for a new loan agreement', error?.data || null, true)
                        break;
                }

            } finally {
                state.search_loading = false
            }
        }

        const submitStep = async () => {
            // fields will automatically create their own validation states
            if (state.asset.loading || !isValid(state.asset.fields)) return

            // if the value is present and unchaned, just skip to the next step
            if (!state.asset.has_changes) {
                LOAN.activateNextStep()
                return
            }

            state.asset.loading = true

            try {

                const asset_payload = getPayload(state.asset.fields)
                // we append the ids for the item/asset/vehicle so they can be updated if they already exist
                asset_payload.item_id = loan_item_id.value
                asset_payload.asset_id = loan_item_asset_id.value ? loan_item_asset_id.value
                    : state.existing_asset_id ? state.existing_asset_id
                    : null
                asset_payload.vehicle_id = loan_item_asset_vehicle_id.value ? loan_item_asset_vehicle_id.value
                    : state.existing_vehicle_id ? state.existing_vehicle_id
                    : null

                await LOAN.createOrUpdateVehicle(LOAN.loan.id, asset_payload)

                LOAN.activateNextStep()
            } catch (error) {
                console.error('submitStep', error)
                // TODO handle error
            }

            state.asset.loading = false
        }

        const setLoanAssetItem = () => {
            // make sure we have an item
            if (!loan_item) return
            // if we have a VIN, show the fields
            if (loan_item?.value?.asset?.vehicle?.vin || loan_item?.value?.asset?.vehicle_vin || state.existing_asset_id) state.asset.show_fields = true
        }

        const customiseSteps = () => {

            /**
             * V12 have some price restrictions. These are also validated on the back end when a loan is prepared for submittion, but for user here too
             */
            if (loan_lender_name.value == 'V12') {

                // manufacture year
                state.asset.fields.manufacture_year.notes = 'V12 Finance is only available for vehicles less than 15 years old'
                state.asset.fields.manufacture_year.validation.push({ type: 'min', value: new Date().getFullYear() - 15 })

                // mileage
                state.asset.fields.mileage.notes = 'V12 Finance is not available for vehicles that have done more than 150,000 miles'
                state.asset.fields.mileage.validation.push({ type: 'max', value: 150000 })

                // cost net
                state.asset.fields.cost_net.notes = 'Finance for V12 ' + buyer_dealer_classification.value.toLowerCase() + ' dealers is only available for vehicles over £1,500'

                state.asset.fields.cost_net.validation.push({ type: 'min-price', value: 1500 })

                if (buyer_dealer_classification.value == 'PRIME') {
                    state.asset.fields.cost_net.notes += ' and under £65,000'
                    state.asset.fields.cost_net.validation.push({ type: 'max-price', value: 65000 })
                }

                if (buyer_dealer_classification.value == 'FRANCHISE') {
                    state.asset.fields.cost_net.notes += ' and under £65,000'
                    state.asset.fields.cost_net.validation.push({ type: 'max-price', value: 65000 })
                }

                if (buyer_dealer_classification.value == 'PARTNER') {
                    state.asset.fields.cost_net.notes += ' and under £25,000'
                    state.asset.fields.cost_net.validation.push({ type: 'max-price', value: 25000 })
                }
            }
        }

        onMounted(() => {
            customiseSteps()
            setLoanAssetItem()
            isValid(state.asset.fields)
            console.log('loan_item?.value?.asset', loan_item?.value?.asset)
        })

        watch(
            () => loan_item_asset_vehicle_id.value,
            (new_value, old_value) => {
                setLoanAssetItem()
            }
        )

        watch(
            () => state.loan_lender_name,
            (new_value, old_value) => {
                customiseSteps()
            }
        )

        watch(
            () => state.asset.fields.vehicle_class.value,
            (new_value) => {

                console.log('vehicle_class', new_value)

                switch (new_value) {
                    case 'LCV':
                        state.asset.fields.tax_status.value = `commercial_${state.proxy_tax.fields.tax_status.value}`
                        break
                    case 'CAR':
                        state.asset.fields.tax_status.value = `car_${state.proxy_tax.fields.tax_status.value}`
                        break
                }
            }
        )

        watch(
            () => state.proxy_tax.fields.tax_status.value,
            (new_value) => {

                console.log('tax_status', new_value)

                let prefix = state.asset.fields.vehicle_class.value === 'LCV'
                    ? 'commercial'
                    : 'car'

                state.asset.fields.tax_status.value = `${prefix}_${new_value}`
            }
        )

        watch(
            () => state.search_vrm,
            (new_value, old_value) => {
                if (new_value.length > 0) state.search_vrm_error = false
            }
        )

        return {
            LOAN,
            state,
            loan_item_asset_vehicle_id,
            findVehicle,
            getNewVehicleData,
            submitStep,
            clearVehicleDataError
        }
    }
}

</script>