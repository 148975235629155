<template>
    <div class="relative pb-8">
        <span v-if="!is_last" class="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
        <div class="relative flex space-x-3">
            <div>
                <span class="h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white" :class="event_format.background_color">
                    <component :is="event_icon" class="h-6 w-6 text-white" />
                </span>
            </div>
            <div class="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                <div>
                    <p class="text-sm text-gray-500" v-html="event_message"></p>
                </div>
                <div class="whitespace-nowrap text-right text-sm text-gray-500">
                    <time :datetime="event_date.full">{{ event_date.label }}</time>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive, computed, shallowRef } from 'vue'
import { useLoanStore } from '@sto/loan.store'
import CrossIcon from '@ico/CrossIcon.vue'
import CheckIcon from '@ico/CheckIcon.vue'
import moment from 'moment'
export default {
    components: {
        CrossIcon,
        CheckIcon
    },  
    props: ['event', 'is_last'],
    setup(props) {

        const LOAN = useLoanStore()

        const loan_lender_name = computed(() => {
            if (!props.event?.payload?.lender_id) return 'the lender'
            const len = LOAN.organisation_lenders.find(l => l.id == props.event?.payload?.lender_id)
            return len?.name || 'the lender'
        })
        
        const event_format = reactive({
            background_color: computed(() => {
                switch(props.event.loan_status) {
                    case 'DRAFT':
                    case 'SAVED':
                    case 'PENDING':
                        return 'bg-blue-500'
                    case 'PASS':
                        return 'bg-green-500'
                    case 'ERROR':
                    case 'FAIL':
                    case 'REFER':
                        return 'bg-red-500'
                    default:
                        return 'bg-gray-500'
                }
            })
        })

        const event_message = computed(() => {
            switch(props.event.type) {
                case 'created':
                    return `Draft loan application raised by <span class="font-medium text-gray-900">${props.event.user.first_name} ${props.event.user.last_name}</span>`
                case 'submitted':
                    return `The loan application was <span class="font-medium text-gray-900">submitted to ${loan_lender_name.value}</span> by ${props.event.user.first_name} ${props.event.user.last_name}`
                case 'response_fail':
                    return `The loan application was <span class="font-medium text-gray-900">rejected by ${loan_lender_name.value}</span>`
                default:
                    return props.event.notes
            }
        })

        const event_icon = computed(() => {
            switch(props.event.type) {
                case 'response_fail':
                    return CrossIcon
                default:
                    return CheckIcon
            }
        })

        const event_date = computed(() => {
            return {
                full: moment(props.event.datetime).format('YYYY-MM-DD'),
                label: moment(props.event.datetime).format('MMM Do'),
            }
        })

        return {
            event_format,
            event_message,
            event_icon,
            event_date
        }
    },
}
</script>