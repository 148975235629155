<template>
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" :stroke-width="stroke_width || '2'" stroke-linecap="round" stroke-linejoin="round" stroke="currentColor" fill="none" viewBox="0 0 26 26" style="enable-background:new 0 0 26 26;" xml:space="preserve" :class="class">
		<path d="M18.8,24.4L8.1,13.7c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.2,0.1-0.4,0.2-0.5L18.8,1.9"/>
    </svg>
</template>

<script>
export default {
    props: ['class', 'stroke_width'],
}
</script>