<template>
    <span class="relative inline-block">

        <span v-if="!_avatar_url" :class="`inline-flex h-${_size} w-${_size} items-center justify-center rounded-full bg-gray-500`">
            <span :class="`${text_size} font-medium leading-none text-white`" v-html="initials"></span>
        </span>

        <img v-if="_avatar_url" :class="`inline-block h-${_size} w-${_size} rounded-full`" :src="_avatar_url" :alt="`${_user.first_name} ${_user.last_name}`" />

        <span v-if="is_internal" class="absolute rounded-full bg-white ring-2 ring-white" :class="badge_style">
            <LogoMark />
        </span>
    </span>
</template>

<script>
import { computed, onMounted } from 'vue'
import { useAuthStore } from '@sto/auth.store'
export default {
    props: ['user', 'size', 'self'],
    setup(props) {

        const AUTH_STORE = useAuthStore()

        const _size = computed(() => {
            if (!props.size || isNaN(props.size)) return '14'

            return props.size
        })

        const _user = computed(() => {
            if (props.user) return props.user
            return props.self ? AUTH_STORE.user : null
        })

        const _avatar_url = computed(() => {
            return _user.value?.avatar_image || null
        })

        const text_size = computed(() => {
            if (_size.value <= 6 ) {
                return 'text-xs'
            } else if (_size.value <= 8) {
                return 'text-sm'
            } else if (_size.value <= 10) {
                return 'text-md'
            } else if (_size.value <= 12) {
                return 'text-lg'
            } else {
                return 'text-xl'
            }
        })

        const initials = computed(() => {
            if (!_user.value) return null
            let name = `${_user.value.first_name} ${_user.value.last_name}`
            let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');
            let initials = [...name.matchAll(rgx)] || [];
            return ((initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')).toUpperCase();
        })

        const is_internal = computed(() => {
            return Array.isArray(_user.value?.roles) && _user.value?.roles.includes('USER_AUCTION365_INTERNAL')
        })

        const badge_style = computed(() => {
            if (_size.value <= 6 ) {
                return 'bottom-[-0.35rem] right-[-0.35rem] block h-3 w-3'
            } else if (_size.value <= 8) {
                return 'bottom-[-0.35rem] right-[-0.35rem] block h-4 w-4 p-[1px]'
            } else if (_size.value <= 10) {
                return 'bottom-[-0.2rem] right-[-0.2rem] block h-4 w-4 p-[1px]'
            } else {
                return 'bottom-[-0.3rem] right-[-0.3rem] block h-5 w-5 p-[2px]'
            }
        })

        return {
            initials,
            _size,
            _avatar_url,
            _user,
            text_size,
            is_internal,
            badge_style
        }
    },
}
</script>