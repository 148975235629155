<template>
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" :stroke-width="stroke_width || '2'" stroke-linecap="round" stroke-linejoin="round" stroke="currentColor" fill="none" viewBox="0 0 26 26" style="enable-background:new 0 0 26 26;" xml:space="preserve" :class="class">
		<path d="M13.3,24.4c6.2,0,11.2-5,11.2-11.2s-5-11.2-11.2-11.2c-6.2,0-11.2,5-11.2,11.2S7.1,24.4,13.3,24.4z"/>
		<path d="M5.4,21.1L21.3,5.2"/>
    </svg>
</template>

<script>
export default {
    props: ['class'],
}
</script>