<template>
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" :stroke-width="stroke_width || '2'" stroke-linecap="round" stroke-linejoin="round" stroke="currentColor" fill="none" viewBox="0 0 26 26" style="enable-background:new 0 0 26 26;" xml:space="preserve" :class="class">
		<g>
			<path d="M21.2,10.9v-3c0-0.8-0.7-1.5-1.5-1.5H9.2V4.9c0-0.8-0.7-1.5-1.5-1.5H3.2
				c-0.8,0-1.5,0.7-1.5,1.5v16.3c0,0.8,0.6,1.6,1.5,1.7c0.8,0.1,1.6-0.4,1.9-1.2L7.4,12c0.2-0.6,0.8-1.1,1.4-1.1h13.9
				c0.5,0,0.9,0.2,1.2,0.6c0.3,0.4,0.4,0.8,0.3,1.3l-2.2,9c-0.2,0.7-0.8,1.1-1.4,1.1H3.4"/>
		</g>
    </svg>
</template>

<script>
export default {
    props: ['class','stroke_width'],
}
</script>