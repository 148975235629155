<template>
    <nav class="hidden sm:flex mb-4" aria-label="Breadcrumb">
        <ol role="list" class="flex items-center space-x-2">
            <li v-for="(item, index) in breadcrumbs.data" :key="index">
                <div class="flex" :class="{'items-center': index === 0}">
                    <ChevronRightIcon v-if="index > 0" class="h-5 w-5" />
                    <router-link v-if="index < (breadcrumbs.data.length - 1)" :to="{ name: item.name }" class="text-sm font-medium text-gray-500 hover:text-gray-700" :class="{'ml-2': index > 0}">{{ item.label }}</router-link>
                    <span v-if="index == (breadcrumbs.data.length - 1)" class="text-sm font-medium text-gray-700" :class="{'ml-2': index > 0}">{{ item.label }}</span>
                </div>
            </li>
        </ol>
    </nav>
</template>


<script>
import ChevronRightIcon from '@ico/ChevronRightIcon.vue'
import { useRoute } from 'vue-router'
import { computed, reactive } from 'vue'
export default {
    components: {
        ChevronRightIcon
    },
    setup() {
        const ROUTE = useRoute()

        const breadcrumbs = reactive({ 
            data: computed(() => {
                if (!ROUTE.meta?.breadcrumbs || !Array.isArray(ROUTE.meta.breadcrumbs)) return null
                return ROUTE.meta.breadcrumbs.concat([{label: ROUTE.meta.title}])
            })
        })

        return {
            breadcrumbs
        }
    },
}
</script>