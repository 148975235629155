/*******************************************************************************
 * Dependency Imports
 ******************************************************************************/
// auth::api
import * as Api from './api'
// lodash - https://lodash.com/
import _ from 'lodash'

/*******************************************************************************
 * Services
 ******************************************************************************/

/*******************************************************************************
 * Configuration
 ******************************************************************************/

/*******************************************************************************
 * Transformer prototypes
 ******************************************************************************/

/*******************************************************************************
 * Private methods
 ******************************************************************************/

/**
 * general success
 *
 * @param {obj} data
 * @return {obj} le data
 */
const _onSuccess = data => {

    if( _.get( data, 'data.data' ) ) {
        return _.get( data, 'data.data' )
    }

    return data

}

/**
 * general fail
 *
 * @param {obj} error from a server
 * @return {obj} the error
 */
const _onFailed = error => new Promise(( resolve, reject ) => {

    let error_obj = error.hasOwnProperty( 'response' ) ? error.response : error

    //reject( _.get( error_obj, 'data', null ) || error_obj )

    reject(error_obj)

})

/*******************************************************************************
 * Public methods
 ******************************************************************************/

/**
 * create a loan for an organisation
 *
 * @return {promise}
 */
export const create = (auctioneer_id, payload = null) =>
    Api.create(auctioneer_id, payload)
        .then( _onSuccess )
        .catch( _onFailed )

/**
 * update a loan for an organisation
 *
 * @return {promise}
 */
export const update = (loan_id, payload = null) =>
    Api.update(loan_id, payload)
        .then( _onSuccess )
        .catch( _onFailed )

/**
 * delete a loan for an organisation
 *
 * @return {promise}
 */
export const remove = (loan_id, auctioneer_id) =>
    Api.remove(loan_id, auctioneer_id)
        .then( _onSuccess )
        .catch( _onFailed )

/**
 * get a loan from an organisation
 *
 * @return {promise}
 */
export const show = async (loan_id = null) =>
    Api.show(loan_id)
        .then( _onSuccess )
        .catch( _onFailed )

/**
 * get lenders for an organisation
 *
 * @param {int} organisation_id
 * @return {promise}
 */
export const organisationLenders = organisation_id =>
    Api.organisationLenders(organisation_id)
        .then( _onSuccess )
        .catch( _onFailed )

/**
 * get loan meta for an organisation
 *
 * @param {int} auctioneer_id
 * @return {promise}
 */
export const auctioneerMeta = auctioneer_id =>
    Api.auctioneerMeta(auctioneer_id)
        .then( _onSuccess )
        .catch( _onFailed )


/**
 * create or update a vehicle for this loan
 *
 * @return {promise}
 */
export const createOrUpdateVehicle = (loan_id, payload = null) =>
    Api.createOrUpdateVehicle(loan_id, payload)
        .then( _onSuccess )
        .catch( _onFailed )


/**
 * create an item for this loan
 *
 * @return {promise}
 */
export const createItem = (loan_id, auctioneer_id, payload = null) =>
    Api.createItem(loan_id, auctioneer_id, payload)
        .then( _onSuccess )
        .catch( _onFailed )

/**
 * delete loan item
 *
 * @return {promise}
 */
export const deleteItem = (loan_id, auctioneer_id, item_id) =>
    Api.deleteItem(loan_id, auctioneer_id, item_id)
        .then( _onSuccess )
        .catch( _onFailed )

/**
 * prepare an invoice
 *
 * @return {promise}
 */
export const prepareInvoice = (loan_id, organisation_id) =>
    Api.prepareInvoice(loan_id, organisation_id)
        .then( _onSuccess )
        .catch( _onFailed )

/**
 * list loans for organiations
 */
export const listForUser = async (user_id, page = 1, per_page = 10) =>
    Api.listForUser(user_id, page, per_page)
        .then( _onSuccess )
        .catch( _onFailed )

/**
 * submit a loan application
 */
export const submitApplication = (loan_id, auctioneer_id) =>
    Api.submitApplication(loan_id, auctioneer_id)
        .then( _onSuccess )
        .catch( _onFailed )

/**
 * get the events for a specific loan
 */
export const events = (loan_id, auctioneer_id) =>
    Api.events(loan_id, auctioneer_id)
        .then( _onSuccess )
        .catch( _onFailed )

/**
 * get the records for a specific loan
 */
export const records = (loan_id, auctioneer_id) =>
    Api.records(loan_id, auctioneer_id)
        .then( _onSuccess )
        .catch( _onFailed )

