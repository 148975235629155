<!--
    Returns the slot content if the currently authenticated user has at leat one auctionner association
-->

<template>
	<slot v-if="user_has_auctioneer"></slot>
</template>

<script>
import { computed } from 'vue'
import { useAuthStore } from '@sto/auth.store'
export default {
	setup() {
    	const AUTH_STORE = useAuthStore()

        const user_has_auctioneer = computed(() => AUTH_STORE.user_has_auctioneer)

        return {
            user_has_auctioneer
        }
    }
}
</script>