import { defineStore } from 'pinia'

import * as AlertService from '@ser/alert'

import { shallowRef } from 'vue'

import moment from 'moment'

export const useAlertStore = defineStore('alert', {

    state: () => {
        return {
            _global_modal_active: false,
            _loading: false,
            _user_alerts: [],
            _user_alerts_pagination: {
                total: null,
                current_page: 1,
                per_page: 10,
                last_page: null
            },
            _new_alert_audio: new Audio('/assets/audio/alert_new.mp3'),
            _last_alert_audio_time: null,
        }
    },

    getters: {
        global_modal_active: state => state._global_modal_active,
        alerts: state => state._user_alerts,
        new_alerts: state => state._user_alerts.filter(n => n.acknowledged == 0),
        pagination: state => state._user_alerts_pagination,
        loading: state => state._loading
    },

    actions: {

        resetStore() {
            this._global_modal_active = false
        },

        setGlobalModal(active) {
            this._global_modal_active = active
        },

        /**
         * 
         * @returns trigger an alert sound
         */
        triggerAlertSound() {
            // timestamp now (seconds)
            const time_now = moment().unix()
            // if we fired off a sound less than ten seconds ago, don't play the sound again
            if (this._last_alert_audio_time && (time_now - this._last_alert_audio_time) <= 10) return
            this._last_alert_audio_time = time_now
            this._new_alert_audio.play()
        },

        async getUserAlerts(user_id, page = 1, audio_alert = false) {
            try {
                this._loading = true
                const user_alerts = await AlertService.getForUser(user_id, [{ key: 'page', value: page }])

                if (user_alerts?.alerts) {
                    user_alerts?.alerts.forEach(n => {
                        n.payload = typeof n.payload == 'string' ? JSON.parse(n.payload) : n.payload
                        n.payload = typeof n.payload == 'string' ? JSON.parse(n.payload) : n.payload
                    })
                }

                if (!Array.isArray(user_alerts?.alerts)) throw new Error('get alerts returned an unexpected type in place of alerts array')

                // either replace alerts (if page 1) or append alerts (if page > 1)
                if (!isNaN(user_alerts?.pagination?.current_page) && user_alerts?.pagination?.current_page > 1) {
                    this._user_alerts.push(...user_alerts?.alerts)
                } else {
                    this._user_alerts = user_alerts?.alerts
                }
                
                this._user_alerts_pagination.total = user_alerts?.pagination?.total
                this._user_alerts_pagination.current_page = user_alerts?.pagination?.current_page
                this._user_alerts_pagination.per_page = user_alerts?.pagination?.per_page
                this._user_alerts_pagination.last_page = user_alerts?.pagination?.last_page

                if (audio_alert && this.new_alerts.length > 0) this.triggerAlertSound()

            } catch (error) {
                console.error('AlertService.getForUser error', error)
            } finally {
                this._loading = false
            }
        },

        async update(alert_id, payload) {
            try {
                const alert = await AlertService.update(alert_id, payload)
            } catch (error) {
                console.error('AlertService.update error', error)
            }
        },

        async delete(alert_id) {
            this._user_alerts = this._user_alerts.filter(a => a.id != alert_id)
            try {
                const alert = await AlertService.deleteById(alert_id)
            } catch (error) {
                console.error('AlertService.deleteById error', error)
            }
        },

        async deleteAll(user_id) {
            try {
                const delete_all = await AlertService.deleteForUser(user_id)
            } catch (error) {
                console.error('AlertService.deleteForUser error', error)
            }
        },

        async acknowledgeAll(user_id) {
            try {
                const update_all = await AlertService.updateForUser(user_id, {'acknowledged': 1})
            } catch (error) {
                console.error('AlertService.updateForUser error', error)
            }
        }
    }

})