/*******************************************************************************
 * Dependency Imports
 ******************************************************************************/
import config from '@/config'

/*******************************************************************************
 * Methods
 ******************************************************************************/

/**
 * all field validation
 */
export const isValid = (field_set, keep_pristine = false) => {

    let is_valid = true
    // loop through the fields in this form section
    for(let field in field_set) {
        // check if this field is invalid and set the form to invalid if it is
        if(isValidField(field_set[field], keep_pristine) === false) is_valid = false
    }
    return is_valid
}

/**
 * single field validation
 *
 * @param {obj} field we are validating
 */
export const isValidField = (field, keep_pristine) => {
    
    let is_valid = true
    // if this field is inactive, ,don't validate ... look for exact match of bool false
    if (field.active === false) return true
    // remove the pristine flag from this field to indicate it has been submitted at least once
    if(!keep_pristine) removePristine(field)
    // remove any legacy invalid flags before we start again
    setFieldValid(field)
    // check that required fields are present
    if (field.required && !field.value) {
        // set this field to invalid state with message
        setFieldInvalid(field, 'This is a required field.')
        // set the valid flag to false
        is_valid = false
    }
    // run through each of the fields
    for (let i = 0; i < field.validation.length; i++) {
        switch (field.validation[i].type) {
            case 'min':
                if (field.value < field.validation[i].value) {
                    // set this field to invalid state with message
                    setFieldInvalid(field, 'The value must be ' + field.validation[i].value + ' or above')
                    // set the valid flag to false
                    is_valid = false
                }
                break;
            case 'min-price':
                if (field.value < field.validation[i].value) {
                    // set this field to invalid state with message
                    setFieldInvalid(field, 'The value must be £' + field.validation[i].value + ' or higher')
                    // set the valid flag to false
                    is_valid = false
                }
                break;
            case 'max':
                if (field.value > field.validation[i].value) {
                    // set this field to invalid state with message
                    setFieldInvalid(field, 'The value can not be greater than ' + field.validation[i].value)
                    // set the valid flag to false
                    is_valid = false
                }
                break;
            case 'max-price':
                if (field.value > field.validation[i].value) {
                    // set this field to invalid state with message
                    setFieldInvalid(field, 'The value can not be greater than £' + field.validation[i].value)
                    // set the valid flag to false
                    is_valid = false
                }
                break;
            case 'max-char':
                if (field.value.length > field.validation[i].value) {
                    // set this field to invalid state with message
                    setFieldInvalid(field, 'Maximum ' + field.validation[i].value + ' characters allowed')
                    // set the valid flag to false
                    is_valid = false
                }
                break;
            case 'min-char':
                if (field.value.length < field.validation[i].value) {
                    // set this field to invalid state with message
                    setFieldInvalid(field, 'Minimum ' + field.validation[i].value + ' characters required')
                    // set the valid flag to false
                    is_valid = false
                }
                break;
            case 'characters':
                // ignore the cahracter validation if the field is not required and no value has been entered
                if (!field.required && field.value == '') {
                    break;
                }
                if (hasValidCharacters(field.validation[i].value, field.value) === false) {
                    // set this field to invalid state with message
                    setFieldInvalid(field, 'Please only use valid characters')
                    // set the valid flag to false
                    is_valid = false
                }
                break;
            case 'password':
                if (hasValidCharacters('password', field.value) === false || field.value.length < 8) {
                    // set this field to invalid state with message
                    setFieldInvalid(field, 'Your password must be at least 8 characters, and contain uppercase letters, numbers, and symbols (e.g. !, £, or $)')
                    // set the valid flag to false
                    is_valid = false
                }
                // do this separately - it's so unlikely and I dont' want it in the normal message
                if (field.value.length > 60) {
                    // set this field to invalid state with message
                    setFieldInvalid(field, 'Your password must contain fewer than 60 characters')
                    // set the valid flag to false
                    is_valid = false
                }
                break
        }
    }
    return is_valid
}

/**
 * check a string against a defined pattern to check for validity
 *
 * @param {str} type - the type of check we're running against the string (see switch(type))
 * @param {str} value - the string to test for validity
 * @returns {bool}
 */
export const hasValidCharacters = (type, value) => {
    switch (type) {
        // only letters (upper or lowercase)
        case 'letters':
            return /^[a-zA-Z]+$/.test(value)
        // only numbers
        case 'numbers':
            return /^[0-9]+$/.test(value)
        // only letters and numbers
        case 'alphanumeric':
            return /^[a-z0-9]+$/i.test(value)
        // correctly formed email address - shouldn't really be doing this
        case 'email':
            ///[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
            return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(String(value).toLowerCase())
        // password per api - TBC (currently at least one letter and one number)
        case 'password':
            return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/.test(value)
        case 'url':
            return /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/.test(value)
        case 'vrm':
            return /^[a-z0-9\s]+$/i.test(value)
        case 'no-spaces':
            return !/\s/g.test(value);
        case 'slug':
            return /^[a-zA-Z0-9-_]+$/.test(value)
    }
}

/**
 * remove pristine flag from field
 */
export const removePristine = (field) => {
    field.pristine = false
}

/**
 * sets a field to invalid
 */
export const setFieldInvalid = (field, message) => {
    field.valid = false
    field.message = field.message.length > 0 ? field.message + ' ' + message : message
}

/**
 * sets a field to valid
 */
export const setFieldValid = (field) => {
    field.valid = true
    field.message = ''
}

/**
 * reset a fieldset's state
 */
export const resetFieldset = (field_set, exclude = []) => {

    for (let field in field_set) {

        // all need to be pristine with default invalid
        field_set[field].pristine = true
        field_set[field].valid = false

        // allow specificed keys to be excluded
        if (exclude.includes(field)) continue

        // if a default is present =, honour it
        if (field_set[field].hasOwnProperty('default_value')) {
            field_set[field].value = field_set[field].default_value
            continue
        }
        // set the value of simple types
        if (['text', 'textarea', 'number', 'color', 'datepicker', 'hidden', 'radio'].includes(field_set[field].type)) {
            field_set[field].value = ''
            continue
        }

        // reset a select
        if (field_set[field].type == 'select') {
            if (!Array.isArray(field_set[field].options) || field_set[field].options.length == 0) {
                field_set[field].value = ''
                continue
            }

            field_set[field].value = field_set[field].options[0].hasOwnProperty('value') ? field_set[field].options[0].value : ''
        }

        // checkbox
        if (['checkbox', 'toggle'].includes(field_set[field].type)) {
            field_set[field].value = false
        }
    }

    return
}

/**
 * get a fieldset payload
 */
export const getPayload = (fieldset, as_array = false, allow_null = false) => {

    let payload = allow_null ? null
        : as_array ? []
        : {}

    for(let field in fieldset) {
        // ignore inactive fieldss
        if (!fieldset[field].active) continue;
        let value = fieldset[field].transformer ? fieldset[field].transformer(fieldset[field].value) : fieldset[field].value
        if (payload === null) payload = as_array ? [] : {}
        as_array ? payload.push({key: field, value: value}) : payload[field] = value
    }

    return payload;
}

/**
 * take an error response and apply it to the form
 */
export const applyErrors = (fieldset = null, error_data = null) => {
    if (!fieldset || !error_data) return

    for (let error_field in error_data) {
        if (fieldset.hasOwnProperty(error_field)) {
            setFieldInvalid(fieldset[error_field], error_data[error_field] || 'This field is invalid')
            removePristine(fieldset[error_field])
        }
    }
}


/**
 * outputs an absolute api url based on the currently configured environment host
 *
 * @param {str} route the path to append to the end of the host url. Can be prefixed with '/' or not.
 */
export const getEndpoint = ( route, params = [] ) => {

    let param_string = '',
        has_params = false

    params.forEach(param => {
        if (param.value !== '' && param.value !== null) {
            param_string += (!has_params ? '?' : '&') + param.key + '=' + param.value
            has_params = true
        }
    })

    // return all the parts assembled and url encoded
    return encodeURI( import.meta.env.VITE_API_HOST + 'api' + ( route[0] == '/' ? '' : '/' ) +  route + param_string )

}

/**
 * convert punds to pence
 */
export const poundsToPence = pounds => {
    return parseFloat(pounds) * 100
}

/**
 * make sure a url begins with protocol
 */
export const applyProtocolToUrl = url => {
    return url.includes('://') ? url
        : 'https://'+url
}

/**
 * convert pence to pounds
 */
export const penceToPounds = pence => {
    if (!pence) return 0
    let pounds = (pence / 100).toFixed(2)
    return pounds.replace('.00', '')
}

/**
 * convert pence to a monetised pound string
 * @param {int} pence value that will be divided by 100
 * @param {str} prefix default £
 * @param {str} suffix default null
 */
export const monetise = (pence, prefix = '£', suffix = null) => {
    if (pence == '0') return '£0'
    if (!pence) return ''
    let pounds = (pence / 100).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2})
    let output = `${typeof prefix === 'string' ? prefix : ''}${pounds}${typeof suffix === 'string' ? suffix : ''}`
    output = output.replace('.00', '')
    return output
}

/**
 * convert an integer to number format string
 */
export const numberFormat = int => {
    return int.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

// This function is used to detect the actual image type, 
export const getMimeType = (file, fallback = null) => {
	const byteArray = (new Uint8Array(file)).subarray(0, 4)
    let header = ''
    for (let i = 0; i < byteArray.length; i++) {
       header += byteArray[i].toString(16)
    }
	switch (header) {
        case "89504e47":
            return "image/png"
        case "47494638":
            return "image/gif"
        case "ffd8ffe0":
        case "ffd8ffe1":
        case "ffd8ffe2":
        case "ffd8ffe3":
        case "ffd8ffe8":
            return "image/jpeg"
        default:
            return fallback
    }
}

export const getFilename = file => {
    if (!file) return null
    return file.name ? file.name.replace(/\.[^/.]+$/, "")
        : null
}

export const getExtension = file => {
    if (!file) return null
    const file_name = file.name
    const final_period = file_name.lastIndexOf('.')
    return file_name.substring(final_period + 1)
}

export const getSessionIdentifier = () => {
    return sessionStorage.getItem('@AUC_SES_ID') || null
}

export const generateSessionIdentifier = () => {
    let id = ''
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890'
    for (var i = 10; i > 0; --i) id += chars[Math.floor(Math.random() * chars.length)];
    return id;
}

export const setSessionIdentifier = () => {
    const sid = generateSessionIdentifier()
    sessionStorage.setItem('@AUC_SES_ID', sid)
    return sid
}

/**
 * we're getting device info for logging analytic info about the session purely for info purposes. NOT for adjusting the UI.
 * Use feature detection NOT device info for customising 
 */
export const sessionInformation = async () => {
    const UA = navigator.userAgent

    if (!UA || typeof UA != 'string') return

    let session_info = {}

    const sid = getSessionIdentifier()
    session_info.session_id = sid || setSessionIdentifier()

    const platform = navigator?.userAgentData?.platform || navigator.platform
    const mac_platforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K', 'macOS']
    const windows_platforms = ['Win32', 'Win64', 'Windows', 'WinCE']
    const ios_platforms = ['iPhone', 'iPad', 'iPod']

    if (mac_platforms.indexOf(platform) !== -1) {
        session_info.os = 'Mac OS';
    } else if (windows_platforms.indexOf(platform) !== -1) {
        session_info.os = 'Windows';
    } else if (ios_platforms.indexOf(platform) !== -1) {
        session_info.os = 'iOS';
    } else if (/Android/.test(UA) || /Android/.test(platform)) {
        session_info.os = 'Android';
    } else if (/Linux/.test(platform)) {
        session_info.os = 'Linux';
    }

    if (UA.includes('Firefox/')) {
        session_info.browser = 'Firefox'
    } else if (UA.includes('Edg/')) {
        session_info.browser = 'Edge'
    } else if (UA.includes('Chrome/')) {
        session_info.browser = 'Chrome'
    } else if (UA.includes('Safari/')) {
        session_info.browser = 'Safari'
    }

    session_info.device_type = UA.includes('Mobi') ? 'Mobile' : 'Desktop'

    session_info.viewport_width = window.screen.availWidth
    session_info.viewport_height = window.screen.availHeight
    session_info.viewport = `${window.screen.availWidth}/${window.screen.availHeight}`

    return session_info
}

export const tailwind_colors = {
    slate: {
        50: "#f8fafc",
        100: "#f1f5f9",
        200: "#e2e8f0",
        300: "#cbd5e1",
        400: "#94a3b8",
        500: "#64748b",
        600: "#475569",
        700: "#334155",
        800: "#1e293b",
        900: "#0f172a"
    },
    gray: {
        50: "#f9fafb",
        100: "#f3f4f6",
        200: "#e5e7eb",
        300: "#d1d5db",
        400: "#9ca3af",
        500: "#6b7280",
        600: "#4b5563",
        700: "#374151",
        800: "#1f2937",
        900: "#111827"
    },
    zinc: {
        50: "#fafafa",
        100: "#f4f4f5",
        200: "#e4e4e7",
        300: "#d4d4d8",
        400: "#a1a1aa",
        500: "#71717a",
        600: "#52525b",
        700: "#3f3f46",
        800: "#27272a",
        900: "#18181b"
    },
    neutral: {
        50: "#fafafa",
        100: "#f5f5f5",
        200: "#e5e5e5",
        300: "#d4d4d4",
        400: "#a3a3a3",
        500: "#737373",
        600: "#525252",
        700: "#404040",
        800: "#262626",
        900: "#171717"
    },
    stone: {
        50: "#fafaf9",
        100: "#f5f5f4",
        200: "#e7e5e4",
        300: "#d6d3d1",
        400: "#a8a29e",
        500: "#78716c",
        600: "#57534e",
        700: "#44403c",
        800: "#292524",
        900: "#1c1917"
    },
    red: {
        50: "#fef2f2",
        100: "#fee2e2",
        200: "#fecaca",
        300: "#fca5a5",
        400: "#f87171",
        500: "#ef4444",
        600: "#dc2626",
        700: "#b91c1c",
        800: "#991b1b",
        900: "#7f1d1d"
    },
    orange: {
        50: "#fff7ed",
        100: "#ffedd5",
        200: "#fed7aa",
        300: "#fdba74",
        400: "#fb923c",
        500: "#f97316",
        600: "#ea580c",
        700: "#c2410c",
        800: "#9a3412",
        900: "#7c2d12"
    },
    amber: {
        50: "#fffbeb",
        100: "#fef3c7",
        200: "#fde68a",
        300: "#fcd34d",
        400: "#fbbf24",
        500: "#f59e0b",
        600: "#d97706",
        700: "#b45309",
        800: "#92400e",
        900: "#78350f"
    },
    yellow: {
        50: "#fefce8",
        100: "#fef9c3",
        200: "#fef08a",
        300: "#fde047",
        400: "#facc15",
        500: "#eab308",
        600: "#ca8a04",
        700: "#a16207",
        800: "#854d0e",
        900: "#713f12"
    },
    lime: {
        50: "#f7fee7",
        100: "#ecfccb",
        200: "#d9f99d",
        300: "#bef264",
        400: "#a3e635",
        500: "#84cc16",
        600: "#65a30d",
        700: "#4d7c0f",
        800: "#3f6212",
        900: "#365314"
    },
    green: {
        50: "#f0fdf4",
        100: "#dcfce7",
        200: "#bbf7d0",
        300: "#86efac",
        400: "#4ade80",
        500: "#22c55e",
        600: "#16a34a",
        700: "#15803d",
        800: "#166534",
        900: "#14532d"
    },
    emerald: {
        50: "#ecfdf5",
        100: "#d1fae5",
        200: "#a7f3d0",
        300: "#6ee7b7",
        400: "#34d399",
        500: "#10b981",
        600: "#059669",
        700: "#047857",
        800: "#065f46",
        900: "#064e3b"
    },
    teal: {
        50: "#f0fdfa",
        100: "#ccfbf1",
        200: "#99f6e4",
        300: "#5eead4",
        400: "#2dd4bf",
        500: "#14b8a6",
        600: "#0d9488",
        700: "#0f766e",
        800: "#115e59",
        900: "#134e4a"
    },
    cyan: {
        50: "#ecfeff",
        100: "#cffafe",
        200: "#a5f3fc",
        300: "#67e8f9",
        400: "#22d3ee",
        500: "#06b6d4",
        600: "#0891b2",
        700: "#0e7490",
        800: "#155e75",
        900: "#164e63"
    },
    sky: {
        50: "#f0f9ff",
        100: "#e0f2fe",
        200: "#bae6fd",
        300: "#7dd3fc",
        400: "#38bdf8",
        500: "#0ea5e9",
        600: "#0284c7",
        700: "#0369a1",
        800: "#075985",
        900: "#0c4a6e"
    },
    blue: {
        50: "#eff6ff",
        100: "#dbeafe",
        200: "#bfdbfe",
        300: "#93c5fd",
        400: "#60a5fa",
        500: "#3b82f6",
        600: "#2563eb",
        700: "#1d4ed8",
        800: "#1e40af",
        900: "#1e3a8a"
    },
    indigo: {
        50: "#eef2ff",
        100: "#e0e7ff",
        200: "#c7d2fe",
        300: "#a5b4fc",
        400: "#818cf8",
        500: "#6366f1",
        600: "#4f46e5",
        700: "#4338ca",
        800: "#3730a3",
        900: "#312e81"
    },
    violet: {
        50: "#f5f3ff",
        100: "#ede9fe",
        200: "#ddd6fe",
        300: "#c4b5fd",
        400: "#a78bfa",
        500: "#8b5cf6",
        600: "#7c3aed",
        700: "#6d28d9",
        800: "#5b21b6",
        900: "#4c1d95"
    },
    purple: {
        50: "#faf5ff",
        100: "#f3e8ff",
        200: "#e9d5ff",
        300: "#d8b4fe",
        400: "#c084fc",
        500: "#a855f7",
        600: "#9333ea",
        700: "#7e22ce",
        800: "#6b21a8",
        900: "#581c87"
    },
    fuchsia: {
        50: "#fdf4ff",
        100: "#fae8ff",
        200: "#f5d0fe",
        300: "#f0abfc",
        400: "#e879f9",
        500: "#d946ef",
        600: "#c026d3",
        700: "#a21caf",
        800: "#86198f",
        900: "#701a75"
    },
    pink: {
        50: "#fdf2f8",
        100: "#fce7f3",
        200: "#fbcfe8",
        300: "#f9a8d4",
        400: "#f472b6",
        500: "#ec4899",
        600: "#db2777",
        700: "#be185d",
        800: "#9d174d",
        900: "#831843"
    },
    rose: {
        50: "#fff1f2",
        100: "#ffe4e6",
        200: "#fecdd3",
        300: "#fda4af",
        400: "#fb7185",
        500: "#f43f5e",
        600: "#e11d48",
        700: "#be123c",
        800: "#9f1239",
        900: "#881337"
    },
}

export const tailwindHex = (color, hue) => {

    if ((!color || typeof color !== 'string') || (!hue || isNaN(hue))) {
        console.warn('Helper.tailwindHex warning: invalid hue/color supplied', color, hue)
        return
    }

    if (tailwind_colors.hasOwnProperty(color) || !tailwind_colors[color][hue]) {
        console.warn('Helper.tailwindHex warning: colour not found', color, hue)
    }

    return tailwind_colors[color][hue]
}

export const capitalise = string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * get a specific organisation from the user's organisation array
 */
export const getUserOrganisation = (user, id) => {
    if (!id || !user?.organisations || !Array.isArray(user?.organisations)) return null

    return user.organisations.find(a => a.id == id) || null
}

/**
 *  get a specific organisation type from the user's organisation array
 */
export const getUserOrganisationType = (user, id) => {
    if (!id || !user?.organisations || !Array.isArray(user?.organisations)) return null

    const organisation = getUserOrganisation(user, id)

    return organisation?.type || null
}

export const getAssetStatusBadgeObject = status => {
    let badge = { color: 'gray', text: status }
    switch(status) {
        case 'ready':
            badge.color = 'orange'
            badge.text = 'pending listing'
            break
        case 'provisional_pending':
            badge.color = 'yellow'
            badge.text = 'provisional bid'
            break
        case 'sold':
            badge.color = 'green'
            break
        case 'hold':
            badge.color = 'red'
            badge.text = 'On hold'
            break
        case 'disabled':
            badge.color = 'red'
            break
    }
    return badge
}

export const getAssetStatusBadgeColor = status => {
    const badge = getAssetStatusBadgeObject(status)
    return badge.color
}

export const getAssetStatusBadgeText = (status, pending_transfer_status = null) => {
    const badge = getAssetStatusBadgeObject(status)
    return pending_transfer_status || badge.text
}

/**
 * returns the first found organisation id that belongs to a specified user and specfied asseet
 */
export const getAssetUserOrganisationId = (asset, user) => {

    const shared_organisation_ids = getAssetUserOrganisationIds(asset, user)

    if (!Array.isArray(shared_organisation_ids) || !shared_organisation_ids.length) return null

    return shared_organisation_ids[0]

}

/**
 * return all organisation ids that an asset and a user share
 */
export const getAssetUserOrganisationIds = (asset, user) => {
    
    if (!asset || !user) return null

    const asset_organisations = asset.hasOwnProperty('linked_organisations') ? asset.linked_organisations
        : []
    
    if (asset_organisations.length == 0) return []

    const user_organisations = user.hasOwnProperty('organisations') ? user.organisations
        : []

    const user_organisation_ids = user_organisations.map(o => o.id)

    const shared_organisations = asset_organisations.filter(o => user_organisation_ids.includes(o.hasOwnProperty('organisation_id') ? o.organisation_id : o.id))

    let shared_organisation_ids = []

    shared_organisations.forEach(o => {
        shared_organisation_ids.push(o.hasOwnProperty('organisation_id') ? o.organisation_id : o.id)
    })

    return shared_organisation_ids

}

/**
 * simple delay function because ye-har
 */
export const delay = async (milliseconds = 500) => {
    if ((!milliseconds && milliseconds != 0) || isNaN(milliseconds)) {
        console.warning(milliseconds)
        throw 'milliseconds is not a number'
    }
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve();
        }, milliseconds)
    })
}

/**
 * determine if a color is generally light or dark
 * @param {string} color "#FF00CC"
 * @returns "light" || "dark"
 */
export const lightOrDark = color => {

    let r,g,b

    // Check the format of the color, HEX or RGB?
    if (color.match(/^rgb/)) {
  
        // If HEX --> store the red, green, blue values in separate variables
        color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/)
  
        r = color[1]
        g = color[2]
        b = color[3]
    
    } else {
  
        // If RGB --> Convert it to HEX: http://gist.github.com/983661
        color = +("0x" + color.slice(1).replace( color.length < 5 && /./g, '$&$&'))
  
        r = color >> 16
        g = color >> 8 & 255
        b = color & 255
    }
  
    // HSP equation from http://alienryderflex.com/hsp.html
    let hsp = Math.sqrt(
        0.299 * (r * r) +
        0.587 * (g * g) +
        0.114 * (b * b)
    )
            // Using the HSP value, determine whether the color is light or dark
    //return hsp > 127.5 ? 'light' : 'dark'
    return hsp > 150 ? 'light' : 'dark'

}

/**
 * adjust color
 * https://github.com/PimpTrizkit/PJs/wiki/12.-Shade,-Blend-and-Convert-a-Web-Color-(pSBC.js)
 * @param {*} p 
 * @param {*} c0 
 * @param {*} c1 
 * @param {*} l 
 * @returns 
 */
export const pSBC=(p,c0,c1,l)=>{
	let r,g,b,P,f,t,h,m=Math.round,a=typeof(c1)=="string";
	if(typeof(p)!="number"||p<-1||p>1||typeof(c0)!="string"||(c0[0]!='r'&&c0[0]!='#')||(c1&&!a))return null;
	h=c0.length>9,h=a?c1.length>9?true:c1=="c"?!h:false:h,f=pSBC.pSBCr(c0),P=p<0,t=c1&&c1!="c"?pSBC.pSBCr(c1):P?{r:0,g:0,b:0,a:-1}:{r:255,g:255,b:255,a:-1},p=P?p*-1:p,P=1-p;
	if(!f||!t)return null;
	if(l)r=m(P*f.r+p*t.r),g=m(P*f.g+p*t.g),b=m(P*f.b+p*t.b);
	else r=m((P*f.r**2+p*t.r**2)**0.5),g=m((P*f.g**2+p*t.g**2)**0.5),b=m((P*f.b**2+p*t.b**2)**0.5);
	a=f.a,t=t.a,f=a>=0||t>=0,a=f?a<0?t:t<0?a:a*P+t*p:0;
	if(h)return"rgb"+(f?"a(":"(")+r+","+g+","+b+(f?","+m(a*1000)/1000:"")+")";
	else return"#"+(4294967296+r*16777216+g*65536+b*256+(f?m(a*255):0)).toString(16).slice(1,f?undefined:-2)
}

pSBC.pSBCr=(d)=>{
	const i=parseInt;
	let n=d.length,x={};
	if(n>9){
		const [r, g, b, a] = (d = d.split(','));
	        n = d.length;
		if(n<3||n>4)return null;
		x.r=i(r[3]=="a"?r.slice(5):r.slice(4)),x.g=i(g),x.b=i(b),x.a=a?parseFloat(a):-1
	}else{
		if(n==8||n==6||n<4)return null;
		if(n<6)d="#"+d[1]+d[1]+d[2]+d[2]+d[3]+d[3]+(n>4?d[4]+d[4]:"");
		d=i(d.slice(1),16);
		if(n==9||n==5)x.r=d>>24&255,x.g=d>>16&255,x.b=d>>8&255,x.a=Math.round((d&255)/0.255)/1000;
		else x.r=d>>16,x.g=d>>8&255,x.b=d&255,x.a=-1
	}return x
};

/**
 * turns feature field data into usable form state
 */
export const buildFeatureFields = (feature_fields = [], feature_values = [], form_name = 'feature_field') => {

    const fieldset = {}

    feature_fields.forEach(feature_field => {

        const saved_value = feature_values.find(v => v.feature_field_id == feature_field.id)

        fieldset[form_name+'_'+feature_field.id] = {
            feature_field_id: feature_field.id,
            feature_value_id: saved_value?.id || null,
            field_key: form_name+'_'+feature_field.id,
            active: true,
            label: feature_field.label + (feature_field.suffix ? ' ('+feature_field.suffix+')' : (feature_field.prefix ? ' ('+feature_field.prefix+')' : '')),
            type: 'text',
            value: saved_value?.value || '',
            stored_value: saved_value?.value || '',
            required: feature_field.meta?.is_required === true,
            pristine: true,
            valid: false,
            message: '',
            validation: [],
            notes: '',
            editable: false,
            disabled: false
        }

        // cycle through the validation requirements
        if (feature_field.meta) {
            //
            for (let key in feature_field.meta) {
                switch (key) {
                    case 'min_chars':
                        fieldset[form_name+'_'+feature_field.id].validation.push({ type: 'min-char', value: feature_field.meta[key]})
                        break
                    case 'max_chars':
                        fieldset[form_name+'_'+feature_field.id].validation.push({ type: 'max-char', value: feature_field.meta[key]})
                        break
                    case 'min_num':
                        fieldset[form_name+'_'+feature_field.id].validation.push({ type: 'min', value: feature_field.meta[key]})
                        break
                    case 'max_num':
                        fieldset[form_name+'_'+feature_field.id].validation.push({ type: 'max', value: feature_field.meta[key]})
                        break
                    case 'chars':
                        fieldset[form_name+'_'+feature_field.id].validation.push({ type: 'characters', value: feature_field.meta[key]})
                        break
                }
            }
        }

        switch (feature_field.type) {
            case 'string':
                fieldset[form_name+'_'+feature_field.id].type = 'text'
                break
            case 'number':
                fieldset[form_name+'_'+feature_field.id].type = 'number'
                break
            default:
                fieldset[form_name+'_'+feature_field.id].active = false
        }
    });

    return fieldset
}

export const scrollTop = () => {
    window.scrollTo({top: 0, behavior: 'smooth'})
}

export const copyToClipboard = async value => {

    // Navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
        await navigator.clipboard.writeText(value);
        
    } else {
        // Use the 'out of viewport hidden text area' trick
        const textArea = document.createElement("textarea");
        textArea.value = value;
            
        // Move textarea out of the viewport so it's not visible
        textArea.style.position = "absolute";
        textArea.style.left = "-999999px";
            
        document.body.prepend(textArea);
        textArea.select();

        try {
            document.execCommand('copy');
        } catch (error) {
            console.error(error);
        } finally {
            textArea.remove();
        }
    }
}
