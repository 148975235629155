<template>
    <div class="text-center" :class="class">
        <FolderStrokeIcon class="h-12 w-12 mx-auto text-gray-400" :stroke_width="1.2" />
        <h3 class="mt-2 font-medium text-gray-900" v-html="heading || 'Empty'"></h3>
        <p class="mt-1 text-sm text-gray-500" v-html="message || 'There are no items to show'"></p>
    </div>
</template>

<script>
import FolderStrokeIcon from '@ico/FolderStrokeIcon.vue'
export default {
    props: ['heading', 'class', 'message'],
    components: {
        FolderStrokeIcon
    },
    setup() {
        
    },
}
</script>