/*******************************************************************************
 * Dependency Imports
 ******************************************************************************/

// Helpers
import { getEndpoint } from '@lib/helpers'
import axios from 'axios'

/*******************************************************************************
 * Private methods
 ******************************************************************************/

/*******************************************************************************
 * Public methods
 ******************************************************************************/

/**
 * get a list of a user's associated vehicle assets
 * @returns 
 */
export const userVehicles = async (page = 1, per_page = 30) => {

    return new Promise(( resolve, reject ) => {

        axios({
            method: 'get',
            url: getEndpoint('/asset/user-vehicles?page=' + page + '&per_page=' + per_page),
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * get user vehicle inventory
 * @returns 
 */
 export const dealerVehicleInventory = async () => {

    return new Promise(( resolve, reject ) => {

        axios({
            method: 'get',
            url: getEndpoint('/asset/dealer-vehicle-inventory'),
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * get an asset by id
 * @returns 
 */
export const show = async asset_id => {

    return new Promise(( resolve, reject ) => {

        axios({
            method: 'get',
            url: getEndpoint('/asset/' + asset_id),
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * update an asset by id
 * @returns 
 */
 export const update = async (asset_id, asset_data) => {

    return new Promise(( resolve, reject ) => {

        axios({
            method: 'patch',
            url: getEndpoint('/asset/' + asset_id),
            data: asset_data,
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * update an asset's status by id
 * - a temporary endpoint that allows manuall handling of asset status and also triggers events where appropriate.
 * @returns 
 */
export const updateStatus = async (asset_id, status_data) => {

    return new Promise(( resolve, reject ) => {

        axios({
            method: 'patch',
            url: getEndpoint('/asset/' + asset_id + '/status'),
            data: status_data,
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * get an asset's transfer orders
 * @returns 
 */
export const transferOrders = async (organisation_id, asset_id) => {

    return new Promise(( resolve, reject ) => {

        axios({
            method: 'get',
            url: getEndpoint('/organisation/' + organisation_id + '/asset/' + asset_id + '/transfer-order'),
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * get an asset's transfer requests
 * @returns 
 */
export const transferRequests = async (organisation_id, asset_id, params = []) => {

    return new Promise(( resolve, reject ) => {

        axios({
            method: 'get',
            url: getEndpoint('/organisation/' + organisation_id + '/asset/' + asset_id + '/transfer-request', params),
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * get an asset's lots
 * @returns 
 */
export const lots = async asset_id => {

    return new Promise(( resolve, reject ) => {

        axios({
            method: 'get',
            url: getEndpoint('/asset/' + asset_id + '/lots'),
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * get organisations that have custody over this users assets
 * @param {int} user_id
 * @param {obj} params
 * @returns 
 */
export const userAssetCustody = async (user_id = null) => {

    return new Promise(( resolve, reject ) => {

        if (!user_id) {
            reject({message: 'user_id missing'})
            return
        }

        axios({
            method: 'get',
            url: getEndpoint('/user/' + user_id + '/asset-custody'),
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * create a vehicle
 * @param {int} organisation_id
 * @param {obj} payload of startring data
 * @returns 
 */
export const createVehicle = async (organisation_id, payload = null) => {

    return new Promise(( resolve, reject ) => {

        if (!organisation_id) {
            reject({message: 'Organisation ID missing'})
            return
        }

        axios({
            method: 'post',
            url: getEndpoint('/organisation/' + organisation_id + '/vehicle'),
            data: payload,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * update an asset's location
 * @returns 
 */
export const location = async (asset_id, location_id) => {

    return new Promise(( resolve, reject ) => {

        axios({
            method: 'patch',
            url: getEndpoint('/asset/' + asset_id + '/location'),
            data: {
                'location_id': location_id,
            },
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

export const addMedia = async (asset_id, payload) => {

    let formData = new FormData()
    for (let key in payload) {
        formData.append(key, payload[key])
    }
    
    return new Promise(( resolve, reject ) => {

        axios({
            method: 'post',
            url: getEndpoint('/asset/' + asset_id + '/media'),
            data: formData,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data; charset=utf-8; boundary=' + Math.random().toString().substr(2)
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

export const getMedia = async (asset_id, params = []) => {
    
    return new Promise(( resolve, reject ) => {

        axios({
            method: 'get',
            url: getEndpoint('/asset/' + asset_id + '/media', params),
            headers: {
                'Accept': 'application/json',
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

export const updateMedia = async (media_id, asset_id, data) => {
    
    return new Promise(( resolve, reject ) => {

        axios({
            method: 'patch',
            url: getEndpoint('/asset/' + asset_id + '/media/' + media_id),
            data,
            headers: {
                'Accept': 'application/json',
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

export const deleteMedia = async (media_id, asset_id) => {
    
    return new Promise(( resolve, reject ) => {

        axios({
            method: 'delete',
            url: getEndpoint('/asset/' + asset_id + '/media/' + media_id),
            headers: {
                'Accept': 'application/json',
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

export const createTransferOrder = async (payload) => {
    
    return new Promise(( resolve, reject ) => {

        axios({
            method: 'post',
            url: getEndpoint('/transfer-order'),
            data: payload,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

export const createTransferOrderForDealerToAuctionDelivery = async (payload) => {
    
    return new Promise(( resolve, reject ) => {

        axios({
            method: 'post',
            url: getEndpoint('/transfer-order/dealer-to-auction-delivery'),
            data: payload,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

export const updateTransferOrder = async (transfer_order_id, payload) => {
    
    return new Promise(( resolve, reject ) => {

        axios({
            method: 'patch',
            url: getEndpoint('/transfer-order/' + transfer_order_id),
            data: payload,
            headers: {
                'Accept': 'application/json',
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

// /{organisation}/asset/{asset}/transfer-order/{transfer_order}

export const getTransferOrderByOrganisation = async (organisation_id, asset_id, transfer_order_id) => {
    
    return new Promise(( resolve, reject ) => {

        axios({
            method: 'get',
            url: getEndpoint('/organisation/' + organisation_id + '/asset/' + asset_id + '/transfer-order/' + transfer_order_id),
            headers: {
                'Accept': 'application/json',
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * get the transfer requests for the current user
 * @param {*} params 
 * @returns 
 */
export const getTransferRequests = async ( params = []) => {
    return new Promise(( resolve, reject ) => {

        axios({
            method: 'get',
            url: getEndpoint('/transfer-request', params),
            headers: {
                'Accept': 'application/json',
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * create a transfer request
 * @param {*} params 
 * @returns 
 */
export const createTransferRequest = async payload => {
    return new Promise(( resolve, reject ) => {

        axios({
            method: 'post',
            url: getEndpoint('/transfer-request'),
            data: payload,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * update a transfer request
 * @param {*} params 
 * @returns 
 */
export const updateTransferRequest = async (transfer_request_id, payload) => {
    return new Promise(( resolve, reject ) => {

        axios({
            method: 'patch',
            url: getEndpoint('/transfer-request/' + transfer_request_id),
            data: payload,
            headers: {
                'Accept': 'application/json',
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * get a transfer request
 * @param {*} params 
 * @returns 
 */
export const getTransferRequest = async transfer_request_id => {
    return new Promise(( resolve, reject ) => {

        axios({
            method: 'get',
            url: getEndpoint('/transfer-request/' + transfer_request_id),
            headers: {
                'Accept': 'application/json',
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * get an organisation's transferable assets
 */
export const transferableAssets = async organisation_id => {
    return new Promise(( resolve, reject ) => {

        axios({
            method: 'get',
            url: getEndpoint('/organisation/' + organisation_id + '/transferable-assets'),
            headers: {
                'Accept': 'application/json',
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * get this user's transferable assets
 */
export const userTransferableAssets = async () => {
    return new Promise(( resolve, reject ) => {

        axios({
            method: 'get',
            url: getEndpoint('/asset/user-transferable'),
            headers: {
                'Accept': 'application/json',
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * associate assets to a transfer request
 * @param {*} params 
 * @returns 
 */
export const transferRequestAssets = async (transfer_request_id, payload) => {
    return new Promise(( resolve, reject ) => {

        axios({
            method: 'post',
            url: getEndpoint('/transfer-request/' + transfer_request_id + '/assets'),
            data: payload,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * sends a transfer request
 * @param {int} transfer_request_id 
 * @returns 
 */
export const sendTransferRequest = async transfer_request_id => {
    return new Promise(( resolve, reject ) => {

        axios({
            method: 'post',
            url: getEndpoint('/transfer-request/' + transfer_request_id + '/send'),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * create a transfer order in response to a transfer request
 * @param {int} transfer_request_id
 * @param {obj} payload
 * @returns 
 */
export const createTransferOrderForRequest = async (transfer_request_id, payload) => {
    return new Promise(( resolve, reject ) => {

        axios({
            method: 'post',
            url: getEndpoint('/transfer-request/' + transfer_request_id + '/transfer-order'),
            data: payload,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * get a transfer orders for this user
 * @returns 
 */
export const getTransferOrders = async (params = []) => {
    
    return new Promise(( resolve, reject ) => {

        axios({
            method: 'get',
            url: getEndpoint('/transfer-order', params),
            headers: {
                'Accept': 'application/json',
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * get a transfer order by id
 * @param {*} transfer_order_id 
 * @returns 
 */
export const getTransferOrder = async transfer_order_id => {
    
    return new Promise(( resolve, reject ) => {

        axios({
            method: 'get',
            url: getEndpoint('/transfer-order/' + transfer_order_id),
            headers: {
                'Accept': 'application/json',
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * remove an asset from a transfer reqyest
 * @param {*} transfer_order_id 
 * @param {*} asset_id 
 * @returns 
 */
export const removeTransferRequestAsset = async (transfer_request_id, asset_id) => {
    
    return new Promise(( resolve, reject ) => {

        axios({
            method: 'delete',
            url: getEndpoint('/transfer-request/' + transfer_request_id + '/asset/' + asset_id),
            headers: {
                'Accept': 'application/json',
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * compllete a transfer order by id
 * @param {*} transfer_order_id 
 * @returns 
 */
export const transferReceived = async transfer_order_id => {
    
    return new Promise(( resolve, reject ) => {

        axios({
            method: 'post',
            url: getEndpoint('/transfer-order/' + transfer_order_id + '/received'),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * cancel an asset transfer request
 * @param {int} organisation_id
 * @param {int} transfer_request_id 
 * @returns 
 */
export const cancelTransferRequest = async (organisation_id, transfer_request_id) => {
    
    return new Promise(( resolve, reject ) => {

        axios({
            method: 'patch',
            url: getEndpoint('/organisation/' + organisation_id + '/transfer-request/' + transfer_request_id + '/cancel'),
            headers: {
                'Accept': 'application/json',
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * get user vehicle inventory
 * @returns 
 */
export const getComments = async asset_id => {

    return new Promise(( resolve, reject ) => {

        axios({
            method: 'get',
            url: getEndpoint('/asset/' + asset_id + '/comments'),
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * create a comment ono this asset
 * @returns 
 */
export const createComment = async (asset_id, comment_data) => {

    return new Promise(( resolve, reject ) => {

        axios({
            method: 'post',
            url: getEndpoint('/asset/' + asset_id + '/comment'),
            data: comment_data,
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * get the provisional offers for this asseet
 * @returns 
 */
export const getProvisionalOffers = async asset_id => {

    return new Promise(( resolve, reject ) => {

        axios({
            method: 'get',
            url: getEndpoint('/asset/' + asset_id + '/provisional-offers'),
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * create a provisional offer for this asset
 * @returns 
 */
export const createProvisionalOffer = async (asset_id, data) => {

    return new Promise(( resolve, reject ) => {

        if (!asset_id) {
            reject({message: 'asset_id missing'})
            return
        }

        if (!data?.offer_value_net) {
            reject({message: 'You must provide an offer value'})
            return
        }

        axios({
            method: 'post',
            data,
            url: getEndpoint('/asset/' + asset_id + '/provisional-offer'),
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * accept offfer
 * @returns 
 */
export const acceptProvisonalOffer = async (asset_id, offer_id) => {

    return new Promise(( resolve, reject ) => {

        if (!asset_id) {
            reject({message: 'asset_id missing'})
            return
        }

        if (!offer_id) {
            reject({message: 'offer_id is missing'})
            return
        }

        axios({
            method: 'patch',
            url: getEndpoint('/asset/' + asset_id + '/provisional-offer/' + offer_id + '/accept'),
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * reject offfer
 * @returns 
 */
export const rejectProvisonalOffer = async (asset_id, offer_id) => {

    return new Promise(( resolve, reject ) => {

        if (!asset_id) {
            reject({message: 'asset_id missing'})
            return
        }

        if (!offer_id) {
            reject({message: 'offer_id is missing'})
            return
        }

        axios({
            method: 'patch',
            url: getEndpoint('/asset/' + asset_id + '/provisional-offer/' + offer_id + '/reject'),
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * reject offfer
 * @returns 
 */
export const rejectProvisonalOfferWithCounter = async (asset_id, offer_id, data) => {

    return new Promise(( resolve, reject ) => {

        if (!asset_id) {
            reject({message: 'asset_id missing'})
            return
        }

        if (!offer_id) {
            reject({message: 'offer_id is missing'})
            return
        }

        axios({
            method: 'post',
            data,
            url: getEndpoint('/asset/' + asset_id + '/provisional-offer/' + offer_id + '/counter'),
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * withdraw offfer
 * @returns 
 */
export const withdrawProvisonalOffer = async (asset_id, offer_id) => {

    return new Promise(( resolve, reject ) => {

        if (!asset_id) {
            reject({message: 'asset_id missing'})
            return
        }

        if (!offer_id) {
            reject({message: 'offer_id is missing'})
            return
        }

        axios({
            method: 'patch',
            url: getEndpoint('/asset/' + asset_id + '/provisional-offer/' + offer_id + '/withdraw'),
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}


/**
 * add a new feature value
 * @returns 
 */
export const storeFeatureValue = async (asset_id, data) => {
    return new Promise(( resolve, reject ) => {

        if (!asset_id) {
            reject({message: 'asset_id missing'})
            return
        }

        axios({
            method: 'post',
            url: getEndpoint('/asset/' + asset_id + '/feature-value'),
            data,
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * archive an organisation's asset
 * @returns 
 */
export const archiveOrganisationAsset = async (organisation_id, asset_id) => {

    return new Promise(( resolve, reject ) => {

        if (!asset_id) {
            reject({message: 'asset_id missing'})
            return
        }
        
        if (!organisation_id) {
            reject({message: 'organisation_id is missing'})
            return
        }
        
        axios({
            method: 'patch',
            url: getEndpoint('/organisation/' + organisation_id + '/asset/' + asset_id + '/archive'),
            headers: {
                'Accept': 'application/json'
            }
            })
            .then(( response ) => {
                resolve( response )
            })
            .catch(( error ) => {
                reject( error )
            })
        })
}
/**
 * archive an asset for the specified user
 */
export const archiveUserAsset = async (user_id, asset_id) => {

    return new Promise(( resolve, reject ) => {

        if (!user_id) {
            reject({message: 'user_id missing'})
            return
        }
        
        if (!asset_id) {
            reject({message: 'asset_id is missing'})
            return
        }
        
        axios({
            method: 'patch',
            url: getEndpoint('/user/' + user_id + '/asset/' + asset_id + '/archive'),
            headers: {
                'Accept': 'application/json'
            }
            })
            .then(( response ) => {
                resolve( response )
            })
            .catch(( error ) => {
                reject( error )
            })
        })
}

/**
 * update an existing feature value
 * @returns 
 */
export const updateFeatureValue = async (asset_id, feature_value_id, data) => {

    return new Promise(( resolve, reject ) => {

        if (!asset_id) {
            reject({message: 'asset_id missing'})
            return
        }

        if (!feature_value_id) {
            reject({message: 'feature_value_id is missing'})
            return
        }

        axios({
            method: 'patch',
            url: getEndpoint('/asset/' + asset_id + '/feature-value/'+feature_value_id),
            data,
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * update an existing feature value
 * @returns 
 */
export const getFeatureFields = async asset_id => {

    return new Promise(( resolve, reject ) => {
        if (!asset_id) {
            reject({message: 'asset_id missing'})
            return
        }

        axios({
            method: 'get',
            url: getEndpoint('/asset/' + asset_id + '/feature-fields'),
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })

    })
}

/**
 * delete an organisation's asset
 * @returns 
 */
export const deleteOrganisationAsset = async (organisation_id, asset_id) => {

    return new Promise(( resolve, reject ) => {

        if (!asset_id) {
            reject({message: 'asset_id missing'})
            return
        }

        if (!organisation_id) {
            reject({message: 'organisation_id is missing'})
            return
        }

        axios({
            method: 'delete',
            url: getEndpoint('/organisation/' + organisation_id + '/asset/' + asset_id),
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * assign lot number to an asset
 * @returns 
 */
export const assignLotNumber = async (asset_id, data) => {

    return new Promise(( resolve, reject ) => {

        if (!asset_id) {
            reject({message: 'asset_id missing'})
            return
        }

        axios({
            method: 'post',
            url: getEndpoint('/asset/'+asset_id+'/lot-number'),
            data,
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * change an asset's lot number
 * @returns 
 */
export const changeLotNumber = async (asset_id, data) => {

    return new Promise(( resolve, reject ) => {

        if (!asset_id) {
            reject({message: 'asset_id missing'})
            return
        }

        axios({
            method: 'patch',
            url: getEndpoint('/asset/'+asset_id+'/lot-number'),
            data,
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * remove an asset's lot number
 * @returns 
 */
export const removeLotNumber = async (asset_id, data) => {

    return new Promise(( resolve, reject ) => {

        if (!asset_id) {
            reject({message: 'asset_id missing'})
            return
        }

        axios({
            method: 'delete',
            url: getEndpoint('/asset/'+asset_id+'/lot-number'),
            data,
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * create a link and attach to the asset
 * @returns 
 */
export const addNewLink = async (asset_id, data) => {

    return new Promise(( resolve, reject ) => {

        if (!asset_id) {
            reject({message: 'asset_id missing'})
            return
        }

        axios({
            method: 'post',
            url: getEndpoint('/asset/'+asset_id+'/link'),
            data,
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * get the comment participants for a given asset
 */
export const getCommentParticipants = async asset_id => {

    return new Promise(( resolve, reject ) => {

        if (!asset_id) {
            reject({message: 'asset_id missing'})
            return
        }

        axios({
            method: 'get',
            url: getEndpoint('/asset/'+asset_id+'/comment-participants'),
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * get the default bid increment for this asset
 * @returns 
 */
export const getDefaultBidIncrement = async asset_id => {

    return new Promise(( resolve, reject ) => {

        axios({
            method: 'get',
            url: getEndpoint('/asset/'+asset_id+'/default-bid-increment'),
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * mark an asset as having been received and on-site
 */
export const received = async asset_id => {
    return new Promise(( resolve, reject ) => {
        axios({
            method: 'post',
            url: getEndpoint('/asset/'+asset_id+'/received'),
            headers: {
                'Accept': 'application/json'
            }
        })
            .then(( response ) => {
                resolve( response )
            })
            .catch(( error ) => {
                reject( error )
            })
    })
}

/**
 * assign an account to an asset
 * @param asset_id
 * @param data
 * @returns {Promise<unknown>}
 */
export const assignAccount = async (asset_id, data) => {
    return new Promise(( resolve, reject ) => {
        axios({
            method: 'post',
            url: getEndpoint('/asset/'+asset_id+'/account'),
            data,
            headers: {
                'Accept': 'application/json'
            }
            })
            .then(( response ) => {
                resolve( response )
            })
            .catch(( error ) => {
                reject( error )
            })
    })
}