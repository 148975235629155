<template>
    <div v-for="alert in alerts" :key="alert.id" :class="alert.background_style + ' rounded-md p-4'">
        <div class="flex">
            <div class="flex-shrink-0">
                <component :is="alert.icon_component" :class="alert.icon_style" aria-hidden="true" />
            </div>
            <div class="ml-3">
                <p :class="alert.heading_style">{{alert.heading}}</p>
                <div v-if="Array.isArray(alert.body) && alert.body.length > 0" :class="alert.body_style + ' mt-2'">
                    <ul role="list" class="list-disc pl-5 space-y-1">
                        <li v-for="(item, index) in alert.body" :key="index">{{ item.message }}</li>
                    </ul>
                </div>
            </div>
            <div class="ml-auto pl-3">
                <div class="-mx-1.5 -my-1.5">
                    <button v-if="alert.dismissable" v-on:click="() => ALERTDEP_STORE.removeAlert(alert.id)" type="button" :class="alert.button_style + ' inline-flex rounded-md p-1.5 focus:outline-none focus:ring-2 focus:ring-offset-2'">
                        <span class="sr-only">Dismiss</span>
                        <CrossIcon class="h-5 w-5" aria-hidden="true" />
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useAlertdepStore } from '@sto/alertdep.store'
import { reactive, computed } from 'vue'
import CrossIcon from '@ico/CrossIcon.vue'
export default {
    components: {
        CrossIcon
    },
    setup() {
        const ALERTDEP_STORE = useAlertdepStore()
        
        const alerts = computed(() => {
            return ALERTDEP_STORE.alerts
        });
        
        return {
            ALERTDEP_STORE,
            alerts
        }
    },
}
</script>