<template>
    <button type="button" class="flex items-center font-medium text-gray-500 hover:text-gray-600 transition" v-on:click="() => ROUTER.back()">
        <ChevronLeftStrokeIcon class="h-3.5 w-3.5 mr-1" :stroke_width="'3.8'" />
        
        {{ label || 'Back' }}
    </button>
</template>

<script>
import { useRouter } from 'vue-router'
import ChevronLeftStrokeIcon from '@ico/ChevronLeftStrokeIcon.vue'
export default {
    props: ['label'],
    components: {
        ChevronLeftStrokeIcon
    },
    setup() {
        const ROUTER = useRouter()

        return {
            ROUTER
        }
    },
}
</script>