/*******************************************************************************
 * Dependency Imports
 ******************************************************************************/

// Helpers
import { getEndpoint } from '@lib/helpers'
import axios from 'axios'

/*******************************************************************************
 * Private methods
 ******************************************************************************/

/*******************************************************************************
 * Public methods
 ******************************************************************************/

/**
 * get a valuation for a vehicle by VRM
 * @param {str} vrm
 * @param {int} mileage
 * @returns 
 */
export const valuation = async (vrm, mileage, allow_existing_finance = 0) => {

    return new Promise(( resolve, reject ) => {

        if (!vrm) {
            reject({message: 'VRM missing'})
            return
        }

        axios({
            method: 'get',
            url: getEndpoint('/vehicle/valuation?vrm=' + vrm + '&mileage=' + mileage + '&allow_existing_finance=' + allow_existing_finance),
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * get a vehicle data by VRM
 * @param {str} vrm
 * @returns 
 */
export const data = async vrm => {

    return new Promise(( resolve, reject ) => {

        if (!vrm) {
            reject({message: 'VRM missing'})
            return
        }

        axios({
            method: 'get',
            url: getEndpoint('/vehicle/data?vrm=' + vrm),
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * get a valuation for a vehicle by VRM
 * @param {str} vrm
 * @returns 
 */
export const vrmCheck = async (vrm, user_id) => {

    return new Promise(( resolve, reject ) => {

        if (!vrm) {
            reject({message: 'VRM missing'})
            return
        }

        if (!user_id) {
            reject({message: 'user_id missing'})
            return
        }

        axios({
            method: 'get',
            url: getEndpoint('/user/' + user_id + '/vrm-check?vrm=' + vrm),
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * return a full vehicle asset from the api for this organisation
 * @param {str} vrm
 * @returns 
 */
export const findInOrganisation = async (vrm, organisation_id) => {

    return new Promise(( resolve, reject ) => {

        if (!vrm) {
            reject({message: 'VRM missing'})
            return
        }

        axios({
            method: 'get',
            url: getEndpoint('/organisation/' + organisation_id + '/vehicle?vrm=' + vrm),
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * get the vehicles for a specfied user who is associated to a dealership
 * @param {int} page
 * @param {int} per_page
 * @param {int} user_id
 * @param {obj} params
 * @returns 
 */
export const userDealerVehicles = async (user_id = null, params = []) => {

    return new Promise(( resolve, reject ) => {

        if (!user_id) {
            reject({message: 'user_id missing'})
            return
        }

        axios({
            method: 'get',
            url: getEndpoint('/user/' + user_id + '/dealer/vehicles', params),
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * get the vehicle inventory for a specfied user who is associated to a dealership
 * @param {int} page
 * @param {int} per_page
 * @param {int} user_id
 * @param {obj} params
 * @returns 
 */
export const userDealerInventory = async (user_id = null) => {

    return new Promise(( resolve, reject ) => {

        if (!user_id) {
            reject({message: 'user_id missing'})
            return
        }

        axios({
            method: 'get',
            url: getEndpoint('/user/' + user_id + '/dealer/inventory'),
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * get the user's seller inventory
 * @param {int} page
 * @param {int} per_page
 * @param {int} user_id
 * @param {obj} params
 * @returns 
 */
export const userSellerInventory = async (user_id = null) => {

    return new Promise(( resolve, reject ) => {

        if (!user_id) {
            reject({message: 'user_id missing'})
            return
        }

        axios({
            method: 'get',
            url: getEndpoint('/user/' + user_id + '/seller-inventory'),
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

const fake_inventory = {
    data: [
        // group
        {
            id: 1,
            name: 'Barretts Group',
            brands: ['kia'],
            type: 'group',
            dealer_inventory: {
                at_dealer: 102,
                for_collection: 30,
                for_delivery: 30,
            },
            my_organisations: [
                {
                    name: 'Hobbs Parker Car Auctions',
                    id: 10,
                    type: 'auctioneer',
                    auctioneer_inventory: {
                        at_auctioneer: 10,
                        awaiting_info: 5
                    }
                },
                {
                    name: 'Hobbs Parker Auctioneers',
                    id: 11,
                    type: 'auctioneer',
                    auctioneer_inventory: {
                        at_auctioneer: 10,
                        awaiting_info: 5
                    }
                }
            ],
            child_organisations: [
                {
                    id: 2,
                    name: 'Barrets Landrover Ashford',
                    brands: ['kia'],
                    type: 'dealership',
                    dealer_inventory: {
                        at_dealer: 52,
                        for_collection: 15,
                        for_delivery: 15,
                    },
                    my_organisations: [
                        {
                            name: 'Hobbs Parker Car Auctions',
                            id: 10,
                            type: 'auctioneer',
                            auctioneer_inventory: {
                                at_auctioneer: 5,
                                awaiting_info: 2
                            }
                        },
                        {
                            name: 'Hobbs Parker Auctioneers',
                            id: 11,
                            type: 'auctioneer',
                            auctioneer_inventory: {
                                at_auctioneer: 5,
                                awaiting_info: 3
                            }
                        }
                    ],
                },
                {
                    name: 'Barrets Landrover Canterbury',
                    brands: ['kia'],
                    type: 'dealership',
                    id: 3,
                    dealer_inventory: {
                        at_dealer: 50,
                        for_collection: 15,
                        for_delivery: 15,
                    },
                    my_organisations: [
                        {
                            name: 'Hobbs Parker Car Auctions',
                            id: 10,
                            type: 'auctioneer',
                            auctioneer_inventory: {
                                at_auctioneer: 5,
                                awaiting_info: 3
                            }
                        },
                        {
                            name: 'Hobbs Parker Auctioneers',
                            id: 11,
                            type: 'auctioneer',
                            auctioneer_inventory: {
                                at_auctioneer: 5,
                                awaiting_info: 2
                            }
                        }
                    ],
                }
            ]
        },
        {
            name: 'Orbital Group',
            brands: ['kia'],
            id: 4,
            type: 'group',
            dealer_inventory: {
                at_dealer: 100,
                for_collection: 30,
                for_delivery: 30,
            },
            my_organisations: [
                {
                    name: 'Hobbs Parker Car Auctions',
                    id: 10,
                    type: 'auctioneer',
                    auctioneer_inventory: {
                        at_auctioneer: 10,
                        awaiting_info: 5
                    }
                },
                {
                    name: 'Hobbs Parker Auctioneers',
                    id: 11,
                    type: 'auctioneer',
                    auctioneer_inventory: {
                        at_auctioneer: 10,
                        awaiting_info: 5
                    }
                }
            ],
            child_organisations: [
                {
                    name: 'Orbital Ashford',
                    brands: ['kia'],
                    type: 'dealership',
                    id: 5,
                    dealer_inventory: {
                        at_dealer: 50,
                        for_collection: 15,
                        for_delivery: 15,
                    },
                    my_organisations: [
                        {
                            name: 'Hobbs Parker Car Auctions',
                            id: 10,
                            type: 'auctioneer',
                            auctioneer_inventory: {
                                at_auctioneer: 5,
                                awaiting_info: 2
                            }
                        },
                        {
                            name: 'Hobbs Parker Auctioneers',
                            id: 11,
                            type: 'auctioneer',
                            auctioneer_inventory: {
                                at_auctioneer: 5,
                                awaiting_info: 3
                            }
                        }
                    ],
                },
                {
                    name: 'Orbital Canterbury',
                    brands: ['kia'],
                    type: 'dealership',
                    id: 6,
                    dealer_inventory: {
                        at_dealer: 50,
                        for_collection: 15,
                        for_delivery: 15,
                    },
                    my_organisations: [
                        {
                            name: 'Hobbs Parker Car Auctions',
                            id: 10,
                            type: 'auctioneer',
                            auctioneer_inventory: {
                                at_auctioneer: 5,
                                awaiting_info: 3
                            }
                        },
                        {
                            name: 'Hobbs Parker Auctioneers',
                            id: 11,
                            type: 'auctioneer',
                            auctioneer_inventory: {
                                at_auctioneer: 5,
                                awaiting_info: 2
                            }
                        }
                    ],
                }
            ] 
        }
    ]
}

/**
 * get the vehicle inventory for a specfied user who is associated to a dealership
 * @param {int} page
 * @param {int} per_page
 * @param {int} user_id
 * @param {obj} params
 * @returns 
 */
export const userAuctioneerSellerInventory = async (user_id = null) => {

    return new Promise(( resolve, reject ) => {

        if (!user_id) {
            reject({message: 'user_id missing'})
            return
        }

        axios({
            method: 'get',
            url: getEndpoint('/user/' + user_id + '/auctioneer-seller-inventory'),
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })

        /*
        setTimeout(() => {
            resolve({ data: fake_inventory })
        }, 1000)
        */
    })
}

/**
 * get a vehicle by id
 * @returns 
 */
export const show = async (vehicle_id = null) => {

    return new Promise(( resolve, reject ) => {

        if (!vehicle_id) {
            reject({message: 'vehicle_id missing'})
            return
        }

        axios({
            method: 'get',
            url: getEndpoint('/vehicle/' + vehicle_id),
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * create a vehicle
 * @param {obj} payload of startring data
 * @returns 
 */
export const create = async payload => {

    return new Promise(( resolve, reject ) => {

        if (!payload.auctioneer_id && !payload.seller_account_id) {
            reject({message: 'Auctioneer ID and Seller Account ID missing'})
            return
        }

        axios({
            method: 'post',
            url: getEndpoint('/vehicle'),
            data: payload,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * update a vehicle
 * @param {int} vehicle_id
 * @param {obj} payload of startring data
 * @returns 
 */
 export const update = async (vehicle_id, payload = null) => {

    return new Promise(( resolve, reject ) => {

        axios({
            method: 'patch',
            url: getEndpoint('/vehicle/' + vehicle_id),
            data: payload,
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * find MOT expiry date for a vehicle by vrm and make
 * @param {str} vrm
 * @param {str} make
 * @returns 
 */
export const getMotExpiryForVrm = async (vrm, make) => {

    return new Promise(( resolve, reject ) => {

        axios({
            method: 'get',
            url: getEndpoint('/services/mot-expiry?vrm=' + vrm + '&make=' + make),
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * find MOT expiry date for a vehicle by vehicle id
 * @param {int} vehicle_id
 * @returns 
 */
export const getMotExpiryForVehicle = async vehicle_id => {

    return new Promise(( resolve, reject ) => {

        axios({
            method: 'patch',
            url: getEndpoint('/vehicle/' + vehicle_id + '/mot-expiry'),
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * refresh the vehicle cap valluations
 * @param {int} vehicle_id
 * @returns 
 */
export const refreshValuations = async vehicle_id => {

    return new Promise(( resolve, reject ) => {

        axios({
            method: 'patch',
            url: getEndpoint('/vehicle/' + vehicle_id + '/refresh-valuations'),
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * trye to delete an organisation's vehicle
 * @param {int} organisation_id
 * @param {int} vehicle_id
 * @returns 
 */
export const removeOrganisationVehicle = async (organisation_id, vehicle_id) => {

    return new Promise(( resolve, reject ) => {

        axios({
            method: 'delete',
            url: getEndpoint('/organisation/' + organisation_id + '/vehicle/' + vehicle_id),
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * get the checks for a vehicle
 * @param {int} vehicle_id
 * @param {arr} params
 * @returns 
 */
export const getChecks = async (vehicle_id, params = []) => {

    return new Promise(( resolve, reject ) => {

        if (!vehicle_id) return reject({ message: 'vehicle_id is required'})

        axios({
            method: 'get',
            url: getEndpoint('/vehicle/' + vehicle_id + '/checks', params),
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * run and stoire the full checks for a vehicle
 */
export const runChecks = async vehicle_id => {

    return new Promise(( resolve, reject ) => {

        if (!vehicle_id) return reject({ message: 'vehicle_id is required'})

        axios({
            method: 'post',
            url: getEndpoint('/vehicle/' + vehicle_id + '/full-check'),
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * ===================================================
 */

/**
 * get the user's vehicles
 */
export const getUserVehicles = async (user_id, params) => {
    return new Promise(( resolve, reject ) => {

        axios({
            method: 'get',
            url: getEndpoint('/user/' + user_id + '/vehicles', params),
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}
