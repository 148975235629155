/*******************************************************************************
 * Dependency Imports
 ******************************************************************************/
// auth::api
import * as Api from './api'
// lodash - https://lodash.com/
import _ from 'lodash'

/*******************************************************************************
 * Services
 ******************************************************************************/

/*******************************************************************************
 * Configuration
 ******************************************************************************/

/*******************************************************************************
 * Transformer prototypes
 ******************************************************************************/

/*******************************************************************************
 * Private methods
 ******************************************************************************/

/**
 * general success
 *
 * @param {obj} data
 * @return {obj} le data
 */
const _onSuccess = data => {

    if( _.get( data, 'data.data' ) ) {
        return _.get( data, 'data.data' )
    }

    return data

}

/**
 * general fail
 *
 * @param {obj} error from a server
 * @return {obj} the error
 */
const _onFailed = error => new Promise(( resolve, reject ) => {

    let error_obj = error.hasOwnProperty( 'response' ) ? error.response : error

    reject( _.get( error_obj, 'data', null ) || error_obj )

})

/*******************************************************************************
 * Public methods
 ******************************************************************************/

/**
 * get the sanctum csrf
 *
 * @return {promise}
 */
export const csrf = () =>
    Api.csrf()
	    .then( _onSuccess )
        .catch( _onFailed )

/**
 * login
 *
 * @return {promise}
 */
 export const login = payload =>
    Api.login( payload )
        .then( _onSuccess )
        .catch( _onFailed )

/**
 * logout
 *
 * @return {promise}
 */
export const logout = () =>
    Api.logout()
        .then( _onSuccess )
        .catch( _onFailed )

/**
 * get currently authenticated user
 *
 * @return {promise}
 */
 export const user = () =>
    Api.user()
        .then( _onSuccess )
        .catch( _onFailed )


/**
 * get currently authenticated user
 *
 * @return {promise}
 */
export const test = () =>
    Api.test()
        .then( _onSuccess )
        .catch( _onFailed )

/**
 * request Password Reset
 *
 * @return {promise}
 */
export const requestPasswordReset = payload =>
    Api.requestPasswordReset( payload )
        .then( _onSuccess )
        .catch( _onFailed );

/**
* reset Password
*
* @return {promise}
*/
export const resetPassword = payload =>
    Api.resetPassword( payload )
        .then( _onSuccess )
        .catch( _onFailed );
