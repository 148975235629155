export default {
    
    // anything date related
    'dates': {

        'formats': {

            'brief_date': 'D MMM \'YY',

            'full_date': 'ddd D MMM YYYY',
            'full_time': 'h:mm a',
            'full_datetime': 'ddd D MMM YYYY h:mm a'
        }
    },

    // anything checks related
    'checks': {

        'status_colours': {
            'clear': 'bg-green-500',
            'warning': 'bg-amber-500',
            'alert': 'bg-red-500',
            'default': 'bg-gray-300'
        }
    },

}