<template>
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 500 500" fill="currentColor">
        <g>
            <path d="M402,159.3H99.7c-5.6,0-10.1,4.5-10.1,10.1v282.2c0,22.3,18,40.3,40.3,40.3h241.9c22.3,0,40.3-18,40.3-40.3V169.4
                C412.1,163.8,407.6,159.3,402,159.3z M215.6,421.3c0,8.3-6.8,15.1-15.1,15.1c-8.3,0-15.1-6.8-15.1-15.1V239.9
                c0-8.3,6.8-15.1,15.1-15.1c8.3,0,15.1,6.8,15.1,15.1V421.3z M316.3,421.3c0,8.3-6.8,15.1-15.1,15.1c-8.3,0-15.1-6.8-15.1-15.1
                V239.9c0-8.3,6.8-15.1,15.1-15.1c8.3,0,15.1,6.8,15.1,15.1V421.3z"/>
            <path d="M452.4,88.8h-95.7c-2.8,0-5-2.3-5-5V58.5c0-27.8-22.6-50.4-50.4-50.4H200.5c-27.8,0-50.4,22.6-50.4,50.4v25.2
                c0,1.3-0.5,2.6-1.5,3.6c-0.9,1-2.2,1.5-3.6,1.5H49.3c-11.1,0-20.2,9-20.2,20.2c0,11.1,9,20.2,20.2,20.2h403.1
                c11.1,0,20.2-9,20.2-20.2C472.5,97.8,463.5,88.8,452.4,88.8z M311.3,83.7c0,2.8-2.3,5-5,5H195.4c-2.8,0-5-2.3-5-5V58.5
                c0-5.6,4.5-10.1,10.1-10.1h100.8c5.6,0,10.1,4.5,10.1,10.1V83.7z"/>
        </g>
    </svg>
</template>