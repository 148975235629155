/*******************************************************************************
 * Dependency Imports
 ******************************************************************************/
// auth::api
import * as Api from './api'
// lodash - https://lodash.com/
import _ from 'lodash'

/*******************************************************************************
 * Services
 ******************************************************************************/

/*******************************************************************************
 * Configuration
 ******************************************************************************/

/*******************************************************************************
 * Transformer prototypes
 ******************************************************************************/

/*******************************************************************************
 * Private methods
 ******************************************************************************/

/**
 * general success
 *
 * @param {obj} data
 * @return {obj} le data
 */
const _onSuccess = data => {

    if( data.hasOwnProperty('data') && data.data.hasOwnProperty('data') ) {
        return _.get( data, 'data.data' )
    }

    return data

}

/**
 * general fail
 *
 * @param {obj} error from a server
 * @return {obj} the error
 */
const _onFailed = error => new Promise(( resolve, reject ) => {

    let error_obj = error.hasOwnProperty( 'response' ) ? error.response : error

    let return_error = {
        ...error_obj.data,
        status: error_obj.status
    }

    reject( return_error )

})

/*******************************************************************************
 * Public methods
 ******************************************************************************/

/**
 * get a vehicle valuation
 *
 * @return {promise}
 */
export const valuation = (vrm, mileage, allow_existing_finance) =>
    Api.valuation(vrm, mileage, allow_existing_finance)
        .then( _onSuccess )
        .catch( _onFailed )

/**
 * get a vehicle valuation
 *
 * @return {promise}
 */
export const data = vrm =>
    Api.data(vrm)
        .then( _onSuccess )
        .catch( _onFailed )

/**
 * eexisting vrm check
 *
 * @return {promise}
 */
export const vrmCheck = (vrm, user_id) =>
    Api.vrmCheck(vrm, user_id)
        .then( _onSuccess )
        .catch( _onFailed )

/**
 * existing full vehicle data
 */
export const findInOrganisation = (vrm, organisation_id) =>
    Api.findInOrganisation(vrm, organisation_id)
        .then( _onSuccess )
        .catch( _onFailed )

/**
 * get a vehicle
 *
 * @return {promise}
 */
export const show = id =>
    Api.show(id)
        .then( _onSuccess )
        .catch( _onFailed )

/**
 * create a vehicle for na organisation
 */
export const create = payload =>
    Api.create(payload)
        .then( _onSuccess )
        .catch( _onFailed )

/**
 * get the vehicles for a user who is associated with a dealership
 */
export const userDealerVehicles = (user_id, payload) =>
    Api.userDealerVehicles(user_id, payload)
        .then( _onSuccess )
        .catch( _onFailed )

/**
 * get the vehicles inventory for a user who is associatd with a dealership
 */
export const userDealerInventory = user_id =>
    Api.userDealerInventory(user_id)
        .then( _onSuccess )
        .catch( _onFailed )

/**
 * get a user's seller inventory
 * @param {int} user_id 
 * @returns 
 */
export const userSellerInventory = async (user_id = null) =>
    Api.userSellerInventory(user_id)
        .then( _onSuccess )
        .catch( _onFailed )

/**
 * get the vehicles inventory for a user who is associatd with an auctioneer
 */
export const userAuctioneerSellerInventory = user_id =>
    Api.userAuctioneerSellerInventory(user_id)
        .then( _onSuccess )
        .catch( _onFailed )

/**
 * update a vehicle by id
 */
export const update = (vehicle_id, payload) =>
    Api.update(vehicle_id, payload)
        .then( _onSuccess )
        .catch( _onFailed )

/**
 * mot expiry by vrm and make
 */
export const getMotExpiryForVrm = async (vrm, make) =>
    Api.getMotExpiryForVrm(vrm, make)
        .then( _onSuccess )
        .catch( _onFailed )

export const getMotExpiryForVehicle = async vehicle_id =>
    Api.getMotExpiryForVehicle(vehicle_id)
        .then( _onSuccess )
        .catch( _onFailed )


export const refreshValuations = async vehicle_id =>
    Api.refreshValuations(vehicle_id)
        .then( _onSuccess )
        .catch( _onFailed )

/**
 * get the checks for a given vehicle
 */
export const getChecks = async (vehicle_id, params = []) =>
    Api.getChecks(vehicle_id, params)
        .then( _onSuccess )
        .catch( _onFailed )

export const runChecks = async vehicle_id =>
    Api.runChecks(vehicle_id)
        .then( _onSuccess )
        .catch( _onFailed )
        
export const removeOrganisationVehicle = async (organisation_id, vehicle_id) =>
    Api.removeOrganisationVehicle(organisation_id, vehicle_id)
        .then( _onSuccess )
        .catch( _onFailed )

export const getUserVehicles = async (user_id, params) =>
    Api.getUserVehicles(user_id, params)
        .then( _onSuccess )
        .catch( _onFailed )
