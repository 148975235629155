<!--
    SystemAlert

    system_alert
        - type {string} | "warning", "info", "error", "success" default: "info"
        - heading {string}
        - *message {string}
        - *persist {bool} default false
        - *actions {arr}
            - type {string} | "route", "button", "component"
            - label {string}
            - *comoponent {component}
            - *component_properties {obj}
            - *route_path {string}
            - *color {string} a tailwind colour
            - *onClick {function}
            - *payload {obj}
-->

<template>
    <transition enter-active-class="transform ease-out duration-500 transition" enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2" enter-to-class="translate-y-0 opacity-100 sm:translate-x-0" leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
        <div v-show="state.visible" class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
            <div class="p-4">
                <div class="flex items-start">
                    <div class="flex-shrink-0">
                        <AlertWarningIcon v-if="system_alert.type == 'warning'" class="text-yellow-500 h-6 w-6" aria-hidden="true" />
                        <AlertWarningIcon v-else-if="system_alert.type == 'error'" class="text-red-500 h-6 w-6" aria-hidden="true" />
                        <AlertSuccessIcon v-else-if="system_alert.type == 'success'" class="text-green-500 h-6 w-6" aria-hidden="true" />
                        <AlertInfoIcon v-else class="text-blue-500 h-6 w-6" aria-hidden="true" />
                    </div>
                    <div class="ml-3 w-0 flex-1 pt-0.5">
                        <p class="text-sm font-medium text-gray-900" v-html="system_alert.heading"></p>
                        <p class="mt-1 text-sm text-gray-500" v-html="system_alert.message"></p>
                        <div v-if="Array.isArray(system_alert.actions) && system_alert.actions.length > 0" class="mt-3 flex space-x-7">
                            <HasOrganisationRole v-for="(action, index) in system_alert.actions"  :key="index" :roles="action.roles" organisation_id="*">
                                <router-link v-if="action.type == 'route'" :to="action.route_path" class="rounded-md bg-white text-sm font-medium focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2" :class="`text-${action.color || 'blue'}-600 hover:text-${action.color || 'blue'}-500`">{{ action.label }}</router-link>
                                <button v-if="action.type == 'button'" v-on:click="action.onClick(action.payload)" type="button" class="rounded-md bg-white text-sm font-medium focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2" :class="`text-${action.color || 'blue'}-600 hover:text-${action.color || 'blue'}-500`">{{ action.label }}</button>
                                <component v-if="action.type == 'component'" :is="action.component" v-bind="action.component_properties" />
                            </HasOrganisationRole>
                        </div>
                    </div>
                    <div class="ml-4 flex flex-shrink-0">
                        <button type="button" v-on:click="removeAlert()" class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                            <span class="sr-only">Close</span>
                            <XmarkFillIcon class="h-5 w-5" aria-hidden="true" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import { useSystemStore } from '@sto/system.store'
import { reactive, onMounted } from 'vue'
import XmarkFillIcon from '@ico/XmarkFillIcon.vue'
import AlertInfoIcon from '@ico/AlertInfoIcon.vue'
import AlertSuccessIcon from '@ico/AlertSuccessIcon.vue'
import AlertWarningIcon from '@ico/AlertWarningIcon.vue'
export default {
    props: ['system_alert'],
    components: {
        XmarkFillIcon,
        AlertInfoIcon,
        AlertSuccessIcon,
        AlertWarningIcon
    },
    setup(props) {
        const SYSTEM_STORE = useSystemStore()

        const state = reactive({
            visible: false
        })

        const hide = () => {
            state.visible = false
        }

        const show = () => {
            state.visible = true
        }

        const removeAlert = () => {
            hide()
            // allow the fade oout animation to complete before removing
            setTimeout(() => {
                SYSTEM_STORE.remove(props.system_alert.id)
            }, 500)
        }

        onMounted(() => {
            // show on load because it allows the transition in
            show()
            // by edfault, alerts will remove themsleves after a short period
            if (!props.system_alert.persist) {
                setTimeout(() => {
                    hide()
                }, 3000)
            }
        })

        return {
            state,
            removeAlert
        }
    },
}
</script>