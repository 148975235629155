<template>
    <div v-if="timeline_events.length > 0" class="flow-root">
        <ul role="list" class="-mb-8">
            <li v-for="(event, index) in timeline_events" :key="index">
                <TimelineEvent :event="event" :is_last="timeline_events.length == (index + 1)" />
            </li>
        </ul>
    </div>
</template>

<script>
import { reactive, computed, watch } from 'vue'
export default {
    props: ['events'],
    setup(props) {
        const timeline_events = computed(() => Array.isArray(props.events) ? props.events : [])

        return {
            timeline_events
        }
    },
}
</script>