<template>
    <header class="flex-0 bg-white shadow border-b border-gray-200">
        <div class="py-6">
            <div class="px-4 sm:px-6 md:px-8">

                <NavigationBreadcrumb />

                <slot></slot>

            </div>
        </div>
    </header>
</template>

<script>
import AccountMenu from '@par/account/AccountMenu.vue'
import NavigationBreadcrumb from '@par/navigation/NavigationBreadcrumb.vue'
export default {
    components: {
        AccountMenu,
        NavigationBreadcrumb
    },
    setup() {
        
    },
}
</script>