<template>
    <div class="border-b border-gray-200 pb-4 sm:flex sm:items-end sm:justify-between mb-12">
        <h3 class="inline-block text-base font-semibold text-gray-900" v-html="heading"></h3>
        <div class="mt-3 sm:mt-0 sm:ml-4">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: ['heading'],
    setup() {
        
    },
}
</script>