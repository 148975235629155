<template>
    <span :class="style_string" v-html="text"></span>
</template>

<script>
import { computed } from 'vue'
export default {
    props: ['color', 'text', 'size', 'format'],
    setup(props) {
        
        const style_string = computed(() => {
            let dimensions,
                text_size
            switch(props.size) {
                case 'lg':
                    dimensions = props.format == 'border'
                        ? { x: '3', y: '1.5'}
                        : { x: '3', y: '0.5'}
                    text_size = 'sm'
                    break
                case 'sm':
                default:
                    dimensions = props.format == 'border'
                        ? { x: '2', y: '1'}
                        : { x: '2.5', y: '0.5'}
                    text_size = 'xs'
            }

            if (props.format == 'border') {
                return `inline-flex items-center rounded-md bg-${props.color || 'gray'}-50 px-2 py-1 text-${text_size || 'xs'} font-medium text-${props.color || 'gray'}-700 ring-1 ring-inset ring-${props.color || 'gray'}-600/20`
            }

            return `inline-flex items-center capitalize rounded-full bg-${props.color || 'gray'}-100 px-${dimensions?.x || '2.5'} py-${dimensions?.y || '0.5'} text-${text_size || 'xs'} font-medium text-${props.color || 'gray'}-800`
        })

        return {
            style_string
        }
    },
}
</script>