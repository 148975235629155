<template>
    <slot v-if="has_scope"></slot>
</template>

<script>
import { useAuthStore } from '@sto/auth.store'
import { computed } from 'vue'
export default {
    props: ['scopes','organisation_id', 'exclude_scopes'],
    setup(props) {
        // auth store
        const AUTH = useAuthStore()
        const {user} = AUTH
        const user_organisations = () => {
            // if no user or malfored/unexpected organisations property, return empty array
            if (!user || !Array.isArray(user.organisations)) return []
            // if no organisation is specified, assume all user's organisations
            if (!props.organisation_id || props.organisation_id == '*') return user.organisations
            // just the specified organisation object found by id
            return user.organisations.filter(org => org.id == props.organisation_id)
        }

        const has_scope = computed(() => {
            // super admin procede always
            // no scopes supplied - might be the case if the component was called in a loop
            if (!props.scopes || (Array.isArray(props.scopes) && props.scopes.length === 0)) return true
            // get the organisations
            const organisations = user_organisations()
            // malformed or missing organisation arrays stop here - must check *after* super admin because they will have no organisations
            if (!organisations || !Array.isArray(organisations) || organisations.length == 0) return false
            let has_scope_flag = false
            organisations.forEach(org => {
                // for each of the organisations, check if the organisation type match at least one of the supplied scope
                if (props.scopes.includes(org.type)) has_scope_flag = true
            })

            return has_scope_flag
        })

        return {
            has_scope
        }
    },
}
</script>