<template>
    <div :class="classes">
        <div class="flex items-center">
            <div>
                <h3 class="text-lg leading-6 font-medium text-gray-900">{{ heading }}</h3>
                <p v-if="subheading" class="mt-1 max-w-2xl text-sm text-gray-500">{{ subheading }}</p>
            </div>
        </div>
        <div class="flex content-center items-center">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import { computed } from 'vue'
export default {
    props: ['heading','subheading', 'size'],
    setup(props) {
        const classes = computed(() => {
            let class_string = 'flex min-w-0 flex-1 justify-between '

            switch(props.size) {
                case 'sm':
                    class_string += 'py-1 sm:py-4 sm:px-3'
                    break
                case 'lg':
                default:
                    class_string += 'px-4 py-5 sm:px-6'
            }

            if (props.background_class) class_string += ' ' + props.background_class

            class_string += ' sm:grid-cols-' + (props.grid_cols ? props.grid_cols : '3')

            return class_string
        })

        return {
            classes
        }
    },
}
</script>