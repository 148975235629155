<template>
    <div v-if="active">
        <div v-if="notes" class="bg-blue-50 mb-2 py-2 rounded-md text-blue-500 flex mt-6">
            <InformationCircleIcon class="flex-shrink-0 mr-1.5 h-5 w-5" aria-hidden="true" />
            <p v-html="notes" class="text-sm font-medium"></p>
        </div>

        <!-- OTHER FIELD -->
        <div>
            <label v-if="label" class="block w-full text-sm font-medium text-gray-700 mb-2">{{ label }}</label>
            <div class="relative">
                <span v-if="value" class="appearance-none block py-2 font-semibold text-gray-500 sm:text-sm">{{ value }}</span>
            </div>
        </div>

        <p v-if="message" class="mt-2 text-sm text-gray-500">{{ message }}</p>
    </div>
</template>

<script>
    import { watch, ref, reactive, computed } from 'vue'
    import InformationCircleIcon from '@ico/InformationCircleIcon.vue'
    export default {
        props: ['active', 'notes', 'label', 'value', 'message'],
        components: {
            InformationCircleIcon,
        },
        setup(props) {

        }
    }
</script>
