/*******************************************************************************
 * Dependency Imports
 ******************************************************************************/

// Helpers
import { getEndpoint } from '@lib/helpers'
import axios from 'axios'

/*******************************************************************************
 * Private methods
 ******************************************************************************/

/*******************************************************************************
 * Public methods
 ******************************************************************************/

/**
 * get alerts for a user
 * @param {str} user_id
 * @returns 
 */
export const getForUser = async (user_id, params = []) => {

    return new Promise(( resolve, reject ) => {

        if (!user_id) {
            reject({message: 'user_id missing'})
            return
        }

        axios({
            method: 'get',
            url: getEndpoint('/user/'+ user_id + '/alerts', params),
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * update an alert
 * @param {str} alert_id
 * @param {obj} payload
 * @returns 
 */
 export const update = async (alert_id, payload) => {

    return new Promise(( resolve, reject ) => {

        if (!alert_id) {
            reject({message: 'alert_id missing'})
            return
        }

        axios({
            method: 'patch',
            url: getEndpoint('/alert/'+ alert_id),
            data: payload,
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * delete an alert
 * @param {str} alert_id
 */
export const deleteById = async alert_id => {
    return new Promise(( resolve, reject ) => {

        if (!alert_id) {
            reject({message: 'alert_id missing'})
            return
        }

        axios({
            method: 'delete',
            url: getEndpoint('/alert/'+ alert_id),
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * update all alerts for a user
 * @param {str} user_id
 * @param {arr} params
 * @returns 
 */
export const updateForUser = async (user_id, payload) => {

    return new Promise(( resolve, reject ) => {

        if (!user_id) {
            reject({message: 'user_id missing'})
            return
        }

        axios({
            method: 'patch',
            url: getEndpoint('/user/'+ user_id + '/alerts'),
            data: payload,
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * update all alerts for a user
 * @param {str} user_id
 * @param {arr} params
 * @returns 
 */
export const deleteForUser = async (user_id) => {

    return new Promise(( resolve, reject ) => {

        if (!user_id) {
            reject({message: 'user_id missing'})
            return
        }

        axios({
            method: 'delete',
            url: getEndpoint('/user/'+ user_id + '/alerts'),
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}