<template>
    <dt :class="classes">
        <slot></slot>
    </dt>
</template>

<script>
import { computed } from 'vue'
export default {
    props: ['colspan', 'color', 'weight', 'size'],
    setup(props) {
        const classes = computed(() => {
            let class_string = ''

            class_string += ' col-span-' + (props.colspan ? props.colspan : '1')

            // colour
            class_string += ' text-' + (props.color ? props.color : 'gray-500')

            // weight
            class_string += ' font-' + (props.weight ? props.weight : 'medium')

            // size
            class_string += ' text-' + (props.size ? props.size : 'sm')

            return class_string
        })

        return {
            classes
        }
    },
}
</script>