/*******************************************************************************
 * Dependency Imports
 ******************************************************************************/

// Helpers
import { getEndpoint } from '@lib/helpers'
import axios from 'axios'

/*******************************************************************************
 * Private methods
 ******************************************************************************/

/*******************************************************************************
 * Public methods
 ******************************************************************************/

/**
 * post a reply to an existing comment
 * @param {str} comment_id
 * @param {obj} payload
 * @returns 
 */
 export const reply = async (comment_id, payload) => {

    return new Promise(( resolve, reject ) => {

        if (!comment_id) {
            reject({message: 'comment_id missing'})
            return
        }

        axios({
            method: 'post',
            url: getEndpoint('/comments/'+ comment_id + '/reply'),
            data: payload,
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}