<template>
    <TransitionRoot as="template" :show="active">
        <Dialog as="div" class="relative z-10" @close="$emit('close')">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </TransitionChild>

            <div class="fixed z-10 inset-0 overflow-y-auto">
                <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                    <DialogPanel :class="`relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:p-12`" :style="modal_width">
                        <slot></slot>
                    </DialogPanel>
                </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script>
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { computed, onMounted, onBeforeMount, reactive, watch } from 'vue'
export default {
    props: ['active', 'size'],
    components: {
        Dialog,
        DialogPanel,
        DialogTitle,
        TransitionChild,
        TransitionRoot
    },
    setup(props) {

        const modal_width = computed(() => {

            let width

            switch(props.size) {
                case '2xl':
                    width = '62em'
                    break
                case 'xl':
                    width = '52em'
                    break
                case 'lg':
                    width = '42em'
                    break
                case 'md':
                    width = '32em'
                    break
                case 'sm':
                default:
                    width = '24em'
            }

            return `width:100%;max-width:${width};`

        })

        return {
            modal_width
        }
    },
}
</script>