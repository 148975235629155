<template>
    <div aria-live="assertive" class="pointer-events-none fixed z-50 inset-0 flex items-end px-4 py-6 sm:items-end sm:p-6 lg:pt-24">
        <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
            <SystemAlert v-for="system_alert in system_alerts" :key="system_alert.id" :system_alert="system_alert" />
        </div>
    </div>
</template>

<script>
import SystemAlert from '@par/system/SystemAlert.vue'
import { useSystemStore } from '@sto/system.store'
import { computed, reactive, onMounted } from 'vue'
export default {
    components: {
        SystemAlert
    },
    setup() {
        const SYSTEM_STORE = useSystemStore()

        const system_alerts = computed(() => {
            return SYSTEM_STORE.system_alerts
        })

        return {
            system_alerts
        }
    },
}
</script>