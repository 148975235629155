<template>
    <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
        <div class="sm:col-span-3">
            <div>
                <label for="name" :class="getClasses('label', state.item_create_form.fields.name)">Name</label>
                <div class="mt-1 relative rounded-md shadow-sm">
                    <input v-model="state.item_create_form.fields.name.value" type="text" name="name" id="name" :class="getClasses('input', state.item_create_form.fields.name)" />
                    <div class="absolute inset-y-0 right-0 flex items-center">
                        <button class="focus:ring-blue-500 focus:border-blue-500 h-full py-0 pl-2 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md" v-on:click="state.item_suggestions_visible = !state.item_suggestions_visible">Select</button>
                    </div>
                    <ul v-show="state.item_suggestions_visible" class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm" tabindex="-1" role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-option-3">
                        <li v-for="(item, index) in state.item_suggestions" :key="index" class="text-gray-900 cursor-default select-none relative py-2 pl-8 pr-4" id="listbox-option-0" role="option">
                            <a class="font-normal block truncate" href="#" v-on:click="setSuggestion(item.meta.field_values)">{{ item.meta.field_values.name }}</a>
                        </li>
                    </ul>
                </div>
                <p v-if="!state.item_create_form.fields.name.pristine && state.item_create_form.fields.name.message" :class="getClasses('message', state.item_create_form.fields.name)">{{ state.item_create_form.fields.name.message }}</p>
            </div>
        </div>

        <div class="sm:col-span-1">
            <label for="cost_net" :class="getClasses('label', state.item_create_form.fields.cost_net)">Cost (NET)</label>
            <div class="mt-1 relative rounded-md shadow-sm">
                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <span class="text-gray-500 sm:text-sm"> £ </span>
                </div>
                <input v-model="state.item_create_form.fields.cost_net.value" type="text" name="cost_net" id="cost_net" :class="`${getClasses('input', state.item_create_form.fields.cost_net)} pl-7`" aria-describedby="price" v-on:blur="formatCostString" />
            </div>
            <p v-if="!state.item_create_form.fields.cost_net.pristine && state.item_create_form.fields.cost_net.message" :class="getClasses('message', state.item_create_form.fields.cost_net)">{{ state.item_create_form.fields.cost_net.message }}</p>
        </div>

        <div class="sm:col-span-1">
            <div class="mt-8">
                <div class="relative flex items-start">
                    <div class="flex items-center h-5">
                        <input v-model="state.item_create_form.fields.apply_tax.value" id="apply-tax" name="apply-tax" type="checkbox" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded" />
                    </div>
                    <div class="ml-2 text-sm">
                        <label for="apply-tax" :class="getClasses('checkbox-label', state.item_create_form.fields.apply_tax)">Apply tax?</label>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="sm:col-span-1">
            <Button type="button" width="w-full" class="mt-6" color="blue-100" :onClick="saveItem">Add</Button>
        </div>
    </div>

</template>

<script>
import { useLoanStore } from '@sto/loan.store'
import { computed, onMounted, onBeforeMount, reactive, watch } from 'vue'
import { useRoute } from 'vue-router'
import { isValid, getPayload, poundsToPence } from '@lib/helpers'
export default {
    setup() {
        const LOAN = useLoanStore()
        const ROUTE = useRoute()

        const state = reactive({
            item_suggestions_visible: false,
            item_suggestions: computed(() => {
                if (!Array.isArray(LOAN.auctioneer_configurations)) return []
                return LOAN.auctioneer_configurations.filter(c => c.slug == 'organisation_loan_item')
            }),
            loan_misc_items: computed(() => LOAN.loan.items.filter(i => i.type == 'misc')),
            disable_validation: false,
            item_create_form: {
                fields: {
                    name: {
                        active: true,
                        value: '',
                        type: 'string',
                        default_value: '',
                        required: true,
                        pristine: true,
                        valid: false,
                        message: '',
                        notes: '',
                    },
                    cost_net: {
                        active: true,
                        value: null,
                        type: 'number',
                        default_value: '',
                        transform: poundsToPence,
                        required: false,
                        pristine: true,
                        valid: false,
                        message: '',
                        notes: '',
                    },
                    apply_tax: {
                        active: true,
                        value: true,
                        type: 'checkbox',
                        default_value: false,
                        required: false,
                        pristine: true,
                        valid: true,
                        message: '',
                        notes: '',
                    },
                }
            }
        })

        /**
         * get the appropriate field classes for this field
         */
        const getClasses = ( el, field ) => {
            if (!el || !field) return

            switch(el) {
                case 'label':
                    return !field.pristine && !field.valid ? 'block text-sm font-medium text-red-700' : 'block text-sm font-medium text-gray-700'
                case 'checkbox-label':
                    return !field.pristine && !field.valid ? 'text-red-700' : 'text-gray-700'
                case 'input':
                    return !field.pristine && !field.valid ? 'shadow-sm focus:ring-red-500 focus:border-red-500 block w-full sm:text-sm border border-red-300 rounded-md px-3 py-2' : 'shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border border-gray-300 rounded-md px-3 py-2'
                case 'message':
                    return !field.pristine && !field.valid ? 'mt-2 text-sm text-red-400' : 'mt-2 text-sm text-gray-400'
            }
        }

        /**
         * format a nummber into a cost string (2 decimal place float)
         */
        const formatCostString = () => {
            if (isNaN(state.item_create_form.fields.cost_net.value)
                || state.item_create_form.fields.cost_net.value === ''
                || state.item_create_form.fields.cost_net.value === null) return

            state.item_create_form.fields.cost_net.value = (Math.round(state.item_create_form.fields.cost_net.value * 100) / 100).toFixed(2)
        }

        /**
         * reset the form back to a clean starting state
         */
        const resetFormState = () => {
            state.disable_validation = true
            for (let key in state.item_create_form.fields) {
                state.item_create_form.fields[key].value = state.item_create_form.fields[key].default_value
                state.item_create_form.fields[key].pristine = true
                state.item_create_form.fields[key].valid = !state.item_create_form.fields[key].required
            }
            setTimeout(() => { state.disable_validation = false }, 500)
        }

        /**
         * check a single field to see if it's valid
         * @param {*} field 
         * @return {bool}
         */
        const isValidField = field => {
            field.valid = true
            field.pristine = false
            field.message = ''

            switch(field.type) {
                case 'number':
                    if (isNaN(field.value)
                        || field.value === null
                        || field.value === '') {

                        field.valid = false
                        field.message = 'Please enter a valid number'
                    }
                    break
                case 'string':
                    if (!field.value) {
                        field.valid = false
                        field.message = 'This is a required field'
                    }
                    break
                case 'checkbox':
                    break
            }

            return field.valid
        }

        /**
         * checks the entire field set to see if it's valid
         * @return {bool}
         */
        const isValid = () => {
            let is_valid_group = true

            for (let key in state.item_create_form.fields) {
                if (!isValidField(state.item_create_form.fields[key])) is_valid_group = false
            }

            return is_valid_group
        }

        /**
         * save the item from the current form state
         */
        const saveItem = async () => {

            if (!isValid()) return

            const item_payload = getPayload(state.item_create_form.fields)
            item_payload.apply_tax = item_payload.apply_tax
                ? 1
                : 0
            item_payload.type = 'misc'
            item_payload.cost_net = poundsToPence(item_payload.cost_net)

            try {
                const item = await LOAN.createItem(ROUTE.params.loan_id, LOAN.loan?.auctioneer_id, item_payload)
                resetFormState()
            } catch (error) {
                console.error('saveItem', error)
                // TODO handle error
            }
        }

        const setSuggestion = suggestion => {
            state.item_create_form.fields.name.value = suggestion.name
            state.item_create_form.fields.cost_net.value = !isNaN(suggestion.cost_net) && suggestion.cost_net > 0 ? (suggestion.cost_net / 100) : 0
            state.item_create_form.fields.apply_tax.value = suggestion.apply_tax
            formatCostString()
            isValid()
            state.item_suggestions_visible = false
        }

        watch(
            () => state.item_create_form.fields.name.value,
            (new_value, old_value) => {
                if (!state.disable_validation) isValidField(state.item_create_form.fields.name)
            }
        )

        watch(
            () => state.item_create_form.fields.cost_net.value,
            (new_value, old_value) => {
                if (!state.disable_validation) isValidField(state.item_create_form.fields.cost_net)
            }
        )

        return {
            state,
            getClasses,
            saveItem,
            formatCostString,
            setSuggestion
        }
    },
}

</script>