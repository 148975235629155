<template>
    <CheckIcon :class="class" />
</template>

<script>
import { CheckIcon } from '@heroicons/vue/solid'

export default {
    props: ['class'],
    components: {
        CheckIcon
    }
}
</script>
