<template>
    <button
        v-if="use_custom_trigger_button"
        :class="trigger_button_class"
        v-on:click="state.modal_active = !state.modal_active"
        :disabled="buttons_disabled || trigger_button_disabled">
        {{ trigger_button_text }}
    </button>
    <Button
        v-else
        :width="trigger_button_width"
        :color="trigger_button_color"
        :size="trigger_button_size || 'md'"
        :custom_class="trigger_button_custom_class || null"
        :class="trigger_button_class"
        :icon="trigger_button_icon || null"
        v-on:click="state.modal_active = !state.modal_active"
        :disabled="buttons_disabled || trigger_button_disabled">
            {{ trigger_button_text }}
    </Button>
    <Modal v-on:close="state.modal_active = false" :active="state.modal_active" size="sm">
        <div>
            <div class="text-center">
                <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900" v-html="heading"></DialogTitle>
                <div class="my-2">
                    <p class="text-sm text-gray-500" v-html="body"></p>
                </div>
            </div>
        </div>
        <div class="mt-5 sm:mt-10">
            <Button
                :onClick="onConfirm"
                type="button"
                width="w-full"
                :color="confirm_button_color || 'red'"
                :class="confirm_button_class || 'mb-2'"
                :icon="confirm_button_icon || null"
                :disabled="buttons_disabled || confirm_button_disabled"
            >
                <span v-if="!loading">{{ confirm_button_text || 'Yes' }}</span>
                <LoadingIcon v-if="loading" class="-ml-1 mr-3 h-5 w-5 animate-reverse-spin" />
            </Button>
            <Button v-if="!no_cancel" type="button" color="text-blue" v-on:click="state.modal_active = false" :disabled="buttons_disabled">{{ cancel_button_text || 'Cancel' }}</Button>
        </div>    
    </Modal>
</template>

<script>
import { reactive, watch } from 'vue'
import LoadingIcon from '@ico/LoadingIcon.vue'
export default {
    components: {
        LoadingIcon
    },
    props: [
        'trigger_button_color',
        'trigger_button_width',
        'trigger_button_disabled',
        'trigger_button_icon',
        'trigger_button_size',
        'trigger_button_class',
        'trigger_button_custom_class',
        'trigger_button_text',
        'confirm_button_color',
        'confirm_button_disabled',
        'confirm_button_icon',
        'confirm_button_class',
        'confirm_button_text',
        'cancel_button_text',
        'no_cancel',
        'buttons_disabled',
        'loading',
        'onConfirm',
        'heading',
        'body',
        'action_complete',
        'use_custom_trigger_button'
    ],
    setup(props) {
        const state = reactive({
            modal_active: false
        })

        watch(
            () => props.action_complete,
            (new_value, old_value) => {
                if (new_value) state.modal_active = false
            }
        )

        return {
            state
        }
    },
}
</script>