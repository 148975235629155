<template>
    <div class="relative w-full flex rounded-md shadow-sm">
        <div class="relative w-full flex flex-1 rounded-md border border-gray-200 bg-white">
            
            <div v-if="figure" class="flex-shrink-0 flex items-center justify-center w-16 rounded-l-md" :class="figure.container_class || 'bg-blue-500'">
                <span v-if="figure.type == 'text'" v-html="figure.content" :class="figure.content_class || 'text-white text-lg font-medium'"></span>
                <component v-if="figure.type == 'component'" :is="figure.content" :class="figure.content_class" />
            </div>

            <div class="flex flex-1 items-center justify-between truncate">
                <div class="flex-1 truncate px-4 py-2 text-sm">
                    <a v-if="heading" href="#" class="font-medium text-gray-900 hover:text-gray-600" v-html="heading"></a>
                    <p v-if="message" class="text-gray-500" v-html="message"></p>
                </div>
            </div>
            <div v-if="Array.isArray(links) && links.length > 0" class="flex-shrink-0 pr-2 flex items-center justify-between">
                <Menu as="div" class="relative">

                    <MenuButton class="inline-flex h-8 w-8 items-center justify-center rounded-full bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                        <span class="sr-only">Open options</span>
                        <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path d="M10 3a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM10 8.5a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM11.5 15.5a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0z" />
                        </svg>
                    </MenuButton>

                    <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                        <MenuItems class="absolute right-10 top-3 z-10 mx-3 mt-1 w-48 origin-top-right divide-y divide-gray-200 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <MenuItem v-for="(link, index) in links" :key="index">
                                <a :href="link.to" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" v-html="link.label"></a>
                            </MenuItem>
                        </MenuItems>
                    </transition>

                </Menu>
            </div>
        </div>
    </div>
</template>

<script>
import { Menu,MenuItems,MenuItem,MenuButton } from '@headlessui/vue'
import { reactive } from 'vue';
export default {
    props: ['figure', 'heading', 'message', 'links'],
    components: {
        Menu,
        MenuItems,
        MenuItem,
        MenuButton
    },
    setup() {
        const state = reactive({
            show_menu: false
        })

        return {
            state
        }
    },
}
</script>