<template>
	<Line
        :data="chart.data"
        :options="chart.options"
    />
</template>

<script>
import {onMounted, reactive, computed} from 'vue'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js'
import { Line } from 'vue-chartjs'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
)

import moment from 'moment'
import CONFIG from '@lib/config'

export default {
    components: {
        Line
    },
	setup() {

        const config = reactive({
            x_divisions: 12
        })

        const labels = computed(() => {

            let base_date = moment()
            let labels = []
            let target_day
            let offset

            for (let i = 0; i < config.x_divisions; i++) {
                offset = i % 2 == 0 ? 7
                    : 0
                target_day = i % 2 == 0 ? 4
                    : 2
                base_date = base_date.day(target_day - offset)
                labels.unshift(base_date.format(CONFIG.dates.formats.brief_date))
            }

            return labels
        })
    	
        const chart = reactive({
            data: {
                labels: labels.value,
                datasets: [
                    {
                        label: 'Total Sales',
                        backgroundColor: '#3b82f6',
                        borderColor: '#3b82f6',
                        borderWidth: 3,
                        data: [20.3, 18.6, 22.7, 19.5, 15.7, 17.3, 15.1, 20.8, 19.6, 14.2, 17.4, 21.9]
                    },
                    {
                        label: 'Total Reserve',
                        backgroundColor: '#f43f5e',
                        borderColor: '#d1d5db',
                        pointBorderColor: '#f43f5e',
                        borderWidth: 2,
                        data: [18.2, 15.9, 21.4, 17.3, 16.2, 16.8, 14.6, 16.4, 20.2, 14.7, 15.3, 19.2]
                    },
                    {
                        label: 'Total CAP Av.',
                        backgroundColor: '#22c55e',
                        borderColor: '#d1d5db',
                        pointBorderColor: '#22c55e',
                        borderWidth: 2,
                        data: [17.2, 16.1, 20.3, 18.2, 15.4, 14.8, 13.0, 15.4, 18.2, 13.5, 12.9, 18.2]
                    },
                ]
            },
            options: {
                responsive: true,
                //maintainAspectRatio: false
                aspectRatio: 4|1,
                scales: {
                    
                }
            }
        })

        return {
            chart
        }
    }
}
</script>