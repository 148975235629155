<template>
    <div v-if="state.loading" class="w-full text-center align-middle block py-12">
        <LoadingIcon class="text-gray-400 -ml-1 mr-3 h-12 w-12 inline-block animate-reverse-spin" />
    </div>
    <div v-if="!state.loading" class="bg-white shadow overflow-hidden sm:rounded-lg">
        <div class="px-4 py-5 sm:px-6">
            <h3 class="text-lg leading-6 font-medium text-gray-900">Invoice Summary</h3>
            <p class="mt-1 max-w-2xl text-sm text-gray-500">Review and confirm the invoice is correct before submitting.</p>
        </div>
        <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
            <dl class="sm:divide-y sm:divide-gray-200">
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-6 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">Invoice Number</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{{ invoice_number }}</dd>
                </div>
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-6 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">Invoice Date</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{{ invoice_date }}</dd>
                </div>
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-6 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">Buyer</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{{ `${loan_buyer_name} (${buyer_account_code})` }}</dd>
                </div>
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-6 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">Finance Lender</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{{ loan_lender_name }}</dd>
                </div>
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-6 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">{{ `${loan_lender_name ? loan_lender_name : 'Lender'} Dealer ID` }}</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{{ dealer_ref }}</dd>
                </div>
                <div v-if="asset" class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-6 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">Vehicle</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{{ `${asset.name} (${asset.description})` }}</dd>
                </div>
                <div v-if="asset" class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-6 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">Invoice Items</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <ul role="list" class="border border-gray-200 rounded-md divide-y divide-gray-200 overflow-hidden">
                            <li class="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                                <div class="w-0 flex-1 flex items-center">
                                    <span class="ml-2 flex-1 w-0 truncate text-gray-500">{{ asset.name }}</span>
                                </div>
                                <div class="ml-4 flex-shrink-0">
                                    <span v-if="asset.cost_net" class="font-medium text-gray-500">{{ monetise(asset.cost_net) }}</span>
                                </div>
                            </li>
                            <li v-for="item in misc_items" :key="item.id" class="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                                <div class="w-0 flex-1 flex items-center">
                                    <span class="ml-2 flex-1 w-0 truncate text-gray-500"> {{ item.name }} </span>
                                </div>
                                <div class="ml-4 flex-shrink-0">
                                    <span v-if="item.cost_net" class="font-medium text-gray-500">{{ monetise(item.cost_net) }}</span>
                                </div>
                            </li>
                            <li v-if="loan_totals" class="pl-3 pr-4 py-3 flex items-center justify-between text-sm bg-gray-50">
                                <div class="w-0 flex-1 flex items-center">
                                    <span class="ml-2 flex-1 w-0 truncate">Subtotal</span>
                                </div>
                                <div class="ml-4 flex-shrink-0">
                                    <span class="font-medium text-gray-900">{{ monetise(loan_totals.cost_net) }}</span>
                                </div>
                            </li>
                            <li class="pl-3 pr-4 py-3 flex items-center justify-between text-sm bg-gray-50">
                                <div class="w-0 flex-1 flex items-center">
                                    <span class="ml-2 flex-1 w-0 truncate">Tax</span>
                                </div>
                                <div class="ml-4 flex-shrink-0">
                                    <span class="font-medium text-gray-900">{{ monetise(loan_totals.cost_tax) }}</span>
                                </div>
                            </li>
                            <li class="pl-3 pr-4 py-3 flex items-center justify-between text-sm bg-gray-50">
                                <div class="w-0 flex-1 flex items-center">
                                    <span class="font-bold ml-2 flex-1 w-0 truncate">Grand total</span>
                                </div>
                                <div class="ml-4 flex-shrink-0">
                                    <span class="font-bold text-gray-900">{{ monetise(loan_totals.cost_total) }}</span>
                                </div>
                            </li>
                        </ul>
                    </dd>
                </div>
            </dl>
        </div>
        <div class="px-4 py-4 sm:px-6 text-right border-t border-gray-200">
            <Button type="button" width="w-auto" color="blue-outline" :onClick="LOAN.activatePrevStep">Back</Button>
            <Button type="button" width="w-auto" class="ml-2" :onClick="toggleApplyConfirm" :disabled="!can_submit">Submit application</Button>
        </div>
        
        <!-- apply confirm modal -->
        <Modal v-on:close="state.apply_modal_active = false" :active="state.apply_modal_active" size="sm">
            <div>
                <div class="mt-3 text-center sm:mt-5">
                    <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900"> Submit Application to {{ loan_lender_name }}</DialogTitle>
                    <div class="my-2">
                        <p class="text-sm text-gray-500">Are you sure you would like to submit this loan application? Once an application has been submitted, you will no longer be able to edit it.</p>
                    </div>
                </div>
            </div>
            <div class="mt-5 sm:mt-6">
                <Button type="button" color="red" class="mb-2" :onClick="submitApplication" :disabled="state.application_pending">
                    <span v-if="!state.application_pending">Submit application</span>
                    <LoadingIcon v-if="state.application_pending" class="-ml-1 mr-3 h-5 w-5 animate-reverse-spin" />
                </Button>
                <Button v-if="!state.application_pending" type="button" color="blue-outline" :onClick="toggleApplyConfirm">Cancel</Button>
            </div>
        </Modal>

    </div>
</template>

<script>
import { useLoanStore } from '@sto/loan.store'
import { useUserStore } from '@sto/user.store'
import { useAlertdepStore } from '@sto/alertdep.store'
import { useSystemStore } from '@sto/system.store'
import { computed, reactive, watch, onMounted } from 'vue'
import { monetise } from '@lib/helpers'
import LoadingIcon from '@ico/LoadingIcon.vue'
import { useRoute, useRouter } from 'vue-router'
export default {
    components: {
        LoadingIcon
    },
    setup() {
        const LOAN = useLoanStore()
        const USER = useUserStore()
        const ALERTS = useAlertdepStore()
        const SYSTEM_STORE = useSystemStore()
        const ROUTE = useRoute()
        const ROUTER = useRouter()

        const loan_buyer_name = computed(() => LOAN?.loan?.buyer_name )
        const invoice_number = computed(() => LOAN?.loan?.invoice_number )
        const invoice_date = computed(() => LOAN?.loan?.invoice_date )
        const buyer_account_code = computed(() => LOAN?.loan?.buyer_account_code )
        const dealer_ref = computed(() => LOAN?.loan?.dealer_ref )
        const asset = computed(() => LOAN?.loan?.items.find(i => i.type == 'asset') )
        const misc_items = computed(() => LOAN?.loan?.items.filter(i => i.type == 'misc') || [] )
        const loan_totals = computed(() => LOAN?.loan?.totals )
        const loan_auctioneer_name = computed(() => {
            const auctioneer = USER.user_auctioneers.find(a => a.id == LOAN.loan.auctioneer_id)
            return auctioneer?.organisation_name || null
        })
        const loan_lender_name = computed(() => {
            const len = LOAN.auctioneer_lenders.find(l => l.id == LOAN.loan.lender_id)
            return len?.name || null
        })

        const state = reactive({
            loading: true,
            has_loaded: false,
            has_error: false,
            apply_modal_active: false,
            application_pending: false,
        })

        const can_submit = computed(() => {
            return state.has_loaded && !state.has_error
        })

        const init = async () => {
            ALERTS.clearAlerts()
            state.has_error = false
            try {
                await LOAN.prepareInvoice(ROUTE.params.loan_id, LOAN.loan.auctioneer_id)
                await LOAN.show(ROUTE.params.loan_id, true)
            } catch(error) {
                console.error('LOAN.prepareInvoice error', error)
                state.has_error = true
                ALERTS.addAlert('error', error?.data?.message, error?.data?.data || []);
            } finally {
                state.has_loaded = true
            }
            state.loading = false
        }

        const toggleApplyConfirm = () => {
            state.apply_modal_active = !state.apply_modal_active
        }

        const submitApplication = async () => {
            state.application_pending = true

            try {
                const application = await LOAN.submitApplication(ROUTE.params.loan_id, LOAN.loan.auctioneer_id)
                SYSTEM_STORE.createInteraction('NEW_LOAN', ROUTE.fullPath, ROUTE.params.loan_id)
                ROUTER.push({ name: 'loan.view', params: { loan_id: ROUTE.params.loan_id } })
            } catch( error ) {
                ALERTS.addAlert('error', error?.data?.message, error?.data?.data || [])
                SYSTEM_STORE.createInteraction('ERROR', ROUTE.fullPath, 'Loan create error', { error: error || 'no error object'})
            } finally {
                state.apply_modal_active = false
            }
        }

        onMounted(() => {
            init()
        })
            
        return {
            state,
            LOAN,
            toggleApplyConfirm,
            submitApplication,
            loan_buyer_name,
            loan_auctioneer_name,
            loan_lender_name,
            buyer_account_code,
            dealer_ref,
            asset,
            misc_items,
            loan_totals,
            monetise,
            invoice_number,
            invoice_date,
            can_submit
        }
    }
}
</script>