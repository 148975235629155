<template>
    <div class="flex min-h-full flex-col bg-white pt-16 pb-12">
        <main class="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-4 sm:px-6 lg:px-8">
            <div class="py-16">
                <div class="text-center">
                    <p class="text-base font-semibold text-gray-500" v-if="context" v-html="context"></p>
                    <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl" v-if="title" v-html="title"></h1>
                    <p class="mt-2 text-base text-gray-500" v-if="subtitle" v-html="subtitle"></p>
                    <div v-if="custom_message_html"  class="mt-2 text-base text-gray-500" v-html="custom_message_html"></div>
                    <div v-if="link_html" v-html="link_html" class="mt-6"></div>
                    <div v-else-if="(link_path && link_text)" class="mt-6">
                        <router-link :to="link_path" class="text-base font-medium text-blue-600 hover:text-blue-500">
                            {{link_text}}
                            <span aria-hidden="true"> &rarr;</span>
                        </router-link>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
export default {
    props: ['context', 'title', 'subtitle', 'link_path', 'link_text', 'link_html', 'custom_message_html'],
    setup() {
        
    },
}
</script>