<template>
    <fieldset :class="`bg-gray-50 rounded-md p-6 block mb-8`">
        <div v-if="heading || subheading" class="mb-8">
            <h3 v-if="heading" class="text-lg leading-6 font-medium text-gray-900">{{ heading }}</h3>
            <p v-if="subheading" class="mt-1 max-w-2xl text-sm text-gray-500">{{ subheading }}</p>
        </div>
        <slot></slot>
    </fieldset>
</template>

<script>
import { computed } from 'vue'
export default {
    props: ['heading','subheading', 'class', 'no_mb'],
    setup(props) {
        
    },
}
</script>