<template>
    <div v-if="Array.isArray(state.loan_misc_items) && state.loan_misc_items.length > 0" class="mt-8 border border-gray-200 rounded-md overflow-hidden">
        <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
                <tr>
                    <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Name</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Cost Net</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Tax</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Total</th>
                    <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span class="sr-only">Edit</span>
                    </th>
                </tr>
            </thead>
            <tbody class="divide-y divide-gray-200 bg-white">
                <tr v-for="item in state.loan_misc_items" :key="item.id">
                    <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{{ item.name }}</td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ monetise(item.cost_net) }}</td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ monetise(item.cost_tax) }}</td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-800 font-medium">{{ monetise(item.cost_net + item.cost_tax) }}</td>
                    <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        <button v-on:click="() => deleteItem(item.id)" class="text-blue-600 hover:text-blue-900" :disabled="state.delete_in_progress">Remove</button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { useLoanStore } from '@sto/loan.store'
import { computed, reactive } from 'vue'
import { useRoute } from 'vue-router'
import { monetise } from '@lib/helpers'
export default {
    setup() {
        const LOAN = useLoanStore()
        const ROUTE = useRoute()

        const state = reactive({
            delete_in_progress: false,
            loan_misc_items: computed(() => LOAN.loan.items.filter(i => i.type == 'misc'))
        })

        const deleteItem = async item_id => {
            state.delete_in_progress = true
            await LOAN.deleteItem(ROUTE.params.loan_id, LOAN.loan?.auctioneer_id, item_id)
            state.delete_in_progress = false
        }

        return {
            state,
            deleteItem,
            monetise
        }
    },
}
</script>