<template>
    <Tooltip v-if="tooltip_enabled && (number_difference || number_difference == 0)" :content="tooltip_content" placement="top" class="cursor-help">
        <div :class="`inline-flex items-baseline rounded-full px-2.5 py-0.5 ${text_size} font-medium md:mt-2 lg:mt-0 text-${color}-800 bg-${color}-100`">
            <svg :class="`-ml-1 mr-0.5 h-${icon_size} w-${icon_size} flex-shrink-0 self-center text-${color}-500`" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path v-if="difference < 0" fill-rule="evenodd" d="M10 3a.75.75 0 01.75.75v10.638l3.96-4.158a.75.75 0 111.08 1.04l-5.25 5.5a.75.75 0 01-1.08 0l-5.25-5.5a.75.75 0 111.08-1.04l3.96 4.158V3.75A.75.75 0 0110 3z" clip-rule="evenodd" />
                <path v-if="difference > 0" fill-rule="evenodd" d="M10 17a.75.75 0 01-.75-.75V5.612L5.29 9.77a.75.75 0 01-1.08-1.04l5.25-5.5a.75.75 0 011.08 0l5.25 5.5a.75.75 0 11-1.08 1.04l-3.96-4.158V16.25A.75.75 0 0110 17z" clip-rule="evenodd" />
            </svg>
            <span class="sr-only" v-if="difference < 0"> Decreased by </span>
            <span class="sr-only" v-if="difference > 0"> Increased by </span>
            {{ `${number_difference}%` }}
        </div>
    </Tooltip>
    <div v-else-if="number_difference" :class="`inline-flex items-baseline rounded-full px-2.5 py-0.5 ${text_size} font-medium md:mt-2 lg:mt-0 text-${color}-800 bg-${color}-100`">
        <svg :class="`-ml-1 mr-0.5 h-${icon_size} w-${icon_size} flex-shrink-0 self-center text-${color}-500`" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path v-if="difference < 0" fill-rule="evenodd" d="M10 3a.75.75 0 01.75.75v10.638l3.96-4.158a.75.75 0 111.08 1.04l-5.25 5.5a.75.75 0 01-1.08 0l-5.25-5.5a.75.75 0 111.08-1.04l3.96 4.158V3.75A.75.75 0 0110 3z" clip-rule="evenodd" />
            <path v-if="difference > 0" fill-rule="evenodd" d="M10 17a.75.75 0 01-.75-.75V5.612L5.29 9.77a.75.75 0 01-1.08-1.04l5.25-5.5a.75.75 0 011.08 0l5.25 5.5a.75.75 0 11-1.08 1.04l-3.96-4.158V16.25A.75.75 0 0110 17z" clip-rule="evenodd" />
        </svg>
        <span class="sr-only" v-if="difference < 0"> Decreased by </span>
        <span class="sr-only" v-if="difference > 0"> Increased by </span>
        {{ `${number_difference}%` }}
    </div>
</template>

<script>
import { computed } from 'vue'
export default {
    props: ['difference', 'size', 'tooltip_enabled', 'comparison_base', 'comparison_to'],
	setup(props) {

        const number_difference = computed(() => {
            return parseFloat(props.difference).toFixed(2)
        })

        const color = computed(() => {
            return number_difference.value >= 0 ? 'green'
                : 'gray'
        })
        
        const icon_size = computed(() => {
            switch (props.size) {
                case 'sm':
                    return '4'
                case 'md':
                default:
                    return '5'
            }
        })

        const text_size = computed(() => {
            switch (props.size) {
                case 'sm':
                    return 'text-xs'
                case 'md':
                default:
                    return 'text-sm'
            }
        })

        const tooltip_content = computed(() => {
            return `${props.comparison_base} ${number_difference.value}% ${number_difference.value >= 0 ? 'above' : 'below'} ${props.comparison_to}`
        })

        return {
            icon_size,
            text_size,
            color,
            number_difference,
            tooltip_content
        }
    }
}
</script>