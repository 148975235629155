<template>
    <vc-donut

        background="white"
        :foreground="bar_bg || '#e5e7eb'"
        :size="size || 200"
        :total="total"
        :unit="unit || 'px'"
        :thickness="thickness || 30"
        :has-legend="true"
        :legend-placement="legend_placement || 'left'"
        :sections="sections"
    >
            <slot></slot>
  </vc-donut>
</template>

<script>
import 'vue-css-donut-chart/dist/vcdonut.css'
import { computed, reactive, onMounted } from 'vue'
import { tailwindHex } from '@lib/helpers'
export default {
    props: ['sections', 'legend_placement', 'unit', 'total', 'thickness', 'size'],
    setup(props) {

    },
}
</script>