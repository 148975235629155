<template>
    <slot v-if="permitted"></slot>
</template>

<script>
import { useAuthStore } from '@sto/auth.store'
import { computed } from 'vue'
export default {
    props: ['scopes'],
    setup(props) {
        // auth store
        const AUTH_STORE = useAuthStore()

        /**
         * AUTH_STORE.user_scopes e.g. ['buyer', 'seller']
         * props.scopes e.g. ['auctioneer', 'seller']
         * 
         * would return true because access granted to 'auctioneer' or 'seller'; user has 'seller'
         */
        const permitted = computed(() => {
            // if supplied scope is invalid or empty, return true
            return !props.scopes || (Array.isArray(props.scopes) && props.scopes.length == 0) ? true
                // otherwise, check the auth user's scopes to make sure they have at least one of the matching supplied scopes
                : props.scopes.some(scope => AUTH_STORE.user_scopes.includes(scope))
        })

        return {
            permitted
        }
    },
}
</script>