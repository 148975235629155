<template>
    <div class="bg-white sm:rounded-lg border border-gray-200 mb-6" :class="class">
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: ['class'],
    setup() {
        
    },
}
</script>