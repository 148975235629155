<template>
    <div
        class="flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6 transition-all"
        :class="active ? 'bg-gray-100' : 'bg-transparent'"
        :data-active="active"
        @dragenter.prevent="setActive"
        @dragover.prevent="setActive"
        @dragleave.prevent="setInactive"
        @drop.prevent="onDrop"
    >
        <div class="space-y-1 text-center">
            <slot></slot>
        </div>
    </div>
</template>

<script setup>
    import { ref, onMounted, onUnmounted, defineEmits } from 'vue'

    const emit = defineEmits(['files-dropped'])

    let active = ref(false)
    let inactive_timeout = null

    function setActive() {
        active.value = true
        clearTimeout(inactive_timeout)
    }

    function setInactive() {
        inactive_timeout = setTimeout(() => {
            active.value = false
        }, 50)
    }

    function onDrop(e) {
        setInactive()
        emit('files-dropped', [...e.dataTransfer.files])
    }

    function preventDefaults(e) {
        e.preventDefault()
    }

    const events = ['dragenter', 'dragover', 'dragleave', 'drop']

    onMounted(() => {
        events.forEach((eventName) => {
            document.body.addEventListener(eventName, preventDefaults)
        })
    })

    onUnmounted(() => {
        events.forEach((eventName) => {
            document.body.removeEventListener(eventName, preventDefaults)
        })
    })
</script>