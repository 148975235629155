<template>
    <RefreshIcon :class="class" />
</template>

<script>
import { RefreshIcon } from '@heroicons/vue/solid'

export default {
    props: ['class'],
    components: {
        RefreshIcon
    }
}
</script>
