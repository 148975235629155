/*******************************************************************************
 * Dependency Imports
 ******************************************************************************/
// auth::api
import * as Api from './api'
// lodash - https://lodash.com/
import _ from 'lodash'

/*******************************************************************************
 * Services
 ******************************************************************************/

/*******************************************************************************
 * Configuration
 ******************************************************************************/

/*******************************************************************************
 * Transformer prototypes
 ******************************************************************************/

/*******************************************************************************
 * Private methods
 ******************************************************************************/

/**
 * general success
 *
 * @param {obj} data
 * @return {obj} le data
 */
const _onSuccess = data => {

    if( _.get( data, 'data.data' ) ) {
        return _.get( data, 'data.data' )
    }

    return data

}

/**
 * general fail
 *
 * @param {obj} error from a server
 * @return {obj} the error
 */
const _onFailed = error => new Promise(( resolve, reject ) => {

    let error_obj = error.hasOwnProperty( 'response' ) ? error.response : error

    let return_error = {
        ...error_obj.data,
        status: error_obj.status
    }

    reject( return_error )

})

/*******************************************************************************
 * Public methods
 ******************************************************************************/

/**
 * get the alerts for a specified user
 *
 * @return {promise}
 */
export const getForUser = (user_id, params) =>
    Api.getForUser(user_id, params)
        .then( _onSuccess )
        .catch( _onFailed )

/**
 * update an alert
 */
export const update = (alert_id, payload) =>
    Api.update(alert_id, payload)
        .then( _onSuccess )
        .catch( _onFailed )

/**
 * delete an alert
 */
export const deleteById = (alert_id) =>
    Api.deleteById(alert_id)
        .then( _onSuccess )
        .catch( _onFailed )

/**
 * update the alerts for a specified user
 *
 * @return {promise}
 */
export const updateForUser = (user_id, payload) =>
    Api.updateForUser(user_id, payload)
        .then( _onSuccess )
        .catch( _onFailed )


/**
 * delete the alerts for a specified user
 *
 * @return {promise}
 */
export const deleteForUser = (user_id) =>
    Api.deleteForUser(user_id)
        .then( _onSuccess )
        .catch( _onFailed )