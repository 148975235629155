import { defineStore } from 'pinia'

import { shallowRef } from 'vue'
import CheckCircleIcon from '@ico/CheckCircleIcon.vue'
import CrossCircleIcon from '@ico/CrossCircleIcon.vue'

export const useAlertdepStore = defineStore('alertdep', {

    state: () => {
        return {
            alerts: [],
            types: {
                error: {
                    background_style: 'bg-red-50',
                    icon_component: shallowRef(CrossCircleIcon),
                    icon_style: 'h-5 w-5 text-red-400',
                    heading_style: 'text-sm font-medium text-red-800',
                    body_style: 'text-sm text-red-700',
                    button_style: 'bg-red-50 text-red-500 hover:bg-red-100 focus:ring-offset-red-50 focus:ring-red-600'
                },
                success: {
                    background_style: 'bg-green-50',
                    icon_component: shallowRef(CheckCircleIcon),
                    icon_style: 'h-5 w-5 text-green-400',
                    heading_style: 'text-sm font-medium text-green-800',
                    body_style: 'text-sm text-green-700',
                    button_style: 'bg-green-50 text-green-500 hover:bg-green-100 focus:ring-offset-green-50 focus:ring-green-600'
                }
            }
        }
    },

    actions: {

        /**
         * add an alert
         */
        addAlert(type, heading, body = [], dismissable = false) {
            this.alerts.push({
                ...this.types[type],
                heading: heading,
                body: body,
                id: `${type}-${Date.now()}`,
                dismissable: dismissable
            })
        },

        /**
         * remove an alert by its ID
         * @param {*} id 
         */
        removeAlert(id) {
            this.alerts = this.alerts.filter(a => a.id != id)
        },

        /**
         * clear all alerts
         */
        clearAlerts() {
            this.alerts = []
        }
    }

})