<template>
    <main class="py-10 mb-8 bg-gray-50">
        <slot></slot>
    </main>
    <AlertModal />
</template>

<script>
import AlertModal from '@par/alerts/AlertModal.vue';
export default {
    components: {
        AlertModal
    },
    setup() {
        
    },
}
</script>