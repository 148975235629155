import { defineStore } from 'pinia'
import { useAuthStore } from '@sto/auth.store'
import { shallowRef } from 'vue'
import moment from 'moment'

import * as AssetService from '@ser/asset'
import * as CommentService from '@ser/comment'

export const useCommentStore = defineStore('comment', {

    state: () => {
        return {
            _auth_store: useAuthStore(),
            _model_comments: [],
            _comments_loading: false,
            _comments_loaded: false,
            _panel_active: false,
            _stored_count_key: '@MODEL_COMMENT_COUNTS',
            _new_comment_count: 0,
            _new_alert_audio: new Audio('/assets/audio/alert_new.mp3'),
            _last_alert_audio_time: null,
            _model_comments_enabled: false,
            _participants: null
        }
    },

    getters: {
        model_comments: state => state._model_comments,
        comments_loading: state => state._comments_loading,
        comments_loaded: state => state._comments_loaded,
        panel_active: state => state._panel_active,
        comment_count: state => state._model_comments.length,
        new_comment_count: state => state._new_comment_count,
        model_comments_enabled: state => state._model_comments_enabled,
        participants: state => state._participants
    },

    actions: {

        resetStore() {
            this._model_comments = []
            this._comments_loading = false
            this._comments_loaded = false
            this._panel_active = false
            this._new_comment_count = 0
            this._model_comments_enabled = false
            this._participants = []
        },

        setModelCommentsEnabled(enabled = false) {
            this._model_comments_enabled = enabled
        },

        togglePanel() {
            this._panel_active = !this._panel_active
        },

        openPanel() {
            this._panel_active = true
        },

        closePanel() {
            this._panel_active = false
        },

        async modelCommentsSubscribe(model, model_id) {
            // stop if no asset id
            if (!model_id) return
            // subscribe e.g. asset1
            window.Echo.channel(`${model}.${model_id}`)
                .listen(`.new.${model}.comment`, (e) => {
                    this.getModelComments(model, model_id, true, true)
                });
            
            // and replies on the samem mmodel
            window.Echo.private(`user.${this._auth_store?.user?.id}`)
                .listen('.new.comment.reply', (e) => {
                    this.getModelComments(model, model_id, true, true)
                });
        },

        /**
         * get comments for a specified model and save to store
         */
        async getModelComments(model, model_id, without_spinner = false, audio_alert = false) {

            let comments = []

            if (!without_spinner) this._comments_loading = true

            try {

                switch (model) {
                    case 'asset':
                        comments = await AssetService.getComments(model_id)
                        break
                }

                this._model_comments = Array.isArray(comments) ? comments
                    : []

                this.setNewCommentCount(model, model_id, audio_alert)

            } catch (error) {
                console.error('getModelComments error', error)
            } finally {
                this._comments_loading = false
                this._comments_loaded = true
            }
        },

        getStoredCommentCollections() {
            let existing_collection = localStorage.getItem(this._stored_count_key)
            existing_collection = existing_collection ? JSON.parse(existing_collection)
                : []
            return existing_collection
        },

        getStoredCommentModel(model, model_id, existing_collection) {
            let comment_store = Array.isArray(existing_collection) ? existing_collection.find(m => (m.type == model && m.id == model_id))
                : null
            return comment_store || { type: model, id: model_id, count: 0 }
        },

        setModelCommentsCount(model, model_id, count) {
            let existing_collection = this.getStoredCommentCollections()
            let comment_store = this.getStoredCommentModel(model, model_id, existing_collection)
            comment_store.count = count
            let collections = existing_collection.filter(c => (c.type != model && c.id != model_id))
            collections = Array.isArray(collections) ? collections : []
            collections.push(comment_store)
            localStorage.setItem(this._stored_count_key, JSON.stringify(collections))
        },

        /**
         * 
         * @param {*} model 
         * @param {*} model_id 
         */
        setNewCommentCount(model, model_id, audio_alert = false) {
            const existing_collections = this.getStoredCommentCollections()
            const known_model_comments = this.getStoredCommentModel(model, model_id, existing_collections)
            if (isNaN(known_model_comments?.count) || isNaN(this.comment_count)) return 0
            this._new_comment_count = this.comment_count - known_model_comments.count
            if (audio_alert && this._new_comment_count > 0) this.triggerAlertSound()
            this.setModelCommentsCount(model, model_id, this.comment_count)
        },

        /**
         * create a commment for a specific model
         */
        async createModelComment(model, model_id, comment_data) {
            this._comments_loading = true

            let comment

            try {

                switch (model) {
                    case 'asset':
                        comment = await AssetService.createComment(model_id, comment_data)
                        break
                }

            } catch (error) {
                console.error('createModelComment error', error)
            } finally {
                this._comments_loading = true
            }

            return comment
        },

        /**
         * mark comments as viewed
         */
        markAsViewed(model, model_id) {
            this._new_comment_count = 0
            this.setModelCommentsCount(model, model_id, this.comment_count)
        },

        /**
         * 
         * @returns trigger an alert sound
         */
        triggerAlertSound() {
            // timestamp now (seconds)
            const time_now = moment().unix()
            // if we fired off a sound less than 5 seconds ago, don't play the sound again
            if (this._last_alert_audio_time && (time_now - this._last_alert_audio_time) <= 5) return
            this._last_alert_audio_time = time_now
            this._new_alert_audio.play()
        },

        /**
         * reply to an existing comment
         */
        async reply(comment_id, reply_data) {

            this._comments_loading = true

            let reply

            try {
                reply = await CommentService.reply(comment_id, reply_data)
            } catch (error) {
                console.error('reply error', error)
            } finally {
                this._comments_loading = false
            }

            return reply
        },

        /**
         * determine who can see comments associated to this model
         */
        async getModelCommentParticipants(model, model_id) {

            try {

                let model_participants = []

                switch (model) {
                    case 'asset':
                        model_participants = await AssetService.getCommentParticipants(model_id)
                }

                this._participants = model_participants

            } catch (error) {
                console.error('AssetService.getCommentParticipants error', error)
            }
        }
    }

})