<template>
    <div v-if="field.active">
        <div v-if="field.notes" class="bg-blue-50 mb-2 p-2 rounded-md text-blue-500 flex mt-6">
            <InformationCircleIcon class="flex-shrink-0 mr-1.5 h-5 w-5" aria-hidden="true" />
            <p v-html="field.notes" class="text-sm font-medium"></p>
        </div>

        <!-- COLOR SELECT FIELD -->
        <div v-if="field.type == 'color'">
            <legend :class="getFieldState(field, 'label')">{{ field.label }}</legend>
            <div class="flex items-center space-x-3" >
                <label v-if="field.allow_transparent" :class="`relative flex cursor-pointer items-center justify-center rounded-full p-0.5 focus:outline-none ring-gray-400 ${field.value == '' ? 'ring ring-offset-1 ring-2' : ''}`">
                    <input type="radio" :name="field.name" v-model="field.value" :value="''" v-on:click="selectPresetColor" class="sr-only" />
                    <div class="h-8 w-8 rounded-full">
                        <StopStrokeIcon class="h-8 w-8 text-gray-400" />
                    </div>
                </label>
                <label v-for="(option, index) in field.options" :key="option.value" :class="`relative -m-0.5 flex cursor-pointer items-center justify-center rounded-full p-0.5 focus:outline-none ring-${option.tailwind_color_name}-${option.tailwind_color_number} ${option.value == field.value ? 'ring ring-offset-1 ring-2' : ''}`">
                    <input type="radio" :name="field.name" v-model="field.value" :value="option.value" v-on:click="selectPresetColor" class="sr-only" :aria-labelledby="`color-${field.name}-${index}-label`">
                    <span :id="`color-${field.name}-${index}-label`" class="sr-only">{{ option.label }}</span>
                    <span aria-hidden="true" :class="`h-8 w-8 rounded-full border border-black border-opacity-10 bg-${option.tailwind_color_name}-${option.tailwind_color_number}`"></span>
                </label>
                <div v-if="field.allow_custom_color && colorpicker.custom_color" class="relative -m-0.5 flex cursor-pointer items-center justify-center rounded-full p-0.5 focus:outline-none ring ring-offset-1 ring-2">
                    <span aria-hidden="true" class="h-8 w-8 rounded-full border border-black border-opacity-10" :style="`background-color: ${field.value};`"></span>
                </div>
                <div v-if="field.allow_custom_color" class="relative">
                    <button type="button" class="text-blue-500 text-sm font-medium" v-on:click="colorpicker.active = !colorpicker.active">Custom colour</button>
                    <div class="absolute z-50" v-if="colorpicker.active">
                        <color-picker
                            v-model:gradientColor="gradientColor"
                            :shape="'circle'"
                            :disableAlpha="true"
                            :disableHistory="true"
                            :format="'hex6'"
                            :isWidget="true"
                            @pureColorChange="currentColor"
                        />
                    </div>
                </div>
            </div>
        </div>

        <!-- CHECKBOX FIELD -->
        <div v-if="field.type == 'checkbox'" class="relative flex items-start">
            <div class="flex items-center h-5">
                <input v-model="field.value" :id="`${namespace}_${name}`" :name="`${namespace}_${name}`" type="checkbox" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded" />
            </div>
            <div class="ml-2 text-sm">
                <label :for="`${namespace}_${name}`" :class="'text-gray-700'">{{ field.label }}</label>
            </div>
        </div>

        <!-- MULTI CHECKBOX FIELD-->
        <div v-if="field.type == 'multicheckbox'">
            <legend v-if="field.label" :for="`${namespace}_${name}`" :class="getFieldState(field, 'label')">{{ field.label }}</legend>
            <div v-for="option in field.options" :key="option.key" class="relative flex items-start mb-2">
                <div class="flex items-center h-5">
                    <input v-model="option.selected" :id="`${namespace}_${name}_${option.key}`" :name="`${namespace}_${name}`" type="checkbox" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded" />
                </div>
                <div class="ml-2 text-sm">
                    <label :for="`${namespace}_${name}_${option.key}`" :class="'text-gray-700'">{{ option.label }}</label>
                </div>
            </div>
        </div>

        <!-- RADIO FIELD-->
        <div v-if="field.type == 'radio'">
            <legend v-if="field.label" :for="`${namespace}_${name}`" :class="getFieldState(field, 'label')">{{ field.label }}</legend>
            <fieldset class="mt-4">
                <div class="space-y-4" v-bind:class="{ 'sm:flex sm:items-center sm:space-x-10 sm:space-y-0': field.layout === 'inline' }">
                    <div v-for="option in field.options" :key="option.value" class="flex items-center">
                        <input v-model="field.value" :value="option.value" :id="`${namespace}_${name}_${option.value}`" :name="`${namespace}_${name}`" :checked="field.value == option.value" type="radio" checked class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600">
                        <label :for="`${namespace}_${name}_${option.value}`" class="ml-3 block text-sm font-medium leading-6 text-gray-900">{{ option.label }}</label>
                    </div>
                </div>
            </fieldset>
        </div>

        <!-- TOGGLE FIELD -->
        <div v-if="field.type == 'toggle'" class="flex items-center mt-9">
            <!-- Enabled: "bg-indigo-600", Not Enabled: "bg-gray-200" -->
            <button v-on:click="field.value = !field.value" type="button" class="relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2" :class="{'bg-gray-200': !field.value, 'bg-blue-600': field.value}" role="switch" :aria-checked="field.value ? 'true' : 'false'" :aria-labelledby="`${namespace}_${name}-label`">
                <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
                <span aria-hidden="true" class="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out" :class="{'translate-x-0': !field.value, 'translate-x-5': field.value}"></span>
            </button>
            
            <span class="ml-3" :id="`${namespace}_${name}-label`">
                <span class="text-sm font-medium text-gray-900">{{ field.label + ' ' }} </span>
                <span v-show="!field.hide_hint" class="text-sm text-gray-500"> {{ field.value ? field.toggle_positive || 'Yes' : field.toggle_negative || 'No' }}</span>
            </span>
        </div>

        <!-- HIDDEN FIELD -->
        <input v-if="field.type == 'hidden'" type="hidden" v-model="field.value" :id="`${namespace}_${name}`" :name="`${namespace}_${name}`" />

        <!-- OTHER FIELD -->
        <div v-if="!['hidden','toggle','checkbox', 'multicheckbox', 'color', 'radio'].includes(field.type)">
            <label v-if="field.label" :for="`${namespace}_${name}`" :class="getFieldState(field, 'label')">{{ field.label }}</label>
            <div class="relative">

                <!-- STANDARD NATIVES -->
                <input v-if="['text','number','password','email'].includes(field.type)" v-model="field.value" :id="`${namespace}_${name}`" :name="`${namespace}_${name}`" :type="field.type" :class="getFieldState(field, 'input')" :placeholder="field.placeholder || null" :disabled="disabled || field.disabled">

                <!-- TEXTAREA -->
                <textarea v-if="field.type == 'textarea'" rows="4" :name="`${namespace}_${name}`" v-model="field.value" :id="`${namespace}_${name}`" :class="getFieldState(field, 'textarea')"></textarea>
                
                <!-- DATE PICKER -->
                <Datepicker
                    v-if="field.type == 'datepicker'"
                    :modelValue="field.value"
                    @update:modelValue="setDateValue"
                    :format="field.date_format"
                    :previewFormat="field.date_preview_format"
                    :clearable="field.date_clearable"
                    :autoApply="true"
                    :placeholder="field.placeholder"
                    :disabled="field.disabled"
                    :minDate="field.min_date || null"
                    :maxDate="field.max_date || null"
                    :enableTimePicker="field.time_enabled"
                    :inputClassName="'dp-input--tailwind '+getFieldState(field, 'input')"
                    :minutesIncrement="field.minutes_increment || 1"
                    :startTime="field.start_time || null"
                ></Datepicker>

                <!-- SELECT -->
                <select v-if="field.type == 'select'" :id="`${namespace}_${name}`" :name="`${namespace}_${name}`" v-on:change="onSelectChange( $event )" :class="getFieldState(field, 'select')" :disabled="disabled || field.disabled">
                    <option v-if="Array.isArray(field.options)" v-for="(option, index) in field.options" :key="index + 1" :selected="field.value == option.value" :value="option.value">{{option.label}}</option>
                    <optgroup v-else-if="field.options" v-for="(optgroup, key) in field.options" :key="key" :label="optgroup.label">
                        <option v-for="(option, index) in field.options[key].options" :key="index + 1" :selected="field.value == option.value" :value="option.value">{{option.label}}</option>
                    </optgroup>
                </select>
                <span v-if="field.type == 'select'" class="pointer-events-none absolute inset-y-0 right-1 flex items-center pr-1">
                    <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z" clip-rule="evenodd" />
                    </svg>
                </span>

                <!-- MULTISELECT -->
                <div v-if="field.type == 'multiselect'">
                    <multiselect
                        v-model="field.value"
                        :options="field.options"
                        :close-on-select="false"
                        :clear-on-select="false"
                        :searchable="true"
                        :multiple="true"
                    />
                </div>
                <div v-if="field.type == 'search-multiselect'">
                    <multiselect
                        v-model="field.value"
                        :options="field.options"
                        :close-on-select="false"
                        :clear-on-select="false"
                        :searchable="true"
                        :multiple="field.multiple"
                        @search-change="field.search"
                    />
                </div>

            </div>
        </div>

        <p v-if="field.message && !field.pristine" :class="getFieldState(field, 'message')">{{ field.message }}</p>
    </div>
</template>

<script>
    import { watch, ref, reactive, computed } from 'vue'
    import { isValidField } from '@lib/helpers'
    import InformationCircleIcon from '@ico/InformationCircleIcon.vue'
    import StopStrokeIcon from '@ico/StopStrokeIcon.vue'
    import Datepicker from '@vuepic/vue-datepicker'
    import '@vuepic/vue-datepicker/dist/main.css'
    import moment from 'moment'
    export default {
        props: ['field','namespace','name', 'disabled', 'width_class', 'rounded_class'],
        components: {
            Datepicker,
            InformationCircleIcon,
            StopStrokeIcon,
        },
        setup(props) {

            //const pureColor = ref<ColorInputWithoutInstance>("red");
            const gradientColor = ref("linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 100%)");

            const colorpicker = reactive({
                value: '',
                custom_color: false,
                active: false
            })

            const applied_width_class = computed(() => {
                return props.width_class ? props.width_class : 'w-full'
            })

            const applied_rounded_class = computed(() => {
                return props.field.rounded_class ? props.field.rounded_class : 'rounded-md'
            })

            const currentColor = hex => {
                props.field.value = hex
                colorpicker.custom_color = true
                colorpicker.active = false
            }

            const selectPresetColor = () => {
                colorpicker.custom_color = false
            }

            const field_state = computed(() => {
                return {
                    default: {
                        label: 'block text-sm font-medium text-gray-700 mb-2',
                        input: 'appearance-none block '+applied_width_class.value+' px-3 py-2 border border-gray-300 shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm '+applied_rounded_class.value,
                        select: 'relative block appearance-none '+applied_width_class.value+' px-3 py-2 pr-8 border border-gray-300 shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm '+applied_rounded_class.value,
                        textarea: 'appearance-none block '+applied_width_class.value+' px-3 py-2 border border-gray-300 shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm '+applied_rounded_class.value,
                        message: 'mt-2 text-sm text-gray-500'
                    },
                    has_error: {
                        label: 'block text-sm font-medium text-red-700 mb-2',
                        input: 'appearance-none block '+applied_width_class.value+' px-3 py-2 border border-red-700 bg-red-50 text-red-900 placeholder-red-300 shadow-sm focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm '+applied_rounded_class.value,
                        select: 'relative block appearance-none '+applied_width_class.value+' px-3 py-2 pr-8 border border-red-700 bg-red-50 text-red-900 placeholder-red-300 shadow-sm focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm '+applied_rounded_class.value,
                        textarea: 'appearance-none block '+applied_width_class.value+' px-3 py-2 border border-red-700 bg-red-50 text-red-900 placeholder-red-300 shadow-sm focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm '+applied_rounded_class.value,
                        message: 'mt-2 text-sm text-red-700'
                    },
                    disabled: {
                        label: 'block text-sm font-medium text-gray-700 mb-2',
                        input: 'appearance-none block '+applied_width_class.value+' px-3 py-2 border bg-gray-200 border-gray-300 shadow-sm placeholder-gray-400 focus:outline-none sm:text-sm sm:text-sm cursor-not-allowed '+applied_rounded_class.value,
                        select: 'relative block appearance-none '+applied_width_class.value+' px-3 py-2 pr-8 border bg-gray-200 border-gray-300 shadow-sm placeholder-gray-400 focus:outline-none sm:text-sm cursor-not-allowed '+applied_rounded_class.value,
                        textarea: 'appearance-none block '+applied_width_class.value+' px-3 py-2 border bg-gray-200 border-gray-300 shadow-sm placeholder-gray-400 focus:outline-none sm:text-sm sm:text-sm cursor-not-allowed '+applied_rounded_class.value,
                        message: 'mt-2 text-sm text-gray-400'
                    }
                }
            })

            /**
             * set the field value when datepicker updates
             */
            const setDateValue = value => {
                if (value) props.field.value = moment(value).format( props.field.time_enabled ? 'YYYY-MM-DD kk:mm:00' : 'YYYY-MM-DD')
            }

            /**
             * determine the correct display state for a field's elements based on static conditions
             * @param {*} el 
             * 
             * @return {str} tailwind classes
             */
            const getFieldState = (field, el) => {
                try {
                    if (!el) throw 'el is not defined in _getFieldState'
                    if (!field.pristine && !field.valid) return field_state.value.has_error[el]
                    if (props.disabled || field.disabled) return field_state.value.disabled[el]
                    //default
                    return field_state.value.default[el]
                } catch (error) {
                    console.error(error)
                }
            }

            const onSelectChange = $event => {
                props.field.value = $event.target.value
            }

            watch(
                () => props.field.value,
                (new_value) => {
                    if( !props.field.pristine && !props.field.disable_auto_validation ) isValidField( props.field )
                    // number field min/max
                    if (props.field.type == 'number') {
                        if ((props.field.min_value || props.field.min_value == 0) && new_value < props.field.min_value) props.field.value = null
                        if ((props.field.max_value || props.field.max_value == 0) && new_value > props.field.max_value) props.field.value = null
                    }
                }
            )

            const multicheckbox_selected = computed(() => {
                if (props.field.type != 'multicheckbox') return null
                return props.field.options.filter(o => o.selected)
            })

            watch(
                () => multicheckbox_selected.value,
                (new_value) => {
                    if (props.field.type != 'multicheckbox') return

                    switch (props.field.value_format) {
                        case 'string':
                            props.field.value = new_value.map(({value}) => value).toString()
                            break
                        case 'array':
                        default:
                            props.field.value = new_value
                    }
                }
            )

            return {
                colorpicker,
                field_state,
                getFieldState,
                setDateValue,
                onSelectChange,
                gradientColor,
                currentColor,
                selectPresetColor
            }
        }
    }
</script>

<style>
    .dp-input--tailwind {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        border-radius: 0.375rem;
        border-color: rgb(209 213 219 / var(--tw-border-opacity));
        font-size: 0.875rem;
        line-height: 1.25rem;
    }
</style>
