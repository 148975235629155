/*******************************************************************************
 * Dependency Imports
 ******************************************************************************/

// Helpers
import { getEndpoint } from '@lib/helpers'
import axios from 'axios'

/*******************************************************************************
 * Private methods
 ******************************************************************************/

/*******************************************************************************
 * Public methods
 ******************************************************************************/


export const user = async user_id => {
    
    const url = getEndpoint('/user/' + user_id)

    return new Promise(( resolve, reject ) => {
        axios({
            method: 'get',
            url,
            headers: {
                'Accept': 'application/json',
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * create a blank user
 * @param {obj} payload
 * @returns 
 */
export const create = async payload => {
    
    const url = getEndpoint('/user')

    return new Promise(( resolve, reject ) => {
        axios({
            method: 'post',
            data: payload,
            url,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * update a user by id
 * @param {int} user_id 
 * @param {obj} payload
 * @returns 
 */
export const update = async (user_id, payload) => {
    
    const url = getEndpoint('/user/' + user_id)

    return new Promise(( resolve, reject ) => {
        axios({
            method: 'patch',
            data: payload,
            url,
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * update a user's avatar image by id
 * @param {int} user_id 
 * @param {obj} payload
 * @returns 
 */
export const avatar = async (user_id, payload) => {

    let formData = new FormData()
    for (let key in payload) {
        formData.append(key, payload[key])
    }
    return new Promise(( resolve, reject ) => {

        axios({
            method: 'post',
            url: getEndpoint('/user/' + user_id + '/avatar'),
            data: formData,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data; charset=utf-8; boundary=' + Math.random().toString().substr(2)
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

export const availableRoles = async (params = []) => {
    
    const url = getEndpoint('/roles', params)

    return new Promise(( resolve, reject ) => {
        axios({
            method: 'get',
            url,
            headers: {
                'Accept': 'application/json',
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * update a user's roles
 * @param {int} user_id 
 * @param {int} organisation_id
 * @param {obj} payload 'add_roles' and 'remove_roles'
 * @returns 
 */
export const updateRoles = async (organisation_id, user_id, payload) => {
    
    const url = getEndpoint('/organisation/' + organisation_id + '/user/' + user_id + '/roles')

    return new Promise(( resolve, reject ) => {
        axios({
            method: 'post',
            data: payload,
            url,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * add the user to an organisation
 * @param {int} user_id 
 * @param {int} organisation_id
 * @returns 
 */
export const addToOrganisation = async (organisation_id, user_id) => {
    
    const url = getEndpoint('/organisation/' + organisation_id + '/user/' + user_id)

    return new Promise(( resolve, reject ) => {
        axios({
            method: 'post',
            url,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * remove the user from an organisation
 * @param {int} user_id 
 * @param {int} organisation_id 
 * @returns 
 */
export const removeFromOrganisation = async (organisation_id, user_id) => {
    
    const url = getEndpoint('/organisation/' + organisation_id + '/user/' + user_id)

    return new Promise(( resolve, reject ) => {
        axios({
            method: 'delete',
            url,
            headers: {
                'Accept': 'application/json',
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * update a user's roles
 * @param {int} user_id 
 * @param {obj} payload 'add_roles' and 'remove_roles'
 * @returns 
 */
export const updateUserRoles = async (user_id, payload) => {
    
    const url = getEndpoint('/user/' + user_id + '/roles')

    return new Promise(( resolve, reject ) => {
        axios({
            method: 'post',
            data: payload,
            url,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * update a user's password
 * @param {int} user_id 
 * @param {obj} payload 'password'
 * @returns 
 */
export const resetPassword = async (user_id, payload) => {
    
    const url = getEndpoint('/user/' + user_id + '/reset-password')

    return new Promise(( resolve, reject ) => {
        axios({
            method: 'post',
            data: payload,
            url,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * get a user's notification preferences
 * @param {*} user_id 
 * @returns 
 */
export const getNotificationPreferences = async user_id => {
    
    const url = getEndpoint('/user/' + user_id + '/notification-preferences')

    return new Promise(( resolve, reject ) => {
        axios({
            method: 'get',
            url,
            headers: {
                'Accept': 'application/json',
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * update a user's notification preferences
 * @param {*} user_id 
 * @param {obj} payload
 * @returns 
 */
export const updateNotificationPreferences = async (user_id, payload) => {
    
    const url = getEndpoint('/user/' + user_id + '/notification-preferences')

    return new Promise(( resolve, reject ) => {
        axios({
            method: 'patch',
            url,
            data: payload,
            headers: {
                'Accept': 'application/json',
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}

/**
 * update a user's preferences
 * @param {*} user_id 
 * @param {obj} payload
 * @returns 
 */
export const upserConfiguration = async (user_id, payload) => {
    
    const url = getEndpoint('/user/' + user_id + '/configuration')

    return new Promise(( resolve, reject ) => {
        axios({
            method: 'post',
            url,
            data: payload,
            headers: {
                'Accept': 'application/json',
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}