<template>
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 500 500" style="enable-background:new 0 0 500 500;" xml:space="preserve">
        <g>
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="426.2584" y1="400.9572" x2="229.0567" y2="-3.3663">
                <stop  offset="0.362" style="stop-color:#285FF9"/>
                <stop  offset="0.4458" style="stop-color:#275EF6"/>
                <stop  offset="0.5094" style="stop-color:#2659EC"/>
                <stop  offset="0.5663" style="stop-color:#2352DC"/>
                <stop  offset="0.6192" style="stop-color:#1E48C5"/>
                <stop  offset="0.6695" style="stop-color:#193AA7"/>
                <stop  offset="0.7171" style="stop-color:#122A84"/>
                <stop  offset="0.7592" style="stop-color:#0B195D"/>
            </linearGradient>
            <path class="st0" d="M176.1,22.7c82.5-35,177.8,3.5,212.8,86l104.9,259.1c-4.7,2.6-9.6,4.9-14.6,7.1c-82.5,35-177.8-3.5-212.8-86
                l-22.8-65.6L161.5,29.8C166.2,27.2,171.1,24.9,176.1,22.7z"/>
            <path class="st1" d="M303.1,22.7c-82.5-35-177.8,3.5-212.9,86L19.1,271.8l0,0l0,0c-35,82.5,3.5,177.8,86,212.8
                c5,2.1,10.1,4,15.3,5.6l115.3-266.9c5.8-13.7,12.1-28.6,19-44.8C313.7,39.4,366.1,66.4,384,98.2C367.3,65.5,339.5,38.2,303.1,22.7z
                "/>
        </g>
    </svg>
</template>

<style type="text/css">
	.st0{fill:url(#SVGID_1_);}
	.st1{fill:#FF1A22;}
</style>