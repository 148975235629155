import { defineStore } from 'pinia'
import { useAuthStore } from '@sto/auth.store'
import { getSessionIdentifier, generateSessionIdentifier, setSessionIdentifier, sessionInformation } from '@lib/helpers'
import * as InteractionService from '@ser/interaction'

export const useSystemStore = defineStore('system', {

    state: () => {
        return {
            _system_alerts: [],
            _session_info: null,
        }
    },

    getters: {
        system_alerts: state => state._system_alerts,

    },

    actions: {
        /**
         * remove system alert by ID
         */
        remove(id) {
            this._system_alerts = this._system_alerts.filter(sa => sa.id != id)
        },

        /**
         * remove all system alerts
         */
        clear() {
            this._system_alerts = []
        },

        /**
         * add a system alert
         */
        add(type, heading, message, persist, actions ) {

            if (!heading) return

            const AUTH_STORE = useAuthStore()
            if (!AUTH_STORE.user) return

            this._system_alerts.push({
                id: this.generateId(),
                type: type || 'info',
                heading: heading,
                message: message || null,
                persist: persist || false,
                actions: actions || null
            })
        },

        // generate an ID for the alert
        generateId() {
            let id = Date.now(),
                unique = false,
                existing = null,
                attempt = 0

            while(!unique && attempt < 5) {
                existing = this._system_alerts.find(sa => sa.id == id)
                if (!existing) unique = true
                attempt++
                id = id - 100
            }

            return id
        },

        // quick success
        addSuccess(heading, message, actions) {
            this.add('success', heading, message, false, actions )
        },

        // quick error
        addError(heading, message, actions) {
            this.add('error', heading, message, false, actions )
        },

        // quick warning
        addWarning(heading, message, actions) {
            this.add('warning', heading, message, false, actions )
        },

        // quick info
        addInfo(heading, message, actions) {
            this.add('info', heading, message, false, actions )
        },

        async setSessionInfo() {
            const session_info = await sessionInformation()
            if (session_info) this._session_info = session_info
            return
        },

        async createInteraction( type, path, value, payload ) {
            /*
            // do we already have session info?
            if (!this._session_info) await this.setSessionInfo()
            const auth = useAuthStore()
            if (Array.isArray(auth?.user?.roles) && auth.user.roles.includes('USER_AUCTION365_INTERNAL')) return

            let interaction_data = {
                ...this._session_info,
                type: type,
                user_id: auth?.user?.id,
                timestamp: Date.now(),
                path: path
            }

            if (value) interaction_data.value = value
            if (payload) interaction_data.payload = JSON.stringify(payload)

            try {
                await InteractionService.create(interaction_data)
            } catch (error) {
                console.warn('InteractionService.create error', error)
            }
            */

        },

    }
})