<template>
	<span :class="`inline-flex items-center rounded-md bg-${color_modifier}-50 px-2 py-1 text-xs font-medium text-${color_modifier}-700 ring-1 ring-inset ring-${color_modifier}-600/20`" v-html="status"></span>
</template>

<script>
import {onMounted, reactive, computed} from 'vue'
export default {
    props: ['status', 'color'],
	setup(props) {
        const color_modifier = computed(() => props.color || 'green')

        return {
            color_modifier
        }
    }
}
</script>