<template>
    <Menu as="div" class="ml-3 relative">
        <div>
            <MenuButton class="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                <span class="sr-only">Open user menu</span>
                <UserAvatar :self="1" :size="8" />
            </MenuButton>
        </div>
        <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
            <MenuItems class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                <MenuItem>
                    <router-link class="block px-4 py-2 text-sm text-gray-700" :to="{name: 'settings.profile'}">My Profile</router-link>
                </MenuItem>
                <MenuItem>
                    <button type="button" class="block px-4 py-2 text-sm text-gray-700" v-on:click="logout">Log out</button>
                </MenuItem>
            </MenuItems>
        </transition>
    </Menu>
</template>

<script>
import UserAvatar from '@par/user/UserAvatar.vue'
import { Menu,MenuItems,MenuItem,MenuButton } from '@headlessui/vue'
import { useAuthStore } from '@sto/auth.store'
import { useRouter, useRoute } from 'vue-router'
import { computed } from 'vue'
import { useSystemStore } from '@sto/system.store'
export default {
    components: {
        Menu,
        MenuItems,
        MenuItem,
        MenuButton,
        UserAvatar
    },
    setup() {

        const AUTH = useAuthStore()
        const ROUTER = useRouter()
        const ROUTE = useRoute()
        const SYSTEM_STORE = useSystemStore()

        const state = {
            logout_in_progress: false
        }

        const user = computed(() => {
            return AUTH.user || null
        })

        const logout = async () => {
            if (state.logout_in_progress) return
            state.logout_in_progress = true
            try {
                SYSTEM_STORE.createInteraction('LOGOUT', ROUTE.fullPath, 'Logoutrror', null)
                await AUTH.logout()
            } catch (error) {
                console.error('logout error', error)
                SYSTEM_STORE.createInteraction('ERROR', ROUTE.fullPath, 'Logout error', { error: error || 'no error object'})
            }
            state.logout_in_progress = false
            ROUTER.push('/')
        }

        return {
            logout,
            state,
            user
        }
    },
}
</script>