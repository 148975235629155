<template>
    <div class="w-full flex justify-center items-center" :class="classes" :style="supplied_style">
        <LoadingIcon :class="icon_classes" />
    </div>
</template>

<script>
import LoadingIcon from '@ico/LoadingIcon.vue';
import { computed } from 'vue'
export default {
    props: ['height', 'classes', 'icon_size', 'supplied_style', 'icon_color'],
    components: {
        LoadingIcon
    },
    setup(props) {
        const icon_classes = computed(() => {
            return `text-${props.icon_color || 'gray-400'} -ml-1 mr-3 h-${props.icon_size || '12'} w-${props.icon_size || '12'} inline-block animate-reverse-spin`
        })

        return {
            icon_classes
        }
    }
}
</script>