<template>
    <div class="space-y-6 lg:col-start-1 lg:col-span-8">
        <AlertsDep />
        <slot></slot>
    </div>
</template>

<script>
import AlertsDep from '@par/alertsdep/AlertsDep.vue'
export default {
    components: {
        AlertsDep
    },
    setup() {
        
    },
}
</script>