/*******************************************************************************
 * Dependency Imports
 ******************************************************************************/
// auth::api
import * as Api from './api'
// lodash - https://lodash.com/
import _ from 'lodash'

/*******************************************************************************
 * Services
 ******************************************************************************/

/*******************************************************************************
 * Configuration
 ******************************************************************************/

/*******************************************************************************
 * Transformer prototypes
 ******************************************************************************/

/*******************************************************************************
 * Private methods
 ******************************************************************************/

/**
 * general success
 *
 * @param {obj} data
 * @return {obj} le data
 */
const _onSuccess = data => {

    if( _.get( data, 'data.data' ) ) {
        return _.get( data, 'data.data' )
    }

    return data

}

/**
 * general fail
 *
 * @param {obj} error from a server
 * @return {obj} the error
 */
const _onFailed = error => new Promise(( resolve, reject ) => {

    let error_obj = error.hasOwnProperty( 'response' ) ? error.response : error

    reject( _.get( error_obj, 'data', null ) || error_obj )

})

/*******************************************************************************
 * Public methods
 ******************************************************************************/

/**
 * get a "non-you" user by user id
 * @param {int} user_id 
 * @returns 
 */
export const user = async user_id =>
    Api.user(user_id)
        .then( _onSuccess )
        .catch( _onFailed )

/**
 * update a user by id
 */
export const update = async (user_id, payload) =>
    Api.update(user_id, payload)
        .then( _onSuccess )
        .catch( _onFailed )

/**
 * update user avatat
 */
export const avatar = async (user_id, payload) =>
    Api.avatar(user_id, payload)
        .then( _onSuccess )
        .catch( _onFailed )

export const availableRoles = async params =>
    Api.availableRoles(params)
        .then( _onSuccess )
        .catch( _onFailed )

export const updateRoles = async (organisation_id, user_id, payload) =>
    Api.updateRoles(organisation_id, user_id, payload)
        .then( _onSuccess )
        .catch( _onFailed )

export const addToOrganisation = async (organisation_id, user_id) =>
    Api.addToOrganisation(organisation_id, user_id)
        .then( _onSuccess )
        .catch( _onFailed )

export const removeFromOrganisation = async (organisation_id, user_id) =>
    Api.removeFromOrganisation(organisation_id, user_id)
        .then( _onSuccess )
        .catch( _onFailed )

export const updateUserRoles = async (user_id, payload) =>
    Api.updateUserRoles(user_id, payload)
        .then( _onSuccess )
        .catch( _onFailed )
        
export const resetPassword = async (user_id, payload) =>
    Api.resetPassword(user_id, payload)
        .then( _onSuccess )
        .catch( _onFailed )

export const create = async payload =>
    Api.create(payload)
        .then( _onSuccess )
        .catch( _onFailed )

export const getNotificationPreferences = async user_id =>
    Api.getNotificationPreferences(user_id)
        .then( _onSuccess )
        .catch( _onFailed )

export const updateNotificationPreferences = async (user_id, payload) =>
    Api.updateNotificationPreferences(user_id, payload)
        .then( _onSuccess )
        .catch( _onFailed )

export const upserConfiguration = async (user_id, payload) =>
        Api.upserConfiguration(user_id, payload)
            .then(_onSuccess)
            .catch(_onFailed)
