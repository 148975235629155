<template>
    <FormField :field="field" namespace="feature_form_" :name="field.field_key" class="mb-4" />
</template>

<script>
import { onMounted, computed, reactive, watch } from 'vue'
export default {
    props: ['field'],
    setup(props) {
        
        
    },
}
</script>