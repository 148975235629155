<template>
    <svg viewBox="0 0 2 2" :class="`h-${computed_size} w-${computed_size} mx-${computed_spacing} fill-current ${computed_text_color} opacity-80`">
        <circle cx="1" cy="1" r="1" />
    </svg>
</template>

<script>
import { computed } from 'vue'
export default {
    props: ['size', 'spacing', 'text_color'],
	setup(props) {
    	const computed_size = computed(() => props.size || '[3px]')
        const computed_spacing = computed(() => props.spacing || '1')
        const computed_text_color = computed(() => props.text_color || '')

        return {
            computed_size,
            computed_spacing,
            computed_text_color
        }
    }
}
</script>