<template>
    <div class="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200">
        <div class="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap px-4 py-5 sm:p-6">
            <div class="ml-4 mt-2">
                <h3 class="text-lg leading-6 font-medium text-gray-900">Sale</h3>
            </div>
        </div>
    
        <div class="px-4 py-5 sm:p-6">
            <FormField v-for="(field, key) in state.sale.fields" :key="key" :field="field" namespace="loan_sale" :name="key" class="mb-4" />
        </div>
    
        <div class="px-4 py-4 sm:px-6 text-right">
            <Button type="button" width="w-auto" color="blue-outline" :onClick="LOAN.activatePrevStep">Back</Button>
            <Button type="button" width="w-auto" class="ml-2" :onClick="() => submitStep()" :disabled="state.submit_disabled || state.sale.loading">Save & next</Button>
        </div>
    </div>
</template>

<script>
import { useLoanStore } from '@sto/loan.store'
import { computed, onMounted, reactive, watch } from 'vue'
import { isValid, isValidField, getPayload } from '@lib/helpers'
export default {
    setup() {
        const LOAN = useLoanStore()

        /*
        'sale_ref',
        'sale_date',
        */

        const state = reactive({
            loan_store_sale_ref: computed(() => LOAN.loan.sale_ref),
            loan_store_sale_date: computed(() => LOAN.loan.sale_date),
            submit_disabled: computed(() => !isValid(state.sale.fields, true)),
            sale: {
                valid: false,
                has_changes: computed(() => {
                    let has_changes = false
                    for (let f in state.sale.fields) {
                        if (state.sale.fields[f].value != state.sale.fields[f].stored_value) has_changes = true
                    }
                    return has_changes
                }),
                loading: false,
                fields: {
                    sale_ref: {
                        active: true,
                        label: 'Sale ID',
                        type: 'text',
                        value: LOAN.loan?.sale_ref || '',
                        stored_value: LOAN.loan?.sale_ref || '',
                        required: true,
                        pristine: LOAN.loan?.sale_ref ? false : true,
                        valid: false,
                        message: '',
                        validation: [
                            {type: 'characters', value: 'numbers'},
                        ],
                        notes: '',
                    },
                    sale_date: {
                        active: true,
                        label: 'Sale date',
                        placeholder: 'Select a date',
                        type: 'datepicker',
                        value: LOAN.loan?.sale_date || '',
                        stored_value: LOAN.loan?.sale_date || '',
                        min_date: null,
                        time_enabled: false,
                        date_format: 'dd-MM-yyyy',
                        date_clearable: false,
                        required: true,
                        pristine: LOAN.loan?.sale_date ? false : true,
                        valid: false,
                        message: '',
                        validation: [],
                        notes: '',
                    }
                }
            }
        })

        const submitStep = async () => {

            // fields will automatically create their own validation states
            if (state.sale.loading || !isValid(state.sale.fields)) return

            // if the value is present and unchaned, just skip to the next step
            if (!state.sale.has_changes) {
                LOAN.activateNextStep()
                return
            }

            state.sale.loading = true

            try {
                const sale_payload = getPayload(state.sale.fields)
                await LOAN.update(LOAN.loan.id, sale_payload)
                LOAN.activateNextStep()
            } catch (error) {
                console.error('submitStep', error)
                // TODO handle error
            }

            state.sale.loading = false

        }

        watch(
            () => state.loan_store_sale_ref,
            (new_value, old_value) => {
                if (state.sale.fields.sale_ref.value != state.loan_store_sale_ref) {
                    state.sale.fields.sale_ref.value = new_value
                    state.sale.fields.sale_ref.stored_value = new_value
                }
                
            }
        )

        watch(
            () => state.sale.fields.sale_ref.value,
            (new_value, old_value) => {
                isValidField(state.sale.fields.sale_ref)
            } 
        )

        watch(
            () => state.loan_store_sale_date,
            (new_value, old_value) => {
                if (state.sale.fields.sale_date.value != state.loan_store_sale_date) {
                    state.sale.fields.sale_date.value = new_value
                    state.sale.fields.sale_date.stored_value = new_value
                }
                
            }
        )

        return {
            LOAN,
            state,
            submitStep
        }
    }
}
</script>