<!--
    Alert

    DESCRIPTION
    General purpose Alert component
-->

<template>
    <div v-if="active" class="rounded-md p-4" :class="[style.background, style.border, parent_style]">
        <div class="flex" :class="{'items-center': layout == 'inline'}">
            <div class="flex-shrink-0">
                <AlertWarningIcon v-if="type == 'warning'" :style="'text-yellow-600'" />
                <AlertInfoIcon v-if="type == 'info'" :style="'text-blue-700'" />
                <AlertSuccessIcon v-if="type == 'success'" :style="'text-green-700'" />
                <AlertErrorIcon v-if="type == 'error'" :style="'text-red-700'" />
            </div>
            <div v-if="layout == 'inline'" class="ml-3 flex-1 md:flex md:justify-between">
                <p>
                    <span v-if="heading" class="text-sm font-bold" :class="[style.heading, body ? 'mr-2' : '']" v-html="heading"></span>
                    <span v-if="body" class="text-sm" :class="style.body" v-html="body"></span>
                </p>
                <ul v-if="Array.isArray(actions) && actions.length > 0" class="flex items-center pl-4">
                    <li v-for="(action, index) in actions" :key="index" class="ml-4">
                        <button type="button" v-on:click="action.onClick" :class="['whitespace-nowrap text-sm font-medium', style.body]">
                            {{ action.label }}
                            <span aria-hidden="true"> &rarr;</span>
                        </button>
                    </li>
                </ul>
            </div>
            <div v-else class="ml-3">
                <h3 v-if="heading" class="text-sm font-bold" :class="[style.heading, body ? 'mb-2' : '']" v-html="heading"></h3>
                <div v-if="body" class="text-sm" :class="style.body">
                    <p v-html="body"></p>
                </div>
                <div v-if="items" class="mt-2 text-sm" :class="style.body">
                    <ul role="list" class="list-disc space-y-1 pl-5">
                        <li v-for="(item, index) in items" :key="index" v-html="item"></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>    
</template>

<script>
import { computed, shallowRef } from 'vue'

import AlertErrorIcon from '@ico/AlertErrorIcon.vue'
import AlertSuccessIcon from '@ico/AlertSuccessIcon.vue'
import AlertWarningIcon from '@ico/AlertWarningIcon.vue'
import AlertInfoIcon from '@ico/AlertInfoIcon.vue'

export default {
    components: {
        AlertWarningIcon,
        AlertErrorIcon,
        AlertSuccessIcon,
        AlertInfoIcon
    },
    props: ['active', 'type', 'heading', 'body', 'items', 'layout', 'actions', 'parent_style'],
    setup(props) {
        
        const style = computed(() => {
            switch(props.type) {                
                case 'warning':
                    return {
                        icon: 'text-yellow-400',
                        heading: 'text-yellow-700',
                        body: 'text-yellow-700',
                        background: 'bg-yellow-50',
                        border: 'border border-yellow-200',
                        icon_component: shallowRef(AlertWarningIcon),
                        icon_props: {
                            style: 'text-yellow-400',
                        }
                    }
                case 'info':
                    return {
                        icon: 'text-blue-400',
                        heading: 'text-blue-800',
                        body: 'text-blue-800',
                        background: 'bg-blue-50',
                        border: '', 
                        icon_component: shallowRef(AlertInfoIcon),
                        icon_props: {
                            style: 'text-blue-400',
                        }
                    }
                case 'success':
                    return {
                        icon: 'text-green-400',
                        heading: 'text-green-800',
                        body: 'text-green-700',
                        background: 'bg-green-50',
                        border: '', 
                        icon_component: shallowRef(AlertSuccessIcon),
                        icon_props: {
                            style: 'text-green-400',
                        }
                    }
                case 'error':
                default:
                    return {
                        icon: 'text-red-400',
                        heading: 'text-red-800',
                        body: 'text-red-700',
                        background: 'bg-red-50',
                        border: '', 
                        icon_component: shallowRef(AlertErrorIcon),
                        icon_props: {
                            style: 'text-red-400',
                        }
                    }
            }
        })

        return {
            style
        }
    },
}
</script>

